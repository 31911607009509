import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import numeral from "numeral";
import { notification } from "antd";
import { getBioShopPostClick, addToFavourite, addUrl } from "../../../redux/actions/bioShop.action";
import { openLogin } from "../../../redux/actions/login.action";

export default function Popup({ showCustomerModal, setsShowCustomerModal, item, postData }) {
	const dispatch = useDispatch();
	let userInfo = JSON.parse(localStorage.getItem("userInfo"));

	const { bioShopMenu, url } = useSelector((state) => state);
	const userId = bioShopMenu?.payload?.message?.data._id;
	const Urls = url?.payload?.message;

	const [circle, setCircle] = useState([]);
	const [postItem, setPostItem] = useState(item);

	// const [slideIndex, setSlideIndex] = useState(0);

	const [childrenPostItem, setChildrenPostItem] = useState([]);

	const caroselRef = useRef(null);
	const [nextSlide, setNextSlide] = useState(0);

	// const caroselRefSub = useRef(null);

	const [showShare, setShowShare] = useState(false);

	const discountPercent = (percent, actualAmount) => {
		let num = percent.replace(/[^0-9]/g, "");
		return ((num / 100) * actualAmount).toFixed(2);
	};

	useEffect(() => {
		if (item) {
			const circles = [];
			caroselRef?.current?.goToSlide(item?.index, true);
			setPostItem(item);
			item?.children?.map((item) => {
				circles?.push(item?.coordinates[0]);
			});
			setCircle(circles);
		}
	}, [item]);

	useEffect(() => {
		if (postItem) {
			const circles = [];
			postItem?.children?.map((item) => {
				circles.push(item.coordinates[0]);
			});
			setCircle(circles);
			setNextSlide(0);
			if (postItem?.children) setChildrenPostItem(postItem?.children[0]);
		}
	}, [postItem]);

	const copyToClipboard = (url) => {
		let textField = document.createElement("textarea");
		textField.innerText = url;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		// toast.success("Copied to Clipboard!");
		notification.success({
			message: "Copied to Clipboard!",
		});
	};

	const addToFav = (postId, childrenId) => {
		if (!userInfo) {
			dispatch(openLogin(true));
		} else {
			dispatch(addToFavourite(userInfo.token, postId, childrenId)).then((res) => {
				if (res.payload.success) {
					notification.success({
						message: res?.payload?.message,
					});
				} else {
					notification.error({
						message: res?.payload?.message,
					});
				}
			});
		}
	};

	const share = () => {
		if (!userInfo) {
			dispatch(openLogin(true));
		} else {
			dispatch(addUrl(userInfo.token, shareAction(childrenPostItem.ProductUrl)));
			setShowShare(true);
			shareAction(childrenPostItem.ProductUrl);
		}
	};

	const shareAction = (url) => {
		const newUrl = url;
		const newRedirectUrl = new URL(newUrl);
		if (userInfo.account_type === "customer") {
			newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
			newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
		}
		return newRedirectUrl.toString();
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			swipeable: false,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<>
			<Modal
				dialogClassName="bio-image-modal"
				show={showCustomerModal}
				onHide={() => {
					setsShowCustomerModal(false);
					setNextSlide(0);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{bioShopMenu?.payload?.message?.data?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="bio-modal-left-area">
						<Carousel swipeable={true} responsive={responsive} shouldResetAutoplay={false} arrows={false} ref={caroselRef}>
							<>
								<div className="bio-modal-main-content-area">
									<div className="image-inner-box">
										<div className="img-tag-area">
											{postItem?.children?.length !== 0 ? (
												<>
													{" "}
													{item?.media_type === "VIDEO" ? (
														<video
															id={item.post_id}
															//	ref={(el) => (vidRef.current[index] = el)}
															controls={true}
															autoPlay={item?.post_id === postItem?.post_id ? true : false}
															controlsList="nodownload"
														>
															<source src={item.media_url + "#t=0.001"} type="video/mp4"></source>
														</video>
													) : (
														<img src={item?.media_url} alt="media_image" className="rounded image-inner-media" />
													)}
													{circle &&
														circle.map((item, i) => (
															<div
																key={i}
																onClick={() => {
																	setNextSlide(i);
																	setChildrenPostItem(postItem?.children?.[i]);
																}}
																className={`tag-div-main ${nextSlide === i ? "active-circle" : ""}`}
																style={{ top: item?.y, left: item?.x }}
															>
																{i + 1}
															</div>
														))}
												</>
											) : (
												<Link
													to={{
														pathname: item.redirected_url,
													}}
													target="_blank"
													className="image_link"
												>
													<img src={item.media_url} alt="media_image" className="rounded image-inner-media" />
												</Link>
											)}
										</div>

										{postItem && (
											<div className="bio-image-thumbnail">
												{postItem?.children?.map((singleItem, key) => {
													return (
														<div
															onClick={() => {
																setNextSlide(key);
																setChildrenPostItem(singleItem);
															}}
															key={key}
															className="bio-t-img"
														>
															<img src={singleItem.media_url} />
														</div>
													);
												})}
											</div>
										)}
									</div>
									<div className="modal-content-right-area">
										<div className="cat-pro-detail">
											<div className="cat-pro-title">
												<span>{childrenPostItem?.ProductName}</span>
											</div>
											<div className="cat-pro-price">
												<span>{childrenPostItem?.productPromoCodePromo === "KB0" ? `$${numeral(childrenPostItem?.productAmount).format("0.00")}` : childrenPostItem?.productPromoCodeDscs?.includes("%") ? `$${numeral(childrenPostItem?.productAmount - discountPercent(childrenPostItem?.productPromoCodeDscs, childrenPostItem?.productAmount)).format("0.00")}  ` : `$${numeral(childrenPostItem?.productAmount - childrenPostItem?.productPromoCodeDscs?.replace(/[^0-9]/g, "")).format("0.00")}`}</span>
												{childrenPostItem?.productPromoCodePromo === "KB0" ? <span></span> : <span>${numeral(childrenPostItem?.productAmount).format("0.00")}</span>}
											</div>
										</div>
										<div className="pro-cat-description">
											<p>{childrenPostItem?.productDesc}</p>
										</div>
										<div className="cat-pro-buttons">
											<span
												onClick={() => {
													share(postItem);
												}}
												className="btn btn-primary"
											>
												Share <i className="fa fa-share-square-o" aria-hidden="true"></i>
											</span>

											<span
												onClick={() => {
													addToFav(postData?.post_id, childrenPostItem.id);
												}}
												className="btn btn-primary"
											>
												Add To List <i className="fa fa-heart" aria-hidden="true"></i>
											</span>
											<span
												onMouseDown={(e) => {
													if (e.nativeEvent.button === 1) {
														if (!userInfo) {
															dispatch(openLogin(true));
														} else {
															window.open(childrenPostItem.ProductUrl);
															dispatch(getBioShopPostClick(postData?.post_id, childrenPostItem.username, postItem?.media_url, postItem?.media_type, postItem?.caption, postItem?.timestamp, userId, postItem?.post_type, postItem?.media_id));
														}
													}
												}}
												onClick={(ev) => {
													if (!userInfo) {
														dispatch(openLogin(true));
													} else {
														window.open(childrenPostItem.ProductUrl);
														dispatch(getBioShopPostClick(postData?.post_id, childrenPostItem.username, postItem?.media_url, postItem?.media_type, postItem?.caption, postItem?.timestamp, userId, postItem?.post_type, postItem?.media_id));
													}
												}}
												className="btn btn-primary"
											>
												Website <i className="fa fa-shopping-cart" aria-hidden="true"></i>
											</span>
										</div>
									</div>
								</div>
							</>
						</Carousel>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				backdropClassName="custom-backdrop"
				show={showShare}
				onHide={() => {
					setShowShare(false);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Share</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!url?.loading ? (
						<div className="your-copy-link">
							<div className="item-a">
								<a target="_blank" rel="noreferrer" href={Urls}>
									{Urls}
								</a>
							</div>
							<div onClick={() => copyToClipboard(Urls)} className="item-b">
								Copy
							</div>
						</div>
					) : (
						<div>Loading....</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
