import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import { getHotDeal } from "../../redux/actions/deal.action";
import { NavLink } from "react-router-dom";

export default function HotDeal() {
	const dispatch = useDispatch();
	const [hotDeal, setHotDeal] = useState([]);

	useEffect(() => {
		dispatch(getHotDeal()).then((res) => {
			setHotDeal(res?.payload?.data);
		});
	}, []);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 3000, min: 1900 },
			items: 6,
			slidesToSlide: 3, // optional, default to 1.
		},
		desktop: {
			breakpoint: { max: 1899, min: 991 },
			items: 4,
			slidesToSlide: 3, // optional, default to 1.
		},
		smallDesktop: {
			breakpoint: { max: 990, min: 700 },
			items: 3,
			slidesToSlide: 2, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 699, min: 500 },
			items: 2.5,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 499, min: 0 },
			items: 1.4,
			slidesToSlide: 1, // optional, default to 1.
		},
	};
	return (
		<div className="daily_deals_main">
			<div className="midd-width-ifti">
				<div className="daily_deals">
					<div className="pik-header">
						<h3>Hot Deals</h3>
					</div>
					<div className="dail_deal_caursol">
						{hotDeal && (
							<Carousel responsive={responsive} containerClass="carousel-container row" removeArrowOnDeviceType={["tablet", "mobile"]} shouldResetAutoplay={false}>
								{hotDeal?.map((item, key) => {
									let bioShopUrl = "#";
									if (item?.brandData.length > 0) {
										bioShopUrl = `/brand-events/${item?.brandData[0]?.instagram_username ? item?.brandData[0]?.instagram_username : item?.brandData[0]?.pixel_id}`;
									}
									return (
										<div key={key} className="deal-item">
											<NavLink to={bioShopUrl} className="deal-box">
												<div className="deal-box-outer">
													<div
														className="deal-box-image"
														style={{
															backgroundImage: "url(" + item.desktop_url + ")",
														}}
													></div>
													<span className="d-shop-now">Shop Now</span>
												</div>
												<div className="deal-detail">
													<div className="dl-1">
														<span className="dl-name">{item.title}</span>
													</div>
													<div className="dl-2 mt-2">
														<span className="dl-off">{item.description}</span>
													</div>
												</div>
											</NavLink>
										</div>
									);
								})}
							</Carousel>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}