import { DELETE_MEDIA_REQUEST, DELETE_MEDIA_SUCCESS, DELETE_MEDIA_ERROR } from "../../types/types";
export default function deletetedMedia(state = [], action) {
	const { type, payload } = action;

	switch (type) {
		case DELETE_MEDIA_REQUEST:
			return {
				loading: true,
			};
		case DELETE_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case DELETE_MEDIA_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
