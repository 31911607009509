import { POST_FACEBOOK_MEDIA_REQUEST, POST_FACEBOOK_MEDIA_SUCCESS, POST_FACEBOOK_MEDIA_ERROR } from "../../types/types";

export default function facebookPublish(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case POST_FACEBOOK_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case POST_FACEBOOK_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case POST_FACEBOOK_MEDIA_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
