import Layout from "./component/layout";
import BioShopSetup from "../../pages/account/bioShopSetup/BioShop";

export default () => {
	return (
		<>
			<Layout title="Reorder Store">
				<BioShopSetup />
			</Layout>
		</>
	);
};
