import {
    GET_DASHBOARD_EVENT_REQUEST,
    GET_DASHBOARD_EVENT_SUCCESS,
    GET_DASHBOARD_EVENT_ERROR 

} from "../../types/types";
export default function dashboardPayloadEvent(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case  GET_DASHBOARD_EVENT_REQUEST:
			return {
				loading: true,
				success: true,
			};
		case GET_DASHBOARD_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};

		case GET_DASHBOARD_EVENT_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};

		default:
			return state;
	}
}