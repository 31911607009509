import {useState} from "react";
import Layout from "./component/layoutStreaming";
import LiveEvents from "./component/liveEvents";

export default () => {
	const [filter,setFilter] = useState([])
	return (
		<>
			<Layout setFilter={setFilter}>
				<LiveEvents filter={filter} />
			</Layout>
		</>
	);
};