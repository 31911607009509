import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import { getCategoryWiseInfluencer } from "../../redux/actions/influencer.action";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CatInfLoader from "../loader/categoryBrandInfLoader";
import iflu2 from "../../images/brand-logos/Profile_avatar_placeholder_large.png";
import { Empty } from "antd";
import { useParams } from "react-router-dom";

const settings = {
	className: "slider variable-width",
	dots: false,
	infinite: false,
	centerMode: false,
	slidesToShow: 21,
	slidesToScroll: 2,
	variableWidth: false,
	responsive: [
		{
			breakpoint: 1430,
			settings: {
				slidesToShow: 19,
			},
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 18,
			},
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 14,
			},
		},
		{
			breakpoint: 1023,
			settings: {
				slidesToShow: 12,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 10,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 8,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 7.7,
				arrows: false,
				slidesToScroll: 7,
			},
		},
	],
};

export default ({ heading }) => {
	const params = useParams();
	let categoryId = params.id;
	let key = "category";
	let subCategoryId = params.category;
	if (subCategoryId) {
		categoryId = subCategoryId;
		key = "subcategory";
	}
	const dispatch = useDispatch();
	const [activeAlpha, setActiveAlpha] = useState("");
	const { categoryWiseInfluencer } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		dispatch(getCategoryWiseInfluencer(categoryId, key)).then((res) => {});
	}, [categoryId, subCategoryId]);

	const getBrandByCategory = (alphabet) => {
		setActiveAlpha(alphabet);
		dispatch(getCategoryWiseInfluencer(categoryId, key, alphabet, 1, 12)).then((res) => {});
	};

	const alphabet = () => {
		return new Array(26).fill().map((_, i) => {
			let alphabet = (i + 10).toString(36).toUpperCase();
			return (
				<div key={i}>
					<span className={activeAlpha === alphabet ? "active-tab categ-button" : "categ-button"} onClick={() => getBrandByCategory(alphabet)}>
						{alphabet}
					</span>
				</div>
			);
		});
	};

	return (
		<>
			<div className="cat_brands_main">
				<div className="midd-width-ifti">
					<div className="cat_brands">
						<div className="pik-header">
							<h3>{heading}</h3>
						</div>
						<div className="category_slider">
							<div>
								<Slider {...settings}>
									<div>
										<span
											className={activeAlpha === "" ? "active-tab categ-button" : "categ-button"}
											onClick={() => {
												getBrandByCategory("");
											}}
										>
											All
										</span>
									</div>
									{alphabet()}
								</Slider>

								{categoryWiseInfluencer?.loading ? (
									<CatInfLoader />
								) : (
									<>
										{categoryWiseInfluencer?.payload?.data?.length === 0 ? (
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										) : (
											<>
												<div className="filter_brands_influencer">
													{categoryWiseInfluencer?.payload?.data?.map((item) => {
														return (
															<div className="ft_box_influencer">
																<NavLink to={`/${item?.instagram_username ? item?.instagram_username : item?.pixel_id}`} className="ft_box_link_influencer">
																	<div className="ft_logo_influencer">
																		<img src={item?.profile_image_url ? item.profile_image_url : iflu2} />
																	</div>
																	<div className="ft_content_influencer">
																		<div className="ft-1_influencer">
																			<span className="ft-name_influencer">{item?.name}</span>
																		</div>
																		<div className="ft-2_influencer">{/* <span className="ft-off_influencer">100K Followers</span> */}</div>
																	</div>
																</NavLink>
															</div>
														);
													})}
												</div>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
