import { GET_SCHEDULE_POST, POST_INSTAGRAM_MEDIA_SUCCESS,POST_INSTAGRAM_MEDIA_REQUEST,POST_INSTAGRAM_MEDIA_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const schedulePost = (data) => async (dispatch) => {
    const res = axios.post(`${BASEURL}graph/ig/publishmedia/create`, 
    data, { headers: { Authorization: `Bearer ${userInfo?.token}` }, })
    return res
};

export const getSchedulePosts = () => async (dispatch) => {
    const res = await axios.post(`${BASEURL}graph/ig/publishmedia/getall`, { headers: { Authorization: `Bearer ${userInfo?.token}` }, })
    dispatch({
        type: GET_SCHEDULE_POST,
        payload: res.data
    })
};

// export const getSchedulePost = (media_id) => async (dispatch) => {
//     const res= axios.post(`${config.hostApi}/v1/graph/ig/publishmedia/getone`,{media_id})
//     return res
// };


export const deleteSchedulePost = (media_id) => async (dispatch) => {
    const res = axios.post(`${BASEURL}graph/ig/publishmedia/delete`, { media_id }, { headers: { Authorization: `Bearer ${userInfo?.token}` }, })
    return res
};

export const updateSchedulePost = (data) => async (dispatch) => {
    const res = axios.post(`${BASEURL}graph/ig/publishmedia/update`, data, { headers: { Authorization: `Bearer ${userInfo?.token}` }, })
    return res
};

export const directPublish = (data) => async (dispatch) => {
    const res = axios.post(`${BASEURL}graph/ig/publishmedia/publish`,
        data, {
        headers: { Authorization: `Bearer ${userInfo?.token}` },
    })
    return res
};

export const fbPublish = (data) => async (dispatch) => {
    const res = axios.post(`${BASEURL}graph/fb/publishmedia/publish`,
        data, {
        headers: { Authorization: `Bearer ${userInfo?.token}` },
    })
    return res
};

// export const publishedMedia = (caption,media_url) => (dispatch) => {
//     dispatch({ type: POST_INSTAGRAM_MEDIA_REQUEST });
//     return axios({
//       method: 'POST',
//       url: `${BASEURL}graph/ig/publishmedia/publish`,
//       headers: { Authorization: `Bearer ${userInfo?.token}` },
//       data: {
//         media_url,
//         caption,
//       },
//     })
//       .then((response) => {
//         return dispatch({
//           type: POST_INSTAGRAM_MEDIA_SUCCESS,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         return dispatch({
//           type: POST_INSTAGRAM_MEDIA_ERROR,
//           payload: error.response,
//         });
//       });
//   };