import { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import "../../css/live-events.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllPublishedEvent } from "../../redux/actions/liveShopping.action";
import moment from "moment";
import avatarIcon from "../../images/avatar.png";

export default function PublishEvent({ category, source, sortBy, applyFilter, eventType, origin }) {
	// const location = useLocation();
	const params = useParams();
	const dispatch = useDispatch();
	const [count, setCount] = useState(2);
	const sliderRef = useRef();
	const [data, setData] = useState([]);
	const [hide, setHide] = useState(false);
	const { allPublishEvent, account, brand, bioShopMenu } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (brand?.payload?.success || source === "bioshop") {
			if (applyFilter) {
				if (eventType && eventType !== "previousEvents") {
					setHide(true);
				} else {
					setHide(false);
				}
			}
		}

		if (brand?.payload?.success || source === "bioshop") {
			if (applyFilter === undefined || applyFilter) {
				dispatch(getAllPublishedEvent(1, 25, category, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message, sortBy)).then((res) => {
					setData(res?.payload?.message?.data);
				});
			}
		}
	}, [applyFilter]);

	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	const liveEvent = {
		className: "slider variable-width",
		dots: false,
		infinite:data?.length > 6 ? true:false,
		centerMode: false,
		slidesToShow: 6,
		swipe: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1350,
				settings: {
					slidesToShow: 4,
					swipe: true,
				},
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 3.2,
					infinite: false,
					swipe: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					infinite: false,
					slidesToShow: 1.5,
					swipe: true,
				},
			},
		],
	};

	const onSwipeEvent = (swipeDirection) => {
		if (swipeDirection === "left") {
			if (allPublishEvent?.payload?.message?.next) {
				dispatch(getAllPublishedEvent(count, 6, category, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message, sortBy)).then((res) => {
					setData([...data, ...res?.payload?.message?.data]);
				});
			}
		}
	};

	return (
		<>
			<div
				className={`event-container ${!allPublishEvent?.loading && data?.length === 0 && "d-none"}
      
      ${hide ? "d-none" : ""}
      `}
			>
				<div className="event-header">
					<h3>Previous Shows</h3>
				</div>
				{allPublishEvent?.loading ? (
					<>
						<Slider onSwipe={onSwipeEvent} {...liveEvent}>
							{new Array(6).fill().map((_, i) => {
								return <div className="event-col-box"></div>;
							})}
						</Slider>
					</>
				) : (
					data?.length > 0 && (
						<Slider onSwipe={onSwipeEvent} {...liveEvent} ref={sliderRef}>
							{data.map((item) => {
								return (
									<div className="event-col-box">
										<NavLink to={`/published-events/${item?._id}?origin=${origin}&username=${params.username}`} className="live-vid-link">
											<div className={`vid-item ${item?.default_banner ? "default-image" : ""}`}>
												<img alt="" src={item?.banner} />
												<div className="vid-item-detail">
													<div className="vid-time">
														<span>{moment(item?.started_at).utc().format("YYYY-MM-DD")}</span>
														{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
													</div>
													<div className="vid-title">
														<span className="v-title">{item?.title}</span>
														<div className="author-name justify-content-between">
															<div className="auth-left">
																<span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
																<span className="auth-title">{item?.brand_name}</span>
															</div>
															<div className="auth-right">
																{userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item?.influencer_percent !== "undefined" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
																{userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item.referral_percent !== "undefined" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
															</div>
														</div>
													</div>
												</div>
											</div>
										</NavLink>
									</div>
								);
							})}
						</Slider>
					)
				)}
			</div>
		</>
	);
}
