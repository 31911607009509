import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Steps, Spin, InputNumber } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useStepsForm } from "sunflower-antd";
import { getCountry } from "../../redux/actions/country.action";
import { getState } from "../../redux/actions/state.action";
import { getCity } from "../../redux/actions/city.action";
import { getCustomerRegister, getBrandRegister } from "../../redux/actions/register.action";
import { openLogin } from "../../redux/actions/login.action";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
const { Option } = Select;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export default function Register({ step = "step2" }) {
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState();
  const [country, setCountry] = useState("US");

  const [errorCheck, setErrorCheck] = useState("");
  const [accountType, setAccountType] = useState("customer");
  const [checkAccountType, setCheckAccountType] = useState("brand");

  const { customerRegister, brandRegister } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    dispatch(getCountry()).then((res) => {
      const selectCountries = [];
      res.payload.message.map(({ name, code1 }) => {
        return selectCountries.push({ value: code1, label: name });
      });
      setCountryList(selectCountries);
      dispatch(getState(country)).then((res) => {
        const selectStates = [];
        res.payload.message.map(({ name, isoCode }) => {
          return selectStates.push({ value: isoCode, label: name });
        });
        setStateList(selectStates);
      });
    });
  }, []);

  useEffect(() => {
    if (step) {
      if (step == "step2") {
        gotoStep(current + 1);
        setAccountType("customer");
      } else {
        gotoStep(current);
      }
    }
  }, [step]);

  const changeCountry = (value) => {
    setCountry(value);
    dispatch(getState(value)).then((res) => {
      const selectStates = [];
      res.payload.message.map(({ name, isoCode }) => {
        return selectStates.push({ value: isoCode, label: name });
      });
      setStateList(selectStates);
      setCityList();
      form.resetFields(["state"]);
      form.resetFields(["city"]);
    });
  };

  const changeState = (value) => {
    dispatch(getCity(country, value)).then((res) => {
      const selectStates = [];
      res.payload.message.map(({ name }) => {
        return selectStates.push({ value: name, label: name });
      });
      setCityList(selectStates);
    });
  };

  const { Step } = Steps;
  const { form, current, initialValues, gotoStep, stepsProps, formProps, submit, formLoading } = useStepsForm({
    async submit(values) {
      console.log("zip", values);
      if (accountType === "customer") {
        dispatch(getCustomerRegister(values)).then((res) => {
          if (res?.payload?.success) {
            localStorage.setItem("userInfo", JSON.stringify(res.payload.data));
            window.location.reload(false);
          } else {
            //setErrorCheck('')
            setErrorCheck(res?.payload?.data?.message);
          }
        });
      } else if (accountType === "brand") {
        dispatch(getBrandRegister(values)).then((res) => {
          if (res.payload.success) {
            dispatch(openLogin(false));
            Swal.fire({
              icon: "success",
              title: "You have been register successfully !",
            });
          } else {
            setErrorCheck(res?.payload?.data?.message);
          }
        });
      }
    },
    total: 4,
    isBackValidate: false,
    defaultFormValues: {
      country: country,
    },
  });

  const formList = [
    <>
      <div className="account-choose-container">
        <div className="account-select-box">
          <div
            className="ac_type_block"
            onClick={() => {
              setAccountType("customer");
              setCheckAccountType("shopper");
            }}
          >
            <input type="radio" name="shopper" id="shopper" className={`d-none ${checkAccountType === "shopper" ? "infchecked" : ""}`} value="shopper" />
            <label for="shopper">
              <span className="imp-brnd">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
              <span className="brnd-right-content">
                <h4>Shoppers</h4>
                <p>Enjoy exclusive discounts</p>
                <div className="signup_detail">
                  <ul>
                    <li>
                      <i className="fa fa-circle" aria-hidden="true"></i> Free
                    </li>
                    <li>
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      Access to live shopping events
                    </li>
                    <li>
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      Access to social store
                    </li>
                    <li>
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      Shop and earn
                    </li>
                  </ul>
                </div>
              </span>
            </label>
          </div>
          {/* <div
						className="ac_type_block"
						onClick={() => {
							setAccountType("customer");
							setCheckAccountType("influencer");
						}}
					>
						<input type="radio" name="influencer" id="influencer" className={`d-none ${checkAccountType === "influencer" ? "infchecked" : ""}`} value="influencer" />
						<label for="influencer">
							<span className="imp-brnd">
								<i className="fa fa-user-plus" aria-hidden="true"></i>
							</span>
							<span className="brnd-right-content">
								<h4>Influencers & Creators</h4>
								<div className="signup_detail">
									<ul>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i> Free
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i>
											Earn commission from your favorite brands
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i>
											Grow your audience
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i>
											Connect with your audience via live shopping
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i>
											Curate personalized digital shop
										</li>
									</ul>
								</div>
							</span>
						</label>
					</div>
					<div
						className="ac_type_block"
						onClick={() => {
							setAccountType("brand");
							setCheckAccountType("brand");
						}}
					>
						<input type="radio" name="brand" id="brand" className={`d-none ${checkAccountType === "brand" ? "infchecked" : ""}`} value={`brand `} />
						<label for="brand">
							<span className="imp-brnd">
								<i className="fa fa-tags" aria-hidden="true"></i>
							</span>
							<span className="brnd-right-content">
								<h4>Retailers & Brands </h4>
								<p>Grow your online presence and boost sales through affiliate campaigns</p>
								<div className="signup_detail">
									<ul>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i> Competitive Pricing
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i>
											Connect with Influencers and Customers In One Place
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i> Grow Your Audience & Increase Sales
										</li>
										<li>
											<i className="fa fa-circle" aria-hidden="true"></i> Curate Digital Shop
										</li>
                    <li>
                      <i className="fa fa-circle" aria-hidden="true"></i> Create Buzz With Live Shopping Events
                    </li>
                  </ul>
                </div>
              </span>
            </label>
          </div> */}
        </div>
      </div>
      <Form.Item>
        <Button
          type="primary"
          size="large"
          block
          onClick={() => {
            if (accountType) {
              gotoStep(current + 1);
            }
          }}
        >
          Next
        </Button>
      </Form.Item>
    </>,
    <>
      {accountType === "customer" ? (
        <>
          <Form.Item
            name="nickName"
            rules={[
              {
                required: true,
                message: `Please Enter Nick Name `,
              },
            ]}
          >
            <Input placeholder={`Nick Name`} size="large" />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: `Please Enter First Name `,
              },
            ]}
          >
            <Input placeholder={`First Name`} size="large" />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: `Please Enter Last Name `,
              },
            ]}
          >
            <Input placeholder={`Last Name`} size="large" />
          </Form.Item>
        </>
      ) : (
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: `Please Enter ${accountType === "brand" ? "Company Name" : ""}`,
            },
          ]}
        >
          <Input placeholder={`${accountType === "brand" ? "Company Name" : "Name"}`} size="large" />
        </Form.Item>
      )}

      <Form.Item
        name={["email"]}
        rules={[
          {
            type: "email",
            required: true,
            message: "Please Enter Email",
          },
        ]}
      >
        <Input placeholder="Email" size="large" />
      </Form.Item>

      {/* {accountType === "customer" && (
        <Form.Item
          name="zipCode"
          type="number"
        >
          <InputNumber autoComplete="off" placeholder={`Zip code`} type="number" size="large" minLength={5} />
        </Form.Item>
      )} */}
      {/* <Form.Item
				className={`d-none ${accountType === "customer" ? "d-block" : "d-none"}`}
				name="gender"
				rules={[
					{
						required: accountType === "customer" ? true : false,
						message: `Please Enter ${accountType === "customer" && "Gender"}`,
					},
				]}
			>
				<Select showSearch placeholder="Select Gender" optionFilterProp="children" size="large" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
					<Option value="male">Male</Option>
					<Option value="female">Female</Option>
					<Option value="other">Other</Option>
				</Select>
			</Form.Item> */}

      {accountType === "customer" ? (
        <>
          <Form.Item
            autoComplete="new-password"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please Enter Password!",
              },
            ]}
          >
            <Input.Password autoComplete="new-password" placeholder="Password" size="large" />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" size="large" />
          </Form.Item>
          <Form.Item className="frm-btns">
            {/* <Button size="large" block type="primary" onClick={() => gotoStep(current - 1)}>
              Previous
            </Button> */}

            {customerRegister.loading || brandRegister.loading ? (
              <Button size="large" className="w-100" block type="primary" loading={formLoading}>
                <Spin indicator={antIcon} />
              </Button>
            ) : (
              <Button
                size="large"
                block
                className="w-100"
                type="primary"
                loading={formLoading}
                onClick={() => {
                  submit();
                }}
              >
                Register
              </Button>
            )}
          </Form.Item>
          <div role="alert" className="ant-form-item-explain-error mt-2">
            {errorCheck}
          </div>
        </>
      ) : (
        <Form.Item className="frm-btns">
          <Button size="large" block type="primary" onClick={() => gotoStep(current - 1)}>
            Previous
          </Button>
          <Button type="primary" size="large" block onClick={() => gotoStep(current + 1)}>
            Next
          </Button>
        </Form.Item>
      )}
    </>,
    <>
      <Form.Item
        name="country"
        rules={[
          {
            required: true,
            message: "Please Enter Country",
          },
        ]}
      >
        <Select showSearch placeholder="Select Country" size="large" optionFilterProp="children" onChange={changeCountry} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
          {countryList.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="state"
        rules={[
          {
            required: true,
            message: "Please Enter State",
          },
        ]}
      >
        <Select showSearch placeholder="Select State" optionFilterProp="children" size="large" onChange={changeState} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
          {stateList.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </Select>
      </Form.Item>

      {cityList && (
        <Form.Item
          name="city"
          rules={[
            {
              required: cityList.length ? true : false,
              message: "Please Enter City",
            },
          ]}
        >
          <Select showSearch placeholder="Select City" size="large" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {cityList.map((item) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item className="frm-btns">
        <Button size="large" block type="primary" onClick={() => gotoStep(current - 1)}>
          Previous
        </Button>
        <Button
          size="large"
          block
          type="primary"
          onClick={() => {
            gotoStep(current + 1);
          }}
        >
          Next
        </Button>
      </Form.Item>
    </>,

    <>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please Enter Password!",
          },
        ]}
      >
        <Input.Password placeholder="Password" size="large" />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The two passwords that you entered do not match!"));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" size="large" />
      </Form.Item>
      <Form.Item name="zipCode">
        <InputNumber placeholder={`Zip code`} size="large" min={5} />
      </Form.Item>
      <Form.Item name="refferedBy">
        <Input placeholder={`Referred By`} size="large" />
      </Form.Item>

      <Form.Item className="frm-btns">
        <Button size="large" block type="primary" onClick={() => gotoStep(current - 1)}>
          Previous
        </Button>

        {customerRegister.loading || brandRegister.loading ? (
          <Button size="large" className="w-100" block type="primary" loading={formLoading}>
            <Spin indicator={antIcon} />
          </Button>
        ) : (
          <Button
            size="large"
            className="w-100"
            block
            type="primary"
            loading={formLoading}
            onClick={() => {
              submit();
            }}
          >
            Register
          </Button>
        )}
      </Form.Item>
      <div role="alert" className="ant-form-item-explain-error mt-2">
        {errorCheck}
      </div>
    </>,
  ];

  return (
    <>
      <div className="login-signup-ift">
        <h2>Create an Account</h2>
        <div>
          <Form {...formProps}>{formList[current]}</Form>
        </div>
      </div>
    </>
  );
}
