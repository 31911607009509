import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import GalleryDashboard from "./galleryDashboard";
import UploadMedia from "./createMedia";
import AllGallery from "./allGallery";
const { TabPane } = Tabs;
export default function MediaGallery() {
	const [activeGallery, setActiveKey] = useState("1");
	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	useEffect(() => {
		return () => {
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	const galleryTabChange = (key) => {
		setActiveKey(key);
	};
	return (
		<>
			<div className="account-left-content">
				<div className="favourite-brands-container">
					<div>
						<Tabs activeKey={activeGallery} onChange={galleryTabChange} defaultActiveKey={"1"}>
							<TabPane tab="Dashboard" key="1">
								<GalleryDashboard />
							</TabPane>
							<TabPane tab="Upload Media" key="2">
								<UploadMedia />
							</TabPane>
							<TabPane tab="Active" key="3">
								{activeGallery === "3" && <AllGallery name="active" />}
							</TabPane>
							<TabPane tab="In-Active" key="4">
								{activeGallery === "4" && <AllGallery name="in_active" />}
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
}
