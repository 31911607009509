import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import numeral from "numeral";
import Swal from "sweetalert2";
import { notification, Checkbox, Col, Row, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getBioShopPostClick, addToFavourite, addUrl } from "../../../redux/actions/bioShop.action";
import { publishFb } from "../../../redux/actions/facebook.action";
import { publishedIgmedia } from "../../../redux/actions/instagram.action";
import { reviseStatus } from "../../../redux/actions/postRetrieve.action";
import { openLogin } from "../../../redux/actions/login.action";
import { publishPost } from "../../../redux/actions/postRetrieve.action";
import Counter from "./../../../components/counter/counter";
import avatarIcon from "../../../images/avatar.png";
import playimg from "../../../images/play-icon.png";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Popup({ showCustomerModal, setsShowCustomerModal, item, username, post, fetchData, setStore, store, socialStore }) {
	const ref = React.useRef(0);
	const dispatch = useDispatch();
	let userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const { bioShopMenu, url, publishedPost, account, facebookPublish, instaPublish } = useSelector((state) => state);
	const userId = bioShopMenu?.payload?.message?.data._id;
	const Urls = url?.payload?.message;
	const [circle, setCircle] = useState([]);
	const [postItem, setPostItem] = useState(item);

	const [childrenPostItem, setChildrenPostItem] = useState([]);

	const caroselRef = useRef(null);
	const [nextSlide, setNextSlide] = useState(0);
	const [videoId, setVideoId] = useState("");

	const [showShare, setShowShare] = useState(false);
	const [fetchMore, setFetchMore] = useState(false);
	const [addToStore, setAddToStore] = useState(false);
	const [channel, setChannel] = useState([]);

	const discountPercent = (percent, actualAmount) => {
		let num = percent.replace(/[^0-9]/g, "");
		return ((num / 100) * actualAmount).toFixed(2);
	};

	useEffect(() => {
		if (item) {
			const circles = [];
			caroselRef?.current?.goToSlide(item?.index, true);
			setPostItem(item);
			item.children.map((item) => {
				circles.push(item?.coordinates[0]);
			});
			setCircle(circles);
			setVideoId(item.post_id);
		}
	}, [item]);

	useEffect(() => {
		if (item) {
			fetchData(true);
		}
	}, [fetchMore]);

	useEffect(() => {
		if (postItem) {
			const circles = [];
			postItem.children.map((item) => {
				circles.push(item?.coordinates[0]);
			});
			setCircle(circles);
			setNextSlide(0);
			setChildrenPostItem(postItem?.children[0]);
			setVideoId(postItem.post_id);
		}
	}, [postItem]);

	const deactive = (token, post_id, status) => {
		Swal.fire({
			title: "Deactivate Post",
			text: `Are you sure you want to deactivate this post from kbshop.com? `,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			confirmButtonColor: "#010b40",
			cancelButtonColor: "#d33",
			// imageUrl: `${postItem?.media_type === "VIDEO" ? '<i class="fa-regular fa-play"></i>' : postItem?.media_url}`,	
			imageUrl: `${postItem?.media_type === "VIDEO" ? playimg : postItem?.media_url}`,
			imageWidth: 60,
			imageHeight: 60,
			imageAlt: "Post img/vid",
			reverseButtons: false,
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(reviseStatus(token, post_id, status)).then((res) => {
					if (res?.payload?.data?.success) {
						// setAddToStore(true);
						Swal.fire({
							icon: "success",
							title: "Deactivated successfully",
						}).then(() => {
							window.location.reload();
						});
					} else {
						Swal.fire({
							icon: "error",
							title: res?.payload?.message,
						});
					}
				});
			}
		});
	};

	const channelChange = (e) => {
		if (e.target.checked) setChannel([...channel, e.target.value]);
		else setChannel(channel.filter((value) => value !== e.target.value));
	};

	const copyToClipboard = (url) => {
		let textField = document.createElement("textarea");
		textField.innerText = url;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		notification.success({
			message: "Copied to Clipboard!",
		});
	};

	const publish = (token, postItem) => {
		if (channel.includes("socialStore")) {
			addToBioShop(token, postItem.post_id);
		}

		if (channel.includes("instragram")) {
			instagramPublish(postItem?.children[0].productDesc, postItem?.media_url);
		}

		if (channel.includes("facebook")) {
			dispatch(addUrl(userInfo.token, shareAction(postItem.children[0].ProductUrl))).then((res) => {
				facebookPost(postItem?.media_url, postItem?.children[0].productDesc, res?.payload?.message);
			});
		}

		setTimeout(() => {
			setAddToStore(false);
		}, 3000);
	};

	const addToBioShop = (token, postId) => {
		if (userInfo) {
			return dispatch(publishPost(token, postId)).then((res) => {
				if (!res?.payload?.data?.success) {
					notification.error({
						message: res?.payload?.data?.message,
					});
					return false;
				} else {
					notification.success({
						message: "Successfully added to social store",
					});
				}
			});
		}
	};

	const facebookPost = (mediaUrl, caption, link) => {
		return dispatch(publishFb(mediaUrl, caption, link)).then((res) => {
			console.log("facebook", res);
			if (res?.payload?.data?.err) {
				notification.error({ message: "Something wrong with your facebook" });
			} else {
				notification.success({
					message: "Post to facebook successfully",
				});
			}
		});
	};

	const instagramPublish = (caption, mediaUrl) => {
		return dispatch(publishedIgmedia(caption, mediaUrl)).then((res) => {
			if (res?.payload?.data?.err) {
				notification.error({ message: "Something wrong with your instagram" });
			} else {
				notification.success({
					message: "Post to Instagram Successfully",
				});
			}
			return true;
		});
	};

	function pausePlay(id, prevOrNext, index) {
		const nextVideoData = post[index ? index : postItem?.index + 1];
		const prevVideoData = post[index ? index : postItem?.index - 1];

		if (prevOrNext === "next") {
			if (nextVideoData?.media_type === "VIDEO") {
				const nextVideoId = document.getElementById(nextVideoData?.post_id);
				if (nextVideoId.paused) nextVideoId.play();
			}
		}

		if (prevOrNext === "prev") {
			if (prevVideoData?.media_type === "VIDEO") {
				const prevVideoId = document.getElementById(prevVideoData?.post_id);
				if (prevVideoId.paused) prevVideoId.play();
			}
		}

		if (id) {
			if (postItem?.media_type === "VIDEO") {
				var currentVideo = document.getElementById(id);
				currentVideo.pause();
			}
		}
	}

	const addToFav = (postId, childrenId) => {
		if (!userInfo) {
			dispatch(openLogin(true));
		} else {
			dispatch(addToFavourite(userInfo.token, postId, childrenId)).then((res) => {
				if (res.payload.success) {
					notification.success({
						message: res.payload.message,
					});
				} else {
					notification.error({
						message: res.payload.message,
					});
				}
			});
		}
	};

	const share = () => {
		if (!userInfo) {
			dispatch(openLogin(true));
		} else {
			dispatch(addUrl(userInfo.token, shareAction(childrenPostItem.ProductUrl)));
			setShowShare(true);
			shareAction(childrenPostItem.ProductUrl);
		}
	};

	const shareAction = (url) => {
		const newUrl = url;
		const newRedirectUrl = new URL(newUrl);
		if (userInfo.account_type === "customer" || userInfo.account_type === "influencer") {
			newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
			newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
			newRedirectUrl.searchParams.set("event", "share");
		}
		return newRedirectUrl.toString();
	};

	const productShopUrl = (url) => {
		const accountType = bioShopMenu?.payload?.message?.data?.type;
		if (accountType === "brand") {
			const newUrl = url;
			const newRedirectUrl = new URL(newUrl);
			newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
			newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
			newRedirectUrl.searchParams.set("event", "shop");
			return newRedirectUrl.toString();
		} else {
			return url;
		}
	};

	function CustomRightArrow({ onClick }) {
		function handleClick() {
			onClick();
			if (postItem) {
				setPostItem(post[postItem?.index + 1]);
				setNextSlide(0);
				pausePlay(videoId, "next");
				if (fetchMore) {
					setFetchMore(false);
				} else {
					setFetchMore(true);
				}
			}

			//			fetchData(true);
		}
		return (
			<button
				onClick={() => {
					handleClick();
				}}
				aria-label="Go to next slide"
				className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
			/>
		);
	}

	function CustomLeftArrow({ onClick }) {
		function handleClick() {
			onClick();
			if (postItem) {
				setPostItem(post[postItem?.index - 1]);
				setNextSlide(0);
				pausePlay(videoId, "prev");
			}
		}
		return (
			<button
				onClick={() => {
					handleClick();
				}}
				aria-label="Go to Leftslide"
				className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
			/>
		);
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			swipeable: false,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	//Counter

	return (
		<>
			<Modal
				dialogClassName={`bio-image-modal ${postItem?.media_type === "VIDEO" ? "video-modal-box-rev" : ""}`}
				show={showCustomerModal}
				onHide={() => {
					setsShowCustomerModal(false);
					setNextSlide(0);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{bioShopMenu?.payload?.message?.data?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="bio-modal-left-area">
						<Carousel
							swipeable={true}
							customLeftArrow={<CustomLeftArrow />}
							customRightArrow={<CustomRightArrow />}
							responsive={responsive}
							shouldResetAutoplay={false}
							arrows={true}
							beforeChange={(nextSlide) => {
								pausePlay(videoId, "prev", nextSlide);
								setNextSlide(0);
								setPostItem(post[nextSlide]);
								//	fetchData(true);
							}}
							ref={caroselRef}
						>
							{post?.map((item) => {
								return (
									<>
										<div className={`bio-modal-main-content-area ${item?.source === "brand_event" ? "post-event-modal" : ""}`}>
											<div className="image-inner-box">
												<div className={`img-tag-area ${item.source === "brand_event" && item.children[0]?.default_banner && "default-image"}`}>
													{postItem?.children?.length !== 0 ? (
														<>
															{item?.media_type === "VIDEO" ? (
																<video
																	id={item.post_id}
																	//	ref={(el) => (vidRef.current[index] = el)}
																	controls={true}
																	autoPlay={item?.post_id === postItem?.post_id ? true : false}
																	controlsList="nodownload"
																>
																	<source src={item.media_url + "#t=0.001"} type="video/mp4"></source>
																</video>
															) : (
																<img src={item?.media_url} alt="media_image" className="rounded image-inner-media" />
															)}

															{item?.media_type !== "VIDEO" &&
																circle &&
																circle.map((item, i) => (
																	<div
																		key={i}
																		onClick={() => {
																			setNextSlide(i);
																			setChildrenPostItem(postItem?.children?.[i]);
																		}}
																		className={`tag-div-main ${nextSlide === i ? "active-circle" : ""}`}
																		style={{ top: item?.y, left: item?.x }}
																	>
																		{i + 1}
																	</div>
																))}
														</>
													) : (
														<Link
															to={{
																pathname: item.redirected_url,
															}}
															target="_blank"
															className="image_link"
														>
															<img src={item.media_url} alt="media_image" className="rounded image-inner-media" />
														</Link>
													)}
												</div>

												{postItem && item.source !== "brand_event" && (
													<div className="bio-image-thumbnail">
														{postItem?.children?.map((singleItem, key) => {
															return (
																<div
																	onClick={() => {
																		setNextSlide(key);
																		setChildrenPostItem(singleItem);
																	}}
																	key={key}
																	className="bio-t-img"
																>
																	<img src={singleItem.media_url} />
																</div>
															);
														})}
													</div>
												)}
											</div>
											{item.source === "brand_event" ? (
												<div className="event-right-area">
													<div className="countdown">
														<span className="timer-title">This live event will start in</span>
														<div className="ant-statistic">
															<Counter endDate={postItem.end_date} />
														</div>

														<div className="rsvp-btns">
															{userInfo?.account_type === "influencer" && bioShopMenu?.payload?.message?.data?.type !== "influencer" && (
																<NavLink
																	onClick={() => {
																		setAddToStore(true);
																	}}
																>
																	Add to my Store <i className="fa fa-share-square-o" aria-hidden="true"></i>
																</NavLink>
															)}

															{(postItem?.source === "brand_store" && socialStore) || (postItem?.source === "brand_event" && socialStore) ? (
																<NavLink
																	onClick={() => {
																		deactive(userInfo?.token, postItem?.post_id, false);
																	}}
																	to="#"
																>
																	Deactivate
																</NavLink>
															) : (
																""
															)}

															<NavLink
																onClick={() => {
																	share(postItem);
																}}
																to="#"
															>
																Share
															</NavLink>

															<NavLink
																onClick={() => {
																	addToFav(postItem?.post_id, childrenPostItem.id);
																}}
															>
																Add To List
															</NavLink>

															<NavLink
																onMouseDown={(e) => {
																	if (e.nativeEvent.button === 1) {
																		if (!userInfo) {
																			dispatch(openLogin(true));
																		} else {
																			window.open(productShopUrl(childrenPostItem.ProductUrl));
																			dispatch(getBioShopPostClick(postItem?.post_id, username, postItem?.media_url, postItem?.media_type, postItem?.caption, postItem?.timestamp, userId, postItem?.post_type, postItem?.media_id));
																		}
																	}
																}}
																onClick={(ev) => {
																	if (!userInfo) {
																		dispatch(openLogin(true));
																	} else {
																		window.open(productShopUrl(childrenPostItem.ProductUrl));
																		dispatch(getBioShopPostClick(postItem?.post_id, username, postItem?.media_url, postItem?.media_type, postItem?.caption, postItem?.timestamp, userId, postItem?.post_type, postItem?.media_id));
																	}
																}}
															>
																Go to Event
															</NavLink>
														</div>
														<div class="fee-row">
															{userInfo && account?.payload?.Data?.account_type !== "customer" ? item.children[0]?.influencerFee !== "0" && item.children[0]?.influencerFee ? <span className="inf-fee">{item.children[0]?.influencerFee}% Influencer Fee</span> : "" : ""}
															{userInfo && account?.payload?.Data?.account_type !== "customer" ? item.children[0]?.referral_percent !== "0" && item.children[0]?.referral_percent ? <span className="inf-fee">{item.children[0]?.referral_percent}% Referral Fee</span> : "" : ""}
															{item?.discount && item?.discount !== "0%" && <span class="off-percentage">{item?.discount} Off</span>}
															{item?.promo != "KB0" ? <span className="inf-fee">Promo {item.promo}</span> : ""}
														</div>
														{item?.caption && <span class="broadcaster-name">{item.caption}</span>}
														<div class="tim-author-name">
															{item?.brand[0].profile_image_url && (
																<span class="tim-auth-image">
																	<img src={item?.brand[0].profile_image_url} />
																</span>
															)}
															{item?.brand[0].brand_name && <span class="tim-auth-title">{item?.brand[0].brand_name}</span>}
														</div>
													</div>
												</div>
											) : (
												<div className="modal-content-right-area">
													<div className="cat-pro-detail">
														<div className="cat-pro-title">
															<span className="bio-item-title">{childrenPostItem?.ProductName}</span>

															{/* <span className="bio-item-fee">Referral Fee 3%</span> */}
														</div>

														<div className="cat-pro-price">
															{childrenPostItem?.productPromoCodePromo === "KB0" ? (
																<span></span>
															) : (
																<>
																	{" "}
																	<span className="original-price">${numeral(childrenPostItem?.productAmount).format("0.00")}</span>
																</>
															)}
															{childrenPostItem?.productAmount && <span className="discounted-price">{childrenPostItem?.productPromoCodePromo === "KB0" ? `$${numeral(childrenPostItem?.productAmount).format("0.00")}` : childrenPostItem?.productPromoCodeDscs?.includes("%") ? `$${numeral(childrenPostItem?.productAmount - discountPercent(childrenPostItem?.productPromoCodeDscs, childrenPostItem?.productAmount)).format("0.00")}  ` : `$${numeral(childrenPostItem?.productAmount - childrenPostItem?.productPromoCodeDscs?.replace(/[^0-9]/g, "")).format("0.00")}`}</span>}
														</div>
													</div>
													<div className="pro-cat-description">
														<p>{childrenPostItem?.productDesc}</p>
													</div>
													<div className="cat-pro-buttons">
														{postItem?.source === "brand_store" && socialStore ? (
															<span
																onClick={() => {
																	deactive(userInfo?.token, postItem?.post_id, false);
																}}
																className="btn btn-danger"
															>
																Deactivate <i className="fa fa-share-square-o" aria-hidden="true"></i>
															</span>
														) : (
															""
														)}
														{userInfo?.account_type === "influencer" && bioShopMenu?.payload?.message?.data?.type !== "influencer" && (
															<span
																onClick={() => {
																	setAddToStore(true);
																}}
																className="btn btn-primary"
															>
																Add to my Store <i className="fa fa-share-square-o" aria-hidden="true"></i>
															</span>
														)}
														<span
															onClick={() => {
																share(postItem);
															}}
															className="btn btn-primary"
														>
															Share <i className="fa fa-share-square-o" aria-hidden="true"></i>
														</span>

														<span
															onClick={() => {
																addToFav(postItem?.post_id, childrenPostItem.id);
															}}
															className="btn btn-primary"
														>
															Add To List <i className="fa fa-heart" aria-hidden="true"></i>
														</span>
														<span
															onMouseDown={(e) => {
																if (e.nativeEvent.button === 1) {
																	if (!userInfo) {
																		dispatch(openLogin(true));
																	} else {
																		window.open(productShopUrl(childrenPostItem.ProductUrl));
																		dispatch(getBioShopPostClick(postItem?.post_id, username, postItem?.media_url, postItem?.media_type, postItem?.caption, postItem?.timestamp, userId, postItem?.post_type, postItem?.media_id));
																	}
																}
															}}
															onClick={(ev) => {
																if (!userInfo) {
																	dispatch(openLogin(true));
																} else {
																	window.open(productShopUrl(childrenPostItem.ProductUrl));
																	dispatch(getBioShopPostClick(postItem?.post_id, username, postItem?.media_url, postItem?.media_type, postItem?.caption, postItem?.timestamp, userId, postItem?.post_type, postItem?.media_id));
																}
															}}
															className="btn btn-primary"
														>
															{postItem?.source === "brand_event" ? "Go to event" : "Website"}

															<i className="fa fa-shopping-cart" aria-hidden="true"></i>
														</span>
													</div>
													{userInfo?.account_type !== "influencer" ? item.children[0]?.referral_percent !== "0%" && item.children[0]?.referral_percent ? <span className="bio-item-fee">{item.children[0]?.referral_percent}% Referral Fee</span> : "" : ""}
													{userInfo && account?.payload?.Data?.account_type !== "customer" ? item.children[0]?.influencerFee !== "0%" && item.source !== "brand_event" && item.children[0]?.influencerFee ? <span className="inf-item-fee">{item.children[0]?.influencerFee}% Influencer Fee</span> : "" : ""}
												</div>
											)}
										</div>
									</>
								);
							})}
						</Carousel>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				backdropClassName="custom-backdrop"
				show={showShare}
				onHide={() => {
					setShowShare(false);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Share</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!url?.loading ? (
						<div className="your-copy-link">
							<div className="item-a">
								<a target="_blank" rel="noreferrer" href={Urls}>
									{Urls}
								</a>
							</div>
							<div onClick={() => copyToClipboard(Urls)} className="item-b">
								Copy
							</div>
						</div>
					) : (
						<div>Loading....</div>
					)}
				</Modal.Body>
			</Modal>

			<Modal
				backdropClassName="custom-backdrop"
				className="social-modal"
				show={addToStore}
				onHide={() => {
					setAddToStore(false);
					setChannel([]);
				}}
				centered
			>
				<Modal.Header closeButton>
					<h2>Add to Social Store</h2>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col span={12} className="mb-3">
							<Checkbox
								onChange={(e) => {
									channelChange(e);
								}}
								value="socialStore"
							>
								Add to Social Store
							</Checkbox>
						</Col>
						{userInfo?.page_token && userInfo?.fb_token ? (
							<>
								<Col span={12} className="mb-3">
									<Checkbox
										onChange={(e) => {
											channelChange(e);
										}}
										value="instragram"
									>
										Post on Instagram
									</Checkbox>
								</Col>
								<Col
									onChange={(e) => {
										channelChange(e);
									}}
									span={12}
									className="mb-3"
								>
									<Checkbox value="facebook">Post on Facebook</Checkbox>
								</Col>
							</>
						) : (
							<>
								<Col span={12} className="mb-3">
									<Checkbox disabled value="instragram">
										Post on Instagram
									</Checkbox>
								</Col>
								<Col span={12} className="mb-3">
									<Checkbox disabled value="facebook">
										Post on Facebook
									</Checkbox>
								</Col>
							</>
						)}
						<Col span={12} className="mb-3">
							<Checkbox disabled value="pinterest">
								Post on Pinterest
							</Checkbox>
						</Col>
						<Col span={12} className="mb-3">
							<Checkbox disabled value="linkedin">
								Post on Linkedin
							</Checkbox>
						</Col>
						<Col span={24} className="d-flex post-btns mt-3">
							{publishedPost?.loading || facebookPublish?.loading || instaPublish?.loading ? (
								<Button type="primary" size="large">
									<Spin indicator={antIcon} />
								</Button>
							) : (
								<Button disabled={channel.length === 0 && true} type="primary" size="large" onClick={() => publish(userInfo?.token, postItem)}>
									Add / Post
								</Button>
							)}
							<Button
								onClick={() => {
									setChannel([]);
									setAddToStore(false);
								}}
								type="primary"
								size="large"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	);
}
