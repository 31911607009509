import React, { useEffect, useState } from "react";
import { Tabs, Empty, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { removeFavouriteList, getFavouriteList } from "../../../redux/actions/favourite.action";
import Swal from "sweetalert2";
import Popup from "./popup";
const { TabPane } = Tabs;
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default () => {
	const [size, setSize] = useState("small");
	const [showCustomerModal, setsShowCustomerModal] = useState(false);
	const [singleItem, setSingleItem] = useState([]);

	// const onChange = (e) => {
	// 	setSize(e.target.value);
	// };

	const dispatch = useDispatch();
	const { favouriteList } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		dispatch(getFavouriteList(userInfo.token));
		// dispatch(getFavouriteBrandList(userInfo.token));
		// dispatch(getFavouriteInfluencerList(userInfo.token));
	}, []);

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	return (
		<>
			<div className="account-left-content">
				<div className="favourite-brands-container">
					<div>
						<Tabs defaultActiveKey="1" size={size}>
							<TabPane tab="My List" key="1">
								{favouriteList?.payload?.data.length === 0 && (
									<div className="align-items-center justify-content-center">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</div>
								)}
								<div className="favourite-brands">
									{favouriteList.loading ? (
										<div className="antd-loading-ift">
											<Spin size="large"></Spin>
										</div>
									) : favouriteList?.loading ? (
										<div className="antd-loading-ift">
											<Spin size="large"></Spin>
										</div>
									) : (
										favouriteList?.payload?.data?.map((item) => {
											//console.log('favourite list',item?.postData?.media_type)
											return (
												<div className="indv-brand">
													<a href="#">
														<div className="brand-boxes">
															<div className="brand-boxes-logos">
																{item?.postData?.media_type === "VIDEO" ? (
																	<video ontrolsList="nodownload" preload="metadata" poster={item.media_url}>
																		<source src={item?.postData?.media_url + "#t=0.001"} type="video/mp4"></source>
																	</video>
																) : (
																	<img src={item?.postData?.media_url} />
																)}
															</div>
															<div className="brand-user">
																<div className="brnd-user-title">
																	<span className="brnd-name-title">{item?.name}</span>
																</div>
																{item?.promo !== "KB0" && item.promo && (
																	<div className="ft-2">
																		<span>{item.discount} Off Sitewide</span>
																	</div>
																)}
																<div className="ft-3">
																	<span className="brnd-name-descrp">{item?.postData?.children[0]?.productDesc}</span>
																</div>
															</div>
															<div className="add-remove-button">
																<span
																	onClick={() => {
																		const swalWithBootstrapButtons = Swal.mixin({
																			customClass: {
																				confirmButton: "btn btn-danger",
																				cancelButton: "btn btn-success",
																			},
																			buttonsStyling: false,
																		});
																		swalWithBootstrapButtons
																			.fire({
																				title: item?.name,
																				text: "Are you sure you want to remove this item from your favourites?",
																				imageUrl: item?.postData?.media_url,
																				imageWidth: 100,
																				imageHeight: 100,
																				imageAlt: item?.name,
																				showCancelButton: true,
																				confirmButtonText: "Delete!",
																				cancelButtonText: "Cancel",
																				reverseButtons: true,
																			})
																			.then((result) => {
																				if (result.isConfirmed) {
																					dispatch(removeFavouriteList(userInfo?.token, item?.post_id)).then(() => {
																						dispatch(getFavouriteList(userInfo.token));
																						swalWithBootstrapButtons.fire("Deleted!", "Successfully removed from user favourite post list.", "success");
																					});
																				}
																			});
																	}}
																>
																	Remove
																</span>
																<span
																	onClick={() => {
																		setSingleItem(item);
																		setsShowCustomerModal(true);
																	}}
																>
																	View
																</span>
															</div>
														</div>
													</a>
												</div>
											);
										})
									)}
								</div>
							</TabPane>
							{/* <TabPane tab="Brands" key="2">
								<div className="favourite-brands">
									{favouriteBrandList?.loading ? (
										<div className="antd-loading-ift">
											<Spin size="large"></Spin>
										</div>
									) : favouriteBrandList?.payload?.data.length === 0 ? (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									) : (
										favouriteBrandList?.payload?.data?.map((item) => {
									
											return (
												<div className="indv-brand">
													<a href="#">
														<div className="brand-boxes">
															<div className="brand-boxes-logos">
																<img src={item?.profile_image_url} />
															</div>
															<div className="brand-user">
																<div className="brnd-user-title">
																	<span className="brnd-name-title">{item?.name}</span>
																</div>
																{item?.promo !== "KB0" && item.promo && (
																	<div className="ft-2">
																		<span>{item.discount} Off Sitewide</span>
																	</div>
																)}
															</div>
															<div className="add-remove-button rmv-2">
																<span
																	onClick={() => {
																		const swalWithBootstrapButtons = Swal.mixin({
																			customClass: {
																				confirmButton: "btn btn-danger",
																				cancelButton: "btn btn-success",
																			},
																			buttonsStyling: false,
																		});
																		swalWithBootstrapButtons
																			.fire({
																				title: item?.name,
																				text: "Are you sure you want to remove this item from your favourites?",
																				imageUrl: item?.postData?.media_url,
																				imageWidth: 100,
																				imageHeight: 100,
																				imageAlt: item?.name,
																				showCancelButton: true,
																				confirmButtonText: "Delete!",
																				cancelButtonText: "Cancel",
																				reverseButtons: true,
																			})
																			.then((result) => {
																				if (result.isConfirmed) {
																					dispatch(removeFavouriteBrandList(userInfo.token, item._id)).then((response) => {
																						dispatch(getFavouriteBrandList(userInfo.token));
																						swalWithBootstrapButtons.fire("Deleted!", "Successfully Removed from user favourite brand list.", "success");
																					});
																				}
																			});
																	}}
																>
																	Remove
																</span>
															</div>
														</div>
													</a>
												</div>
											);
										})
									)}
								</div>
							</TabPane>
							<TabPane tab="Influencers" key="3">
								<div className="favourite-brands">
									{favouriteInfluencerList?.loading ? (
										<div className="antd-loading-ift">
											<Spin size="large"></Spin>
										</div>
									) : favouriteInfluencerList?.payload?.data.length == 0 ? (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									) : (
										favouriteInfluencerList?.payload?.data?.map((item) => {
											return (
												<div className="indv-brand">
													<span href="#">
														<div className="brand-boxes">
															<div className="brand-boxes-logos">
																<img src={item?.profile_image_url} />
															</div>
															<div className="brand-user">
																<div className="brnd-user-title">
																	<span className="brnd-name-title">{item?.name}</span>
																</div>
																{item?.promo !== "KB0" && item.promo && (
																	<div className="ft-2">
																		<span>{item.discount} Off Sitewide</span>
																	</div>
																)}
															</div>
															<div className="add-remove-button rmv-2">
																<span
																	onClick={() => {
																		const swalWithBootstrapButtons = Swal.mixin({
																			customClass: {
																				confirmButton: "btn btn-danger",
																				cancelButton: "btn btn-success",
																			},
																			buttonsStyling: false,
																		});
																		swalWithBootstrapButtons
																			.fire({
																				title: item?.name,
																				text: "Are you sure you want to remove this item from your favourites?",
																				imageUrl: item?.postData?.media_url,
																				imageWidth: 100,
																				imageHeight: 100,
																				imageAlt: item?.name,
																				showCancelButton: true,
																				confirmButtonText: "Delete!",
																				cancelButtonText: "Cancel",
																				reverseButtons: true,
																			})
																			.then((result) => {
																				if (result.isConfirmed) {
																					dispatch(removeFavouriteBrandList(userInfo.token, item._id)).then((response) => {
																						dispatch(getFavouriteInfluencerList(userInfo.token));

																						swalWithBootstrapButtons.fire("Deleted!", "Successfully Removed from user favourite brand list.", "success");
																					});
																				}
																			});
																	}}
																>
																	Remove
																</span>
															</div>
														</div>
													</span>
												</div>
											);
										})
									)}
								</div>
							</TabPane> */}
						</Tabs>
					</div>
				</div>
			</div>
			<Popup showCustomerModal={showCustomerModal} setsShowCustomerModal={setsShowCustomerModal} item={singleItem.postData} postData={singleItem} />
		</>
	);
};
