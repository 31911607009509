import {
    GET_REVISE_POST_REQUEST,
    GET_REVISE_POST_SUCCESS,
    GET_REVISE_POST_ERROR,
  } from '../../types/types';
  
  export default function revisePost(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_REVISE_POST_REQUEST:
        return {
          loading: true,
        };
      case GET_REVISE_POST_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      case GET_REVISE_POST_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      default:
        return state;
    }
  }
  