import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import ScrollToTop from "../components/scrollTop/scrollTop";
import ProtectedRoute from "../components/protected/protected";
import Home from "../pages/home/home";
/**BioShop* */
import BioShop from "../pages/bioShop/index";
import CategoryPost from "../pages/bioShop/categoryPost";
import Link from "../pages/bioShop/link";
import Video from "../pages/bioShop/video";
import Profile from "../pages/bioShop/profile";
import BrandEvents from "../pages/bioShop/liveEvents";
//import PublishedEvents from "../pages/publishedEvent/publishEvent";
import PublishedContainer from "../pages/publishedEvent/publishedContainer";
/*Other Pages*/
import Category from "../pages/category/category";
import SubCategory from "../pages/subCategory/subCategory";
import SubSubCategory from "../pages/subSubCategory/subSubCategory";
import Influencer from "../pages/influencer/influencer";
import SubInfluencer from "../pages/subInfluener/subInfluener";
import ComingSoon from "../pages/comingSoon/comingSoon";
import Brand from "../pages/brand/brand";
import InfluncerCreator from "../pages/influencerCreator/influencerCreator";
/*Auth*/
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
/*Account Pages*/
import ChangePassword from "../pages/auth/changePassword";
import ResetPassword from "../pages/auth/resetPassword";
import Dashboard from "../pages/account/dashboard";
import Account from "../pages/account/account";
import AccountDetail from "../pages/account/accountDetail";
import SocialStore from "../pages/account/socialStore";
import Wallet from "../pages/account/wallet";
import BrandWallet from "../pages/account/brandWallet";
import Earning from "../pages/account/earning";
import BecomeInfluencer from "../pages/account/becomeInfluencer";
import Purchase from "../pages/account/purchase";
import Favourites from "../pages/account/favourite";
import Payment from "../pages/account/payment";
import ShippingAddress from "../pages/account/shippingAddress";
import ManageCategory from "../pages/account/ManageCategory";
import ReferralStats from "../pages/account/referralStats";
import MediaGallery from "../pages/account/mediaGallery";
import BioShopSetup from "../pages/account/reorderSocialstore";
import InfReviews from "../pages/bioShop/reviews";
import PageNotFound from "../pages/404/404";
import AboutUsPage from "../pages/aboutUs/aboutUs";
import PrivacyPolicyPage from "../pages/help/privacyPolicy";
import TermsServicePage from "../pages/help/termsAndCondition";
import ContactUsPage from "../pages/help/contact";
import FaqsPage from "../pages/help/faq";
import Logout from "../pages/logout/logout";
import Password from "../pages/password/password.js";

import LiveShopping from "../pages/liveShopping/liveShopping";
import LiveEventNew from "../pages/liveEvent/liveEventNew";
import UpComingEvent from "../pages/upComingEvent/upcomingEvent";
import InfluencerReviews from "../pages/InfluencerReviews/influencerReviews";


const Router = () => {
	let protectedPassword = window.location.href.split("/");
	return (
		<>
			<BrowserRouter>
				{protectedPassword[3] !== "password" && <Header />}
				<ScrollToTop>
					<Routes>
						<Route path="/brands" element={<Home />} />
						<Route path="/" element={<LiveShopping />} />
						<Route path="/live-shopping" element={<LiveShopping />} />
						{/* Bio Shop*/}
						<Route path="/:username" element={<BioShop />} />
						<Route path="/category/:username/:categoryId/:parentId" element={<CategoryPost />} />
						<Route path="/category/:username/:categoryId/:parentId/:sub" element={<CategoryPost />} />
						<Route path="/link/:username" element={<Link />} />
						<Route path="/brand-reviews/:username" element={<InfReviews />} />
						<Route path="/video/:username" element={<Video />} />
						<Route path="/profile/:username" element={<Profile />} />
						<Route path="/brand-events/:username" element={<BrandEvents />} />
						<Route path="/published-events/:id" element={<PublishedContainer />} />
						{/* Auth Link */}
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/change-password" element={<ChangePassword />} />
						{/* Shop Link */}
						<Route path="/retailer-brand" element={<Brand />} />
						<Route path="/influencer-creator" element={<InfluncerCreator />} />
						<Route path="/category" exact element={<Category />} />
						<Route path="/sub-category/:id" element={<SubCategory />} />
						<Route path="/sub-sub-category/:id/:category" element={<SubSubCategory />} />
						<Route path="/influencer" element={<Influencer />} />
						<Route path="/sub-influencer-category/:id" key={1} element={<SubInfluencer />} />
						<Route path="/sub-influencer-sub-category/:id/:category" key={2} element={<SubInfluencer />} />
						<Route path="/coming-soon" element={<ComingSoon />} />
						{/* Accoount Setup */}
						<Route
							path="/dashboard"
							element={
								<ProtectedRoute>
									<Dashboard />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/myaccount"
							element={
								<ProtectedRoute>
									<AccountDetail />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/accountdetail"
							element={
								<ProtectedRoute>
									<Account />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mywallet"
							element={
								<ProtectedRoute>
									<Wallet />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/brandwallet"
							element={
								<ProtectedRoute>
									<BrandWallet />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/earned-commission"
							element={
								<ProtectedRoute>
									<Earning />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/purchase"
							element={
								<ProtectedRoute>
									<Purchase />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/become-influencer"
							element={
								<ProtectedRoute>
									<BecomeInfluencer />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/favourite"
							element={
								<ProtectedRoute>
									<Favourites />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/payment"
							element={
								<ProtectedRoute>
									<Payment />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mediagallery"
							element={
								<ProtectedRoute>
									<MediaGallery />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/reorder-socialstore"
							element={
								<ProtectedRoute>
									<BioShopSetup />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/shippingaddress"
							element={
								<ProtectedRoute>
									<ShippingAddress />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/managecategory"
							element={
								<ProtectedRoute>
									<ManageCategory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/referralstats"
							element={
								<ProtectedRoute>
									<ReferralStats />
								</ProtectedRoute>
							}
						/>
						<Route path="/logout" element={<Logout />} />
						<Route path="/password-reset/:id" element={<ResetPassword />} />

						{/* Accoount Setup */}

						<Route path="/404" element={<PageNotFound />} />
						<Route path="/about-us" element={<AboutUsPage />} />
						<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
						<Route path="/terms-service" element={<TermsServicePage />} />
						<Route path="/contact-us" element={<ContactUsPage />} />
						<Route path="/faqs" element={<FaqsPage />} />

						{/* Event Pages */}

						<Route path="/live-event/:id/:username" element={<LiveEventNew />} />

						{/* <Route path="/live-event-new/:id/:username" element={<LiveEventNew />} /> */}
						<Route path="/upcoming-event/:id/:username" element={<UpComingEvent />} />

						{/* Influencer SocialStore */}

						<Route path="/influencer-reviews/:id/:brandId" element={<InfluencerReviews />} />
	
						{/* Influencer SocialStore */}

						<Route path="influencer-socialstore/:username/:token" element={<SocialStore />} />

						{/* Error Page */}

						<Route path="*" element={<PageNotFound />} />
						<Route path="/password" element={<Password />} />
					</Routes>
				</ScrollToTop>

				{protectedPassword[3] !== "password" && <Footer />}
			</BrowserRouter>
		</>
	);
};
export default Router;
