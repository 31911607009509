import {FACEBOOK_TOKEN_REQUEST, FACEBOOK_TOKEN_SUCCESS , FACEBOOK_TOKEN_ERROR } from "../../types/types";

export default function tokenFb(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case FACEBOOK_TOKEN_REQUEST:
      return {
        loading: true,
      };
    case FACEBOOK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case FACEBOOK_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}