import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Button, Form } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import { getReferralStats } from "../../../redux/actions/customersale.action";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import numeral from "numeral";
import ReactPaginate from "react-paginate";
const { Option } = Select;

const { RangePicker } = DatePicker;

export default () => {
	const [form] = Form.useForm();
	const dateFormat = "YYYY-MM-DD";
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const fromDate = moment().startOf("month").format("YYYY-MM-DD");
	const toDate = moment(new Date()).format("YYYY-MM-DD");
	const [getEvent, setGetEvent] = useState([]);
	const [totalEvent, setTotalEvent] = useState();
	const [currentPage, setCurrentPage] = useState(0);
	const [startDate, setStartDate] = useState(fromDate);
	const [endDate, setEndDate] = useState(toDate);
	const [source, setSource] = useState("all");
	const [sortBy, setSortBy] = useState("share");
	const [submit, setSubmit] = useState("");

	const { referralStats } = useSelector((state) => {
		return state;
	});

	// useEffect(() => {
	//   if (userInfo.token) dispatch(getreferralStats(userInfo?.token));
	// }, []);

	const limit = 8;
	const upperLimit = (currentPage + 1) * limit;
	const lowerLimit = upperLimit - limit + 1;

	useEffect(() => {
		setLoading(true);
		dispatch(getReferralStats(1, limit, startDate, endDate, "all", sortBy)).then((res) => {
			setLoading(false);
			setGetEvent(res?.payload?.message);
			setTotalEvent(res?.payload?.message?.total_records);
		});
	}, []);

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	  }, []);
	
	  // This is componentWillUnmount
	  useEffect(() => {
		return () => {
		//  componentWillUnmount.current = true;
		  document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	  }, []);

	const dateRangePickerChanger = (value, dataString) => {
		const startDate = dataString[0];
		const endDate = dataString[1];
		setStartDate(startDate);
		setEndDate(endDate);
	};

	const handlePageClick = (e) => {
		const page = e.selected;
		setCurrentPage(page);
		setLoading(true);
		dispatch(getReferralStats(page + 1, limit, startDate, endDate, source, sortBy)).then((res) => {
			setLoading(false);
			setGetEvent(res?.payload?.message);
			setTotalEvent(res?.payload?.message?.total_records);
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setCurrentPage(0);
		setLoading(true);
		dispatch(getReferralStats(1, limit, startDate, endDate, source, sortBy)).then((res) => {
			setSubmit(source);
			setLoading(false);
			setGetEvent(res?.payload?.message);
			setTotalEvent(res?.payload?.message?.total_records);
		});
	};

	const handleSource = (e) => {
		setSource(e);
	};
	const handleSortBy = (e) => {
		setSortBy(e);
	};

	const handleReset = () => {
		setSource("all");
		setSortBy("share");
		setCurrentPage(0);
		setLoading(true);
		setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
		setEndDate(moment(new Date()).format("YYYY-MM-DD"));
		setSubmit("");
		dispatch(getReferralStats(1, limit, moment().startOf("month").format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"), "all", "share")).then((res) => {
			setLoading(false);
			setGetEvent(res?.payload?.message);
			setTotalEvent(res?.payload?.message?.total_records);
		});
	};
	const getSum = (a1, a2) => parseFloat(a1) + parseFloat(a2);
	function Summary() {
		return (
			referralStats?.payload?.message?.summary.length > 0 && (
				<div className="sales-summery" style={{marginLeft:-17}}>
					<div className="row">
						<div className="col-lg-6 col-xl-4">
							{referralStats?.payload?.message?.summary.map((item) => {
								return (
									<>
										<div className="summarry-box">
											<h4 className="summary-hd">Summary</h4>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Shares</h4>
												<h5 className="count">{item?.shares}</h5>
											</div>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Views</h4>
												<h5 className="count">{item?.views}</h5>
											</div>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Orders</h4>
												<h5 className="count">{item?.order_count ? item?.order_count : 0}</h5>
											</div>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Quantity</h4>
												<h5 className="count">{item?.total_qty ? item?.total_qty : 0}</h5>
											</div>
											{/* <div className="col-12 summary-detail-box">
												<h4 className="count-title">Total Earning</h4>
												<h5 className="count">{numeral(item?.cashback_amount + item.customer_commission + item.influencer_commission).format("$0,0.0'")}</h5>
											</div> */}
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Price</h4>
												<h5 className="count">{numeral(item?.total_sale).format("$0,0.0'")}</h5>
											</div>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Total Discount</h4>
												<h5 className="count">{numeral(item?.discount).format("$0,0.0'")}</h5>
											</div>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Total Amount Paid</h4>
												<h5 className="count">{numeral(item?.order_totalprice).format("$0,0.0'")}</h5>
											</div>
											<div className="col-12 summary-detail-box">
												<h4 className="count-title">Total Referral Commission</h4>
												<h5 className="count">${numeral(getSum(item.customer_commission, item.cashback_amount)).format("0.00")}</h5>
											</div>
										</div>
									</>
								);
							})}
						</div>
					</div>
				</div>
			)
		);
	}

	function dataTable() {
		let data = referralStats?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								<th></th>
								<th>Title</th>
								<th>Brand</th>
								<th>Category</th>
								<th>Source</th>
								<th>Shares</th>
								<th>Views</th>
								<th>No. Of Orders</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Discounts</th>
								<th>Amount Paid</th>
								<th>Commission</th>
							</tr>
						</thead>
						<tbody>
							{data.length === 0 ? (
								<tr>
									<td colspan="15">
										<Empty
											
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</td>
								</tr>
							) : (
								data?.map((item, i) => {
									return (
										<tr>
											<td>{i + 1}</td>
											<td>
												{item?.post ? (
													<div className="listing-image default-listing-image">
														{item?.post?.media_type === "VIDEO" ? (
															<video
																id={`post-video-${item?.post._id}`}
																//autoPlay
																controls
																controlsList="nodownload"
																className="img-fluid"
															>
																<source src={item?.post?.media_url} type="video/mp4"></source>
															</video>
														) : (
															<img className="full-cover" src={`${item?.post?.media_url}`} />
														)}
													</div>
												) : (
													<div className="listing-image default-listing-image">
														<img className="full-cover" src={`${item?.event?.banner}`} />
													</div>
												)}
											</td>
											{item?.post ? <td title={item?.post?.children[0]?.ProductName}>{item?.post?.children[0]?.ProductName.substring(0, 100)}</td> : <td title={item?.event?.title}>{item?.event?.title.substring(0, 100)}</td>}
											<td>{item?.eventbrand?.name ? item?.eventbrand?.name : item.postbrand?.name}</td>
											<td>{item?.eventcategory?.category_name ? item?.eventcategory?.category_name : item?.postcategory?.category_name}</td>
											<td>{item?.post ? "Social Store" : "Event"}</td>
											<td>{item?.shares ? item?.shares : 0}</td>
											<td>{item?.views ? item?.views : 0 }</td>
											<td>{item?.order_count ? item?.order_count : 0}</td>
											<td>{item?.total_qty ? item?.total_qty : 0}</td>
											<td>{numeral(item?.total_sale).format("$0,0.0'")}</td>
											<td>{numeral(item?.discount).format("$0,0.0'")}</td>
											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.customer_commission).format("$0,0.0'")}</td>
										</tr>
									);
								})
							)}
							{referralStats?.payload?.message?.data?.length ? (
								<tr>
									<td>
									</td>
									<td >
									
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td className="total-sales"><b>Total</b></td>

									<td className="total-sales">
										<b>
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.shares ? b.shares : 0.0);
												}, 0)
											).format("0,0")}
										</b>
									</td>
									<td className="total-sales">
										<b>
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.views ? b.views : 0.0);
												}, 0)
											).format("0,0")}
										</b>
									</td>
									<td className="total-sales">
										<b>
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_count ? b.order_count : 0.0);
												}, 0)
											).format("0,0")}
										</b>
									</td>
									<td className="total-sales">
										<b>
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.total_qty ? b.total_qty : 0.0);
												}, 0)
											).format("0,0")}
										</b>
									</td>
									<td className="total-sales">
										<b>
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.total_sale ? b.total_sale : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td className="total-sales">
										<b>
											{numeral(
												getEvent.data.reduce((a, b) => {
													return Number(a) + Number(b.discount ? b.discount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td className="total-sales">
										<b>
											{numeral(
												getEvent.data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{numeral(
												getEvent.data.reduce((a, b) => {
													return Number(a) + Number(b.customer_commission ? b.customer_commission : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
								</tr>
							) : (
								""
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}
	return (
		<>
			<div className="account-left-content">
				<div className="purchase-content-main">
					<Form form={form}>
						<div className="row">
							<div className="col-xl-3 col-md-6 col-12 mb-20">
								<p className="fw-bold">Select Start Date / End Date</p>
								<RangePicker
									size="large"
									className="w-100"
									key={4}
									value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
									allowClear={false}
									ranges={{
										Today: [moment(), moment()],
										Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
										Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
										"This Month": [moment().startOf("month"), moment().endOf("month")],
										"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
									}}
									format={dateFormat}
									onChange={dateRangePickerChanger}
								/>
							</div>
							<div className="col-xl-3 col-md-6 col-12 mb-20">
								<p>Source </p>
								<Select className="select-style dark-field w-100" placeholder="Source" size="large" focus={false} onBlur={false} onChange={handleSource} value={source === "" ? null : source}>
									<Option value="all">All</Option>
									<Option value="store">Social Store</Option>
									<Option value="event">Event</Option>
								</Select>
							</div>
							<div className="col-xl-3 col-md-6 col-12 mb-20">
								<p>Sort By</p>
								<Select className="select-style dark-field w-100" placeholder="Sort By" size="large" focus={false} onBlur={false} onChange={handleSortBy} value={sortBy === "" ? null : sortBy}>
									<Option value="share">Shares</Option>
									<Option value="view">Views</Option>
									<Option value="grosssale">Price</Option>
									<Option value="netsale">Amount Paid</Option>
								</Select>
							</div>
							<div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-20">
								<Button onClick={handleSubmit} className="ant-btn ant-btn-default ant-btn-lg">
									Search
								</Button>
								<Button onClick={handleReset} className="ant-btn ant-btn-default ant-btn-lg">
									Reset
								</Button>
							</div>
						</div>
					</Form>

					{loading ? (
						<div className="antd-loading-ift">
							<Spin size="large"></Spin>
						</div>
					) : (
						<>
							{/* {totalEvent === 0 ? ( */}
							{!referralStats?.payload?.message?.data?.length === 0 ? (
								<tr>
									<td colspan="5">
										<Empty
											style={
												{
													// display: "flex",
													// justifyContent: "center",
													// alignItems: "center",
												}
											}
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</td>
								</tr>
							) : (
								<>
									<div className="purchase-data referral-data">
										{dataTable()}
										{referralStats?.payload?.message?.data?.length ? (
											<ReactPaginate
												nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
												onPageChange={handlePageClick}
												pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
												// marginPagesDisplayed={2}
												pageCount={Math.ceil(totalEvent / limit)}
												forcePage={currentPage}
												previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
												pageClassName="page-item d-none"
												pageLinkClassName="page-link"
												previousClassName="page-item"
												previousLinkClassName="page-link"
												nextClassName="page-item"
												nextLinkClassName="page-link"
												breakLabel="..."
												breakClassName="page-item d-none"
												breakLinkClassName="page-link"
												containerClassName={"pagination custom-paginate"}
												activeClassName="active"
												renderOnZeroPageCount={null}
											/>
										) : (
											""
										)}
										<Summary />
										<br></br>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};
