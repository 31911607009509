import { GET_PURCHASE_REPORT_REQUEST, GET_PURCHASE_REPORT_SUCCESS, GET_PURCHASE_REPORT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getPurchaseReport = (token, fromDate, toDate,source,group ,limit = 25) => (dispatch) => {
		let dateRange = "";
		if (fromDate && toDate) dateRange = `&from_date=${fromDate}&to_date=${toDate}&event_type=${source}&group_by=${group}`;
		dispatch({ type: GET_PURCHASE_REPORT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/private/getCustomerPurchaseList?limit=${limit}${dateRange}`,
			headers: { Authorization: `Bearer ${token}` },
			data:{
				event_type:source,
				group_by:group,
				from_date:fromDate,
				to_date:toDate
			}
		})
			.then((response) => {
				return dispatch({
					type: GET_PURCHASE_REPORT_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_PURCHASE_REPORT_ERROR,
					payload: error.response,
				});
			});
	};
