import {
    GET_INSTAGRAM_MEDIA_REQUEST,
    GET_INSTAGRAM_MEDIA_SUCCESS,
    GET_INSTAGRAM_MEDIA_ERROR,
  } from '../../types/types';
  
  export default function instagramMedia(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_INSTAGRAM_MEDIA_REQUEST:
        return {
          loading: true,
        };
      case GET_INSTAGRAM_MEDIA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      case GET_INSTAGRAM_MEDIA_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      default:
        return state;
    }
  }