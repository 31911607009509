import React, { useState, useEffect } from "react";
import { DatePicker, Button, Form, Empty, Spin, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getCommissionReport } from "../../../redux/actions/wallet.action";
import Table from "react-bootstrap/Table";
// import wallet1 from "../../../images/brand-logos/commission-discounts.svg";
import numeral from "numeral";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Commission() {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const { commission } = useSelector((state) => state);
  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  const [source, setSource] = useState("all");
  const [sortBy, setSortBy] = useState("date");
  const [groupBy, setGroupBy] = useState("none");
  const [submit, setSubmit] = useState("none");

  useEffect(() => {
    //dispatch(getEarnCommission(userInfo?.token, startDate, endDate));
    dispatch(getCommissionReport(userInfo?.token, startDate, endDate, source, sortBy));
  }, []);

  const handleSource = (e) => {
    setSource(e);
  };

  const handleSortBy = (e) => {
    setSortBy(e);
  };

  const handleGroupBy = (e) => {
    setGroupBy(e);
  };

  const handleReset = () => {
    setSource("all");
    setSortBy("date");
    setGroupBy("none");
    setSubmit("none");
    setStartDate(fromDate);
    setEndDate(toDate);
    dispatch(getCommissionReport(userInfo?.token, fromDate, toDate, "all", "date", "none"));
  };

  const onFinish = async () => {
    if (userInfo.token) {
      setSubmit(groupBy);
      dispatch(getCommissionReport(userInfo?.token, startDate, endDate, source, sortBy, groupBy));
      //dispatch(getEarnCommission(userInfo?.token, startDate, endDate));
    }
  };

  const onFinishFailed = (errorInfo) => console.log("Failed:", errorInfo);
  const getSum = (a1, a2,a3) => parseFloat(a1) + parseFloat(a2) + parseFloat(a3);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	  }, []);
	
	  // This is componentWillUnmount
	  useEffect(() => {
		return () => {
		//  componentWillUnmount.current = true;
		  document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	  }, []);

  const DefaultTable = () => {
    return (
      <Table responsive className="transactions-box table">
        <thead className="table_heading">
          <tr>
            <th>S.# </th>
            <th></th>
            <th>Product Name</th>
            <th>Source</th>
            <th>Date</th>
            <th>Category</th>
            <th>Brand</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Amount Paid</th>
            <th>Earnings</th>
            <th>Earning Source</th>
          </tr>
        </thead>
        <tbody>
          {commission?.payload?.message?.data?.length === 0 ? (
            <tr>
              <td colspan="15">
                <Empty
                  style={
                    {
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    }
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </td>
            </tr>
          ) : (
            commission?.payload?.message?.data?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    <div className="listing-image default-listing-image">
                      <img className="full-cover" s src={item?.post?.media_url ? item?.post?.media_url : item?.event_payload?.banner} />
                    </div>
                  </td>
                  <td>
                    <div className="brand-user-pro">{item?.post?.children[0]?.ProductName ? item?.post?.children[0]?.ProductName : item?.event_payload?.title}</div>
                  </td>
                  <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
                  <td>{item?.created_date}</td>
                  <td>{item?.parent_category?.category_name}</td>
                  <td>{item?.brand_name}</td>
                  <td>{numeral(item?.total_qty).format("0")}</td>
                  <td>${numeral(item?.total_sale).format("0.00")}</td>
                  <td>${numeral(item?.discount).format("0.00")}</td>
                  <td>${numeral(item?.order_totalprice).format("0.00")}</td>
                  <td>${numeral(getSum(item?.customer_commission, item?.cashback_amount, item?.influencer_commission)).format("0.00")}</td>
                  <td>{item.event === "shop" || item.event === "eventshop" ? "CashBack" : "Referral Commission"}</td>
                </tr>
              );
            })
          )}
          {commission?.payload?.message?.summary?.length > 0 && (
            <tr>
              <td>
                <b></b>
              </td>
              <td></td>
              <td>
                <b></b>
              </td>
              <td>
                <b></b>
              </td>
              <td>
                <b></b>
              </td>
              <td>
                <b></b>
              </td>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.total_qty ? b.total_qty : 0.0);
                    }, 0)
                  ).format("0,0")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.total_sale ? b.total_sale : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.discount ? b.discount : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    getSum(
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return parseFloat(a) + parseFloat(b.customer_commission ? b.customer_commission : 0.0);
                      }, 0),
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return parseFloat(a) + parseFloat(b.cashback_amount ? b.cashback_amount : 0.0);
                      }, 0),
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return parseFloat(a) + parseFloat(b.influencer_commission ? b.influencer_commission : 0.0);
                      }, 0)
                    )
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b></b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const DateTable = () => {
    return (
      <Table responsive="scrollable" className="transactions-box table">
        <thead className="table_heading">
          <tr>
            <th>S.# </th>
            <th>Date</th>
            <th>Source</th>
            <th>No. Of Orders </th>
            <th>Qty</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Amount Paid</th>
            <th>Earnings</th>
            <th>Earning Source</th>
          </tr>
        </thead>
        <tbody>
          {commission?.payload?.message?.data?.length === 0 ? (
            <tr>
              <td colspan="10">
                <Empty
                  style={
                    {
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    }
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </td>
            </tr>
          ) : (
            commission?.payload?.message?.data?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item?.created_date}</td>
                  <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
                  <td>{numeral(item?.order_count).format("0")}</td>
                  <td>{numeral(item?.total_qty).format("0")}</td>
                  <td>${numeral(item?.total_sale).format("0.00")}</td>
                  <td>${numeral(item?.discount).format("0.00")}</td>
                  <td>${numeral(item?.order_totalprice).format("0.00")}</td>
                  <td>${numeral(getSum(item?.customer_commission, item?.cashback_amount , item?.influencer_commission)).format("0.00")}</td>
                  <td>{item.event === "shop" || item.event === "eventshop" ? "CashBack" : "Referral Commission"}</td>
                </tr>
              );
            })
          )}
          {commission?.payload?.message?.summary?.length > 0 && (
            <tr>
              <td>
                <b></b>
              </td>
              <td></td>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.order_count ? b.order_count : 0.0);
                    }, 0)
                  ).format("0,0")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.total_qty ? b.total_qty : 0.0);
                    }, 0)
                  ).format("0,0")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.total_sale ? b.total_sale : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.discount ? b.discount : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    getSum(
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return Number(a) + Number(b.customer_commission ? b.customer_commission : 0.0);
                      }, 0),
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
                      }, 0),
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return Number(a) + Number(b.influencer_commission ? b.influencer_commission : 0.0);
                      }, 0)
                    )
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b></b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const BrandTable = () => {
    return (
      <Table responsive="scrollable" className="transactions-box table">
        <thead className="table_heading">
          <tr>
            <th>S.# </th>
            <th>Brand</th>
            <th>Source</th>
            <th>No. Of Orders </th>
            <th>Qty</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Amount Paid</th>
            <th>Earnings</th>
            <th>Earning Source</th>
          </tr>
        </thead>
        <tbody>
          {commission?.payload?.message?.data?.length === 0 ? (
            <tr>
              <td colspan="10">
                <Empty
                  style={
                    {
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    }
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </td>
            </tr>
          ) : (
            commission?.payload?.message?.data?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item?.brand_name}</td>
                  <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
                  <td>{numeral(item?.order_count).format("0")}</td>
                  <td>{numeral(item?.total_qty).format("0")}</td>
                  <td>${numeral(item?.total_sale).format("0.00")}</td>
                  <td>${numeral(item?.discount).format("0.00")}</td>
                  <td>${numeral(item?.order_totalprice).format("0.00")}</td>
                  <td>${numeral(getSum(item?.customer_commission, item?.cashback_amount , item?.influencer_commission)).format("0.00")}</td>
                  <td>{item.event === "shop" || item.event === "eventshop" ? "CashBack" : "Referral Commission"}</td>
                </tr>
              );
            })
          )}

          {commission?.payload?.message?.summary?.length > 0 && (
            <tr>
              <td>
                <b></b>
              </td>
              <td></td>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.order_count ? b.order_count : 0.0);
                    }, 0)
                  ).format("0")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.total_qty ? b.total_qty : 0.0);
                    }, 0)
                  ).format("0")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.total_sale ? b.total_sale : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.discount ? b.discount : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    commission?.payload?.message?.data.reduce((a, b) => {
                      return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
                    }, 0)
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b>
                  {" "}
                  {numeral(
                    getSum(
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return parseFloat(a) + parseFloat(b.customer_commission ? b.customer_commission : 0.0);
                      }, 0),
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return parseFloat(a) + parseFloat(b.cashback_amount ? b.cashback_amount : 0.0);
                      }, 0),
                      commission?.payload?.message?.data.reduce((a, b) => {
                        return parseFloat(a) + parseFloat(b.influencer_commission ? b.influencer_commission : 0.0);
                      }, 0)
                    )
                  ).format("$0,0.0'")}
                </b>
              </td>
              <td>
                <b></b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  function Summary() {
    return (
      commission?.payload?.message?.summary.length > 0 && (
        <div className="sales-summery">
          <div className="row">
            <div className="col-lg-6 col-xl-4">
              {commission?.payload?.message?.summary.map((item) => {
                return (
                  <>
                    <div className="summarry-box">
                      <h4 className="summary-hd">Summary</h4>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Orders</h4>
                        <h5 className="count">{item?.order_count ? item?.order_count : 0}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Quantity</h4>
                        <h5 className="count">{item?.total_qty ? item?.total_qty : 0}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Earning</h4>
                        <h5 className="count">{numeral(parseFloat(item?.cashback_amount) + parseFloat(item.customer_commission) + parseFloat(item.influencer_commission)).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Price</h4>
                        <h5 className="count">{numeral(item?.total_sale).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Discount</h4>
                        <h5 className="count">{numeral(item?.discount).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Amount Paid</h4>
                        <h5 className="count">{numeral(item?.order_totalprice).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Cashback Amount</h4>
                        <h5 className="count">{numeral(item?.cashback_amount).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Referral Commission</h4>
                        <h5 className="count">{numeral(item?.customer_commission).format("$0,0.0'")}</h5>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )
    );
  }
  return (
    <div className="account-left-content">
      <div className="wallet-content-main">
        {/* <div className="wallet-row-1">
					<div className="balance-box">
						<div className="inr-box-main">
							<div className="wallet-icon">
								<img src={wallet1} alt="" className="filter-gray" />
							</div>
							<div className="wallet-data">
								<div className="wallet-detail">
									<span className="box-title">This Month</span>
									<div className="box-ammount">
										${commissionEarn?.payload?.data?.dateRangeEarningTotal ? numeral(commissionEarn?.payload?.data?.dateRangeEarningTotal).format("0.00") : 0}
										
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="balance-box">
						<div className="inr-box-main">
							<div className="wallet-icon">
								<img src={wallet1} alt="" className="filter-gray" />
							</div>
							<div className="wallet-data">
								<div className="wallet-detail">
									<span className="box-title">Total Earning</span>
									<div className="box-ammount">${commissionEarn?.payload?.data?.total_balance ? numeral(commissionEarn?.payload?.data?.total_balance).format("0.00") : 0}</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
        <div className="wallet-row-2">
          <div className="purchase-content-main">
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <div className="row">
                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p className="fw-bold">Select Start Date / End Date</p>
                  <RangePicker
                    size="large"
                    className="w-100"
                    key={4}
                    value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
                    allowClear={false}
                    ranges={{
                      Today: [moment(), moment()],
                      Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
                      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                      "This Month": [moment().startOf("month"), moment().endOf("month")],
                      "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
                    }}
                    format={dateFormat}
                    onChange={dateRangePickerChanger}
                  />
                </div>
                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Source </p>
                  <Select className="select-style dark-field w-100" placeholder="Source" size="large" focus={false} onBlur={false} onChange={handleSource} value={source === "" ? null : source}>
                    <Option value="all">All</Option>
                    <Option value="store">Social Store</Option>
                    <Option value="event">Event</Option>
                  </Select>
                </div>
                
                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Group By</p>
                  <Select className="select-style dark-field w-100" placeholder="Group By" size="large" focus={false} onBlur={false} onChange={handleGroupBy} value={groupBy === "" ? null : groupBy}>
                    <Option value="none">None</Option>
                    <Option value="date">Date</Option>
                    <Option value="brand">Brand</Option>
                  </Select>
                </div>
                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Sort By</p>
                  <Select className="select-style dark-field w-100" placeholder="Sort By" size="large" focus={false} onBlur={false} onChange={handleSortBy} value={sortBy === "" ? null : sortBy}>
                    <Option value="date">Date</Option>
                    <Option value="grosssale">Price</Option>
                    <Option value="netsale">Amount Paid</Option>
                  </Select>
                </div>
                <div className="col-xl-4 col-md-6 col-12 d-flex align-items-end mb-20">
                  <Button htmlType="submit" className="ant-btn ant-btn-default ant-btn-lg">
                    Search
                  </Button>
                  <Button onClick={handleReset} className="ant-btn ant-btn-default ant-btn-lg">
                    Reset
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {commission?.loading ? (
            <div className="antd-loading-ift">
              <Spin size="large"></Spin>
            </div>
          ) : (
            <div className="purchase-data referral-data">
              {submit === "none" && <DefaultTable />}
              {submit === "date" && <DateTable />}
              {submit === "brand" && <BrandTable />}
              <Summary />
              <br></br>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
