import { BRAND_LIVE_EVENT_REQUEST, BRAND_LIVE_EVENT_SUCCESS, BRAND_LIVE_EVENT_ERROR, LIVE_EVENT_REQUEST, LIVE_EVENT_SUCCESS, LIVE_EVENT_ERROR, ALL_LIVE_EVENT_REQUEST, ALL_LIVE_EVENT_SUCCESS, ALL_LIVE_EVENT_ERROR, ALL_UPCOMING_EVENT_REQUEST, ALL_UPCOMING_EVENT_SUCCESS, ALL_UPCOMING_EVENT_ERROR, UPCOMING_EVENT_REQUEST, UPCOMING_EVENT_SUCCESS, UPCOMING_EVENT_ERROR, ALL_PUBLISH_EVENT_REQUEST, ALL_PUBLISH_EVENT_SUCCESS, ALL_PUBLISH_EVENT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getAllUpComingEvent =
  (page = 1, limit = 25, ids = [], brand, sort_by) =>
  (dispatch) => {
    dispatch({ type: ALL_UPCOMING_EVENT_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}public/events/upcoming?page=${page}&limit=${limit}`,
      headers: {
        Accept: "application/json",
      },
      data: { ids: ids, brand: brand, sort_by: sort_by },
    })
      .then((response) => {
        return dispatch({
          type: ALL_UPCOMING_EVENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ALL_UPCOMING_EVENT_ERROR,
          payload: error.response,
        });
      });
  };

export const getAllLiveEvent =
  (page = 1, limit = 25, ids = [], brand, sort_by) =>
  (dispatch) => {
    dispatch({ type: ALL_LIVE_EVENT_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}public/events/live?page=${page}&limit=${limit}`,
      headers: {
        Accept: "application/json",
      },
      data: { ids: ids, brand: brand, sort_by: sort_by },
    })
      .then((response) => {
        return dispatch({
          type: ALL_LIVE_EVENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ALL_LIVE_EVENT_ERROR,
          payload: error.response,
        });
      });
  };

export const getAllPublishedEvent =
  (page = 1, limit = 25, ids = [], brand, sort_by) =>
  (dispatch) => {
    dispatch({ type: ALL_PUBLISH_EVENT_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}public/events/published?page=${page}&limit=${limit}`,
      headers: {
        Accept: "application/json",
      },
      data: { ids: ids, brand: brand, sort_by: sort_by },
    })
      .then((response) => {
        return dispatch({
          type: ALL_PUBLISH_EVENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ALL_PUBLISH_EVENT_ERROR,
          payload: error.response,
        });
      });
  };

export const getBrandLiveEvent =
  (page = 1, limit = 25, username, sort_by) =>
  (dispatch) => {
    dispatch({ type: BRAND_LIVE_EVENT_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}public/events/getbrandevents?page=${page}&limit=${limit}`,
      headers: {
        Accept: "application/json",
      },
      data: {
        username: username,
        sort_by: sort_by,
      },
    })
      .then((response) => {
        return dispatch({
          type: BRAND_LIVE_EVENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: BRAND_LIVE_EVENT_ERROR,
          payload: error.response,
        });
      });
  };

export const getLiveEvent = (id) => (dispatch) => {
  dispatch({ type: LIVE_EVENT_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}events/getscheduleevent/${id}`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return dispatch({
        type: LIVE_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LIVE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getUpComingEvent = (id) => (dispatch) => {
  dispatch({ type: UPCOMING_EVENT_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}public/events/getoneupcomingevent/${id}`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return dispatch({
        type: UPCOMING_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPCOMING_EVENT_ERROR,
        payload: error.response,
      });
    });
};
