import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getParentCategories } from "../../redux/actions/category.action";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/category.scss";

export default () => {
  const dispatch = useDispatch();
  const [allCategory, setAllCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getParentCategories()).then((res) => {
      setAllCategory(res.payload.data);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="cat_brands_main all-cat-page">
        <div className="midd-width-ifti">
          <div className="cat_brands">
            <div className="pik-header">
              <h3>All Categories</h3>
            </div>
            <div className="category_slider">
              <div className="all_categories_ift">
                {loading ? (
                  <>
                    {new Array(7).fill().map((_, i) => {
                      return (
                        <div className="indv-categ">
                          <Link to={"#"}>
                            <div className="categ-boxes"></div>
                            <h4><Spin size="small" /></h4>
                          </Link>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  allCategory.map((item) => {
                    return (
                      <>
                        <div className="indv-categ">
                          <Link to={`/sub-influencer-category/${item._id}`}>
                            <div className="categ-boxes">
                              <img src={item.image_url} />
                            </div>
                            <h4>{item.category_name}</h4>
                          </Link>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};