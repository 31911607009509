import { GET_MEDIA_SUMMARY } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getMediaSummary = (fromDate, toDate) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`${BASEURL}library/dashboard/summary`, {
        from_date: fromDate,
        to_date: toDate,
      },{headers: { Authorization: `Bearer ${userInfo?.token}`},})
      .then((res) => {
        dispatch({
          type: GET_MEDIA_SUMMARY,
          payload: res.data.message,
        });
        resolve("success");
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};
