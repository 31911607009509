import React, { useState, useEffect, useMemo } from "react";
import { Select, Spin, Button } from "antd";
import inficon from "../../../images/brand-logos/hOuVCU.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import queryString from "query-string";
import { facebookUser, facebookReset, getFbPages, fbsToken, facebookAccesstoken, tokenAccess, facebookSelected, facebookAccess } from "../../../redux/actions/facebook.action";
import BecomeInfluenerModal from "../../../pages/becomeInfluencer/becomeInfluencer";
import { getInstagramUrl, disconnectInstagram, getInstagramData, updateAccessToken } from "../../../redux/actions/instagram.action";
import { getAccount } from "../../../redux/actions/account.action";
import Swal from "sweetalert2";
let userInfo = localStorage.getItem("userInfo");
if (userInfo !== "undefined") {
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
} else {
  localStorage.removeItem("userInfo");
  window.location.reload();
}
export default function BecomeInfluncer() {
  const [becomeInfluencerModal, setBecomeInfluencerModal] = useState(false);
  const [url, setUrl] = useState("");
  const queryParams = queryString.parse(window.location.search);
  const [loading, setLoading] = useState(true);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [insta, setInsta] = useState(0);
  const [complete, setComplete] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const getInstagramAccountId = (facebookPageId) => {
    setCheckLoading(true);
    dispatch(facebookAccesstoken(facebookPageId, facebookUserAccessToken)).then((res) => {
      setInsta(res?.payload?.data?.instagram_business_account?.id);
      setCheckLoading(false);
    });
  };

  const getFacebookPages = (facebookUserAccessToken) => {
    dispatch(getFbPages(facebookUserAccessToken)).then((res) => {
      console.log("data", res);
      setPages(res?.payload?.data?.data);
    });
  };

  const pagesMemo = useMemo(() => {
    if (facebookUserAccessToken) {
      getFacebookPages(facebookUserAccessToken);
    }
  }, [facebookUserAccessToken]);

  const idMemo = useMemo(() => {
    if (selectedPage) {
      getInstagramAccountId(selectedPage);
    }
  }, [selectedPage]);

  useEffect(() => {
    const fbToken = JSON.parse(localStorage.getItem("userInfo")).fb_token;
    const fbPage = JSON.parse(localStorage.getItem("userInfo")).page_token;
    if (fbToken) {
      setFacebookUserAccessToken(fbToken);
      if (fbPage) {
        setSelectedPage(fbPage);
        setComplete(true);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const { account } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
  }, []);

  const disconnect = () => {
    dispatch(disconnectInstagram(userInfo?._id)).then((res) => {
      if (res.type == "DISCONNECT_INSTAGRAM_SUCCESS") {
        dispatch(getAccount(userInfo?.token));
      } else {
        notification.error({
          message: "Something went wrong!",
          className: "toast-error",
        });
      }
    });
  };

  const disconnectfb = () => {
    Swal.fire({
      title: "Disconnect Facebook and Instagram",
      text: `Are you sure you want to disconnect both facebook and instagram accounts? This will remove all your content from our platform. This action is not reversible.`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      iconHtml: '<i class="fa fa-facebook" aria-hidden="true" />',
      imageWidth: 50,
      imageHeight: 50,
      imageAlt: "facebook image",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        logOutOfFB();
      }
    });
  };
  const fbLogin = () => {
    const secret = "73a28a599afc60d1d82b2c7e0c15ef9b";
    const client = "916894299469996";
    window.FB.login(
      (response) => {
        // console.log(response.authResponse?.accessToken, "        olddddddd");
        if (response.authResponse?.accessToken) {
          fetch(`https://graph.facebook.com/v12.0/oauth/access_token?  
                        grant_type=fb_exchange_token&          
                        client_id=${client}&
                        client_secret=${secret}&
                        fb_exchange_token=${response.authResponse?.accessToken}`)
            .then((res) => res.json())
            .then((json) => {
              setFacebookUserAccessToken(json?.access_token);
              dispatch(facebookUser(userInfo?.token, json.access_token)).then(() => {
                var userInfo = JSON.parse(localStorage.getItem("userInfo"));
                userInfo.fb_token = json.access_token;
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
              });
            });
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "pages_show_list, pages_manage_posts, instagram_content_publish, pages_read_engagement, instagram_basic, instagram_manage_comments, instagram_manage_messages, instagram_manage_insights,pages_manage_metadata",
      }
    );
  };

  const logOutOfFB = () => {
    dispatch(facebookReset(userInfo?.token, userInfo?._id)).then(() => {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.page_token = "";
      userInfo.fb_token = "";
      localStorage.setItem("userInfo", JSON.stringify(userInfo));

      window.location.reload();
    });
  };

  useEffect(() => {
    if ((queryParams?.code !== undefined && queryParams?.code.length) || userInfo?.instagram_token) {
      dispatch(getInstagramData(userInfo?.instagram_token ? userInfo.instagram_token : queryParams.code, userInfo?.email)).then((res) => {
        if (res.type == "GET_INSTAGRAM_DATA_SUCCESS") {
          dispatch(updateAccessToken(userInfo?._id, res.payload?.data?.username, res.payload?.data?.access_token)).then(() => {
            // window.location.reload();

            history({
              pathname: `/become-influencer`,
            });
            if (queryParams.code !== undefined) {
              dispatch(getAccount(userInfo?.token));
            }
          });
        } else {
          console.log(res.payload.data.message, "fail");

          notification.error({
            message: res?.payload?.data?.message,
            className: "toast-error",
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getInstagramUrl()).then((res) => {
      setUrl(res?.payload?.data);
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  const handleModal = () => {
    Swal.fire({
      title: "Disconnect Instagram",
      text: `Are you sure you want to disconnect @${account?.payload?.Data?.instagram_username} account from kbshop.com? `,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      iconHtml: '<i class="fa fa-instagram" aria-hidden="true" />',
      imageWidth: 50,
      imageHeight: 50,
      imageAlt: "instagram image",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        disconnect();
      }
    });
  };

  const onReset = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    dispatch(facebookReset(userInfo?.token, userInfo?._id)).then(() => {
      setFacebookUserAccessToken(null);
      localStorage.setItem("userInfo", JSON.stringify({ ...userInfo, fb_token: undefined }));
    });
  };

  const next = () => {
    setComplete(true);
    dispatch(fbsToken(selectedPage, insta, facebookUserAccessToken)).then(() => {
      var obj = localStorage.getItem("userInfo");
      var userInfo = JSON.parse(obj);
      userInfo.page_token = selectedPage;
      userInfo.instagram_id = insta;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    });
    dispatch(tokenAccess(selectedPage, facebookUserAccessToken)).then((res) => {
      dispatch(facebookSelected(selectedPage, res.data.access_token));
    });
  };

  return (
    <>
      <div className="account-left-content">
        <div className="account-detail container">
          <div className="bcm-inf-main">
            <div className="pr-op-block pro-inf-block">
              <div className="inf-cret-main">
                <div className="inf-cret-left">
                  <p>
                    To become an influencer you need to have <strong>1000</strong> followers. Please connect your Instagram and Facebook. <br />
                    Once connected you will be able to create a social store and participate in campaigns offered by brands/retailers.
                  </p>
                  <div className="become-inf-container">
                    <p className="app-status">
                      Application Status: <strong>{account?.payload?.Data?.became_influencer ? account?.payload?.Data?.became_influencer : "New"}</strong>
                    </p>

                    {userInfo.account_type !== "brand" && account?.payload?.Data?.became_influencer === "new" && (
                      <a
                        onClick={() => {
                          if (account?.payload?.Data?.became_influencer !== "pending") {
                            setBecomeInfluencerModal(true);
                          }
                        }}
                        href="#"
                      >
                        Become An Influencer
                      </a>
                    )}
                  </div>
                </div>
                <div className="inf-cret-right">
                  <img src={inficon} alt="" />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 mb-20">
                <div className="social_box_main">
                  <div className="social_box_header d-flex justify-content-between">
                    <h5>Instagram Connection</h5>
                    <span className={account?.payload?.Data?.instagram_username !== "" ? "connection-status-badge-green" : "connection-status-badge-red"}>{account?.payload?.Data?.instagram_username !== "" ? "Connected" : "Not Connected"}</span>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="dp_fields-setup mb-3">
                        <div className="category_count">Connection Status</div>
                      </div>

                      <div className="dp_fields-setup">
                        {account?.payload?.Data?.instagram_username ? (
                          <div className="connected-text text-left mb-2">
                            Connected: <span>@{account?.payload?.Data?.instagram_username}</span>
                          </div>
                        ) : (
                          <div className="connected-text text-danger text-left mb-2">Not Connected</div>
                        )}

                        {account?.payload?.Data?.instagram_username !== "" ? (
                          <>
                            <Button onClick={handleModal} className="w-100 connect-button-ig-fb">
                              <i className="fa fa-instagram" /> &nbsp;Disconnect Instagram
                            </Button>
                          </>
                        ) : (
                          <>
                            {account?.payload?.Data?.became_influencer === "approved" ? (
                              <Button
                                onClick={() => {
                                  window.location.replace(url);
                                }}
                                className="w-100 connect-button-ig-fb"
                              >
                                <i className="fa fa-instagram" /> &nbsp;Connect Instagram
                              </Button>
                            ) : (
                              <Button disabled className="w-100 connect-button-ig-fb">
                                <i className="fa fa-instagram" /> &nbsp;Connect Instagram
                              </Button>
                              
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-20">
                <div className="social_box_main">
                  <div className="social_box_header d-flex justify-content-between">
                    <h5>Facebook Connection</h5>
                    {facebookUserAccessToken && complete ? <span className="connection-status-badge-green">Connected</span> : <span className="connection-status-badge-red">Not Connected</span>}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="dp_fields-setup mb-3">
                        <div className="category_count">Connection Status</div>
                      </div>
                      <div class="dp_fields-setup">
                        {loading ? (
                          <p>...Loading</p>
                        ) : facebookUserAccessToken ? (
                          !complete ? (
                            <div className="dp_fields-setup">
                              <h5>Select Facebook Page</h5>
                              <p>Please select a page that is connected to instagram.</p>
                              <div className="select-pages">
                                <label>Facebook Pages</label>
                                {console.log("pages", pages)}
                                <Select
                                  className="mb-3"
                                  showSearch
                                  style={{ width: "100%" }}
                                  size="large"
                                  placeholder="Select Page"
                                  optionFilterProp="children"
                                  onChange={(event) => {
                                    setSelectedPage(event);
                                  }}
                                  onSearch={onSearch}
                                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                  options={pages?.map((item) => {
                                    return {
                                      value: item.id,
                                      label: item.name,
                                    };
                                  })}
                                />

                                {checkLoading ? <Spin /> : insta || insta == 0 ? null : <p style={{ color: "red" }}>Oops, there is no business instagram account found.</p>}

                                <div className="page-submit-btn mb-3">
                                  <Button onClick={onReset} type="primary">
                                    Reset
                                  </Button>
                                  <Button
                                    disabled={insta ? false : true}
                                    onClick={() => {
                                      next();
                                    }}
                                    type="primary"
                                  >
                                    Next
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="dp_fields-setup">
                              <div className="connected-text text-left mb-2">Connected {pages?.filter((it) => it.id === selectedPage)[0]?.name && ": " + pages.filter((it) => it.id === selectedPage)[0]?.name}</div>
                              <Button variant="primary" className="w-100 connect-button-ig-fb" onClick={disconnectfb}>
                                Disconnect Facebook
                              </Button>
                            </div>
                          )
                        ) : (
                          <>
                            <div className="dp_fields-setup">
                              {!facebookUserAccessToken && !complete && <div className="connected-text text-left mb-2 text-danger">Not Connected</div>}
                              <Button
                                disabled={account?.payload?.Data?.instagram_username ? false : true}
                                className="w-100 connect-button-ig-fb"
                                onClick={() => {
                                  fbLogin();
                                }}
                              >
                                Connect Facebook
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p>Note: Connecting Instagram and Facebook will allow you to access and post to your IG/FB account.</p>
          </div>
        </div>
      </div>

      <BecomeInfluenerModal
        setBecomeInfluencerModal={(value) => {
          setBecomeInfluencerModal(value);
        }}
        becomeInfluencerModal={becomeInfluencerModal}
      />
    </>
  );
}
