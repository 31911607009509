import Layout from "./component/layout";
import Payment from "./component/payment";

export default () => {
	return (
		<>
			<Layout title="Payment Method">
				<Payment />
			</Layout>
		</>
	);
};
