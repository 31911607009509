import Layout from "./component/layoutStreaming";
import Reviews from "./component/reviews";
export default () => {
	return (
		<>
			<Layout>
				<Reviews />
			</Layout>
		</>
	);
};
