import { BASEURL } from "../../config";
import axios from "axios";
import { GET_CATEGORY_WISE_INFLUENCER_REQUEST, GET_CATEGORY_WISE_INFLUENCER_SUCCESS, GET_CATEGORY_WISE_INFLUENCER_ERROR, GET_ALL_INFLUENCER_REQUEST, GET_ALL_INFLUENCER_SUCCESS, GET_ALL_INFLUENCER_ERROR, POST_ALL_INFLUENCER_REQUEST, POST_ALL_INFLUENCER_SUCCESS, POST_ALL_INFLUENCER_ERROR, POST_SINGLE_INFLUENCER_REQUEST, POST_SINGLE_INFLUENCER_SUCCESS, POST_SINGLE_INFLUENCER_ERROR } from "../types/types";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getAllInfluencer =
	(page = 1, limit = 12, name) =>
	(dispatch) => {
		dispatch({ type: GET_ALL_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/category/getAllInfluencerForWebCustomer`,
			headers: {
				Accept: "application/json",
			},
			data: {
				name: name === "all" ? "" : name,
				account_type: "influencer",
			},
			params: {
				limit,
				page,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_ALL_INFLUENCER_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const getCategoryWiseInfluencer =
	(categoryId, key, name, page = 1, limit = 100) =>
	(dispatch) => {
		let categoryUrl = `mobile/category/categoryWiseInfluencerAndBrand`;
		if (key === "subcategory") {
			categoryUrl = `mobile/category/getInfluencerSubCategoryWise?limit=${limit}&page=${page}`;
		}
		dispatch({ type: GET_CATEGORY_WISE_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: BASEURL + categoryUrl,
			headers: {
				Accept: "application/json",
			},
			data: {
				account_type: "influencer",
				categoryId: categoryId,
				name: name ? name : "",
				key: key,
			},
			params: {
				limit,
				page,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_CATEGORY_WISE_INFLUENCER_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_CATEGORY_WISE_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const publishInfluencer = () => (dispatch) => {
	dispatch({ type: POST_ALL_INFLUENCER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/reviews/published?page=1&limit=10`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		// data:{
		// 	brand_id : brand_id,
		// }
	})
		.then((response) => {
			return dispatch({
				type: POST_ALL_INFLUENCER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_ALL_INFLUENCER_ERROR,
				payload: error.response,
			});
		});
};

export const publishSingle = ( review_id , brand_id) => (dispatch) => {
  dispatch({ type: POST_SINGLE_INFLUENCER_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}public/reviews/getonereview`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      review_id: review_id,
	  brand_id:brand_id,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_SINGLE_INFLUENCER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SINGLE_INFLUENCER_ERROR,
        payload: error.response,
      });
    });
};
