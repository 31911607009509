import Layout from "./component/layout";
import { useSelector } from "react-redux";
import BecomeInfluencer from "./component/becomeInfluencer";

export default () => {
	const { account } = useSelector((state) => state);
	return (
		<>
			<Layout title={`${account?.payload?.Data?.became_influencer === "approved" ? "Influencer Section" : "Become an Influencer"}`}>
				<BecomeInfluencer />
			</Layout>
		</>
	);
};
