import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Radio, Space } from "antd";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import outSideClick from "../../../helper/outSideClick";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";
import { brandFollower } from "../../../redux/actions/brand.action";
import closeicon from "../../../images/brand-logos/close-icon.svg";
//import { getSubCategories } from "../../../redux/actions/category.action";
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import numeral from "numeral";
// const { Option } = Select;
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ socialStore, children, setFilter }) => {
	const [subCategory, setSubCategory] = useState([]);
	const dispatch = useDispatch();
	const { bioShopMenu, account, followerBrand } = useSelector((state) => {
		return state;
	});
	const params = useParams();
	const componentWillUnmount = useRef(false);
	const [displayCategory, setDisplayCategory] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const divRef = useRef();
	const handler = useCallback(() => setDisplayCategory(false), []);
	const [value, setValue] = useState(1);
	const [categoryName, setCategoryName] = useState("");
	const [activeCategory, setActiveCategory] = useState("");
	const [activeSubCategory, setActiveSubCateogry] = useState("");
	const [filterPost, setFilterPost] = useState({
		categoryId: "",
		subCategoryId: "",
		sortBy: "",
		eventType: "",
		applyFilter: "all",
	});
	outSideClick(divRef, handler);
	useEffect(() => {
		if (bioShopMenu?.length == 0) {
			dispatch(getBioShopMenu(params.username, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.data?._id.length > 0) dispatch(brandFollower(res?.payload?.message?.data?._id));
			});
		}
	}, [params.username]);

	function ComponentBioShop() {
		return (
			<div className="bio_shop_body">
				<div className="bio_shop_header">
					<div className="bio_shop_header_left">
						<div className="brand-descrp">
							<div className="brand_logo">{bioShopMenu?.payload?.message?.data?.profile_image_url && <img src={bioShopMenu?.payload?.message?.data?.profile_image_url} />}</div>
							<div className="logo-right">
								<div className="current-brand-name">{bioShopMenu?.payload?.message?.data?.name}</div>
								<div className="brand-followers">
									{followerBrand?.payload?.message?.followers_count ? (
										<span>
											<strong>{numeral(followerBrand?.payload?.message?.followers_count).format("0a")}</strong> Followers
										</span>
									) : (
										<></>
									)}
									{followerBrand?.payload?.message?.follows_count ? (
										<span>
											<strong>{numeral(followerBrand?.payload?.message?.follows_count).format("0a")}</strong> Following
										</span>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>

						{bioShopMenu?.payload?.message?.data?.promo !== "KB0" && bioShopMenu?.payload?.message?.data?.promo && <div className="brand_name">GET {bioShopMenu?.payload?.message?.data?.discount} OFF USING KBSHOP APP</div>}
					</div>

					<div className="bio_shop_header_topmenu">
						<a href="#" onClick={() => setShowFilter(true)}>
							Filters
						</a>
						{bioShopMenu?.payload?.message?.data.type !== "influencer" && !socialStore && <NavLink to={`/brand-events/${params.username}`}>Shows</NavLink>}
						<NavLink to={`/brand-reviews/${params.username}`}>Influencer Reviews</NavLink>
						<NavLink to={`/${params.username}`}>Social Store</NavLink>

						<a href={bioShopMenu?.payload?.message?.data?.website_address} style={{ visibility: !bioShopMenu?.payload?.message?.data?.website_address ? "hidden" : "visible" }} target="_blank">
							Website
						</a>
					</div>
				</div>

				{children}
			</div>
		);
	}
	return (
		<>
			<div className={`${!socialStore ? "bio_shop_main_ift" : ""}`}>
				<div className={`${!socialStore ? "midd-width-ifti" : ""}`}>
					<div className={`${!socialStore ? "bio_container_main" : ""}`}>{ComponentBioShop()}</div>
				</div>
			</div>

			<Modal
				backdropClassName="custom-backdrop"
				dialogClassName="filter-modal-box"
				show={showFilter}
				scrollable
				onHide={() => {
					setShowFilter(false);
				}}
				centered
			>
				<span
					className="filter-close"
					onClick={() => {
						setShowFilter(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>

				<Modal.Body>
					<div className="filter-container">
						<h3>Sort by</h3>
						<div className="radio-box">
							<Radio.Group
								onChange={(e) => {
									setFilterPost({
										...filterPost,
										sortBy: e.target.value,
										applyFilter: false,
									});
									setFilter({
										...filterPost,
										sortBy: e.target.value,
										applyFilter: false,
									});
								}}
								value={filterPost.sortBy}
								size="large"
							>
								<Space direction="vertical">
									<Radio value={""}>Date</Radio>
									<Radio value={"topdiscount"}>Top Discount</Radio>
									<Radio value={"referralfee"}>Referral Fee</Radio>
									{userInfo && account?.payload?.Data?.account_type !== "customer" ? <Radio value={"influencerfee"}>Influencer Fee</Radio> : <></>}
								</Space>
							</Radio.Group>
						</div>
					</div>

					<div className="filter-container">
						<h3>Event Type</h3>
						<div className="radio-box">
							<Radio.Group
								onChange={(e) => {
									setFilterPost({
										...filterPost,
										eventType: e.target.value,
										applyFilter: false,
									});
									setFilter({
										...filterPost,
										eventType: e.target.value,
										applyFilter: false,
									});
								}}
								value={filterPost.eventType}
								size="large"
							>
								<Space direction="vertical">
									<Radio value="">All</Radio>
									<Radio value="liveEvents">Live Events</Radio>
									<Radio value="upcomingEvents">Upcoming Events</Radio>
									<Radio value="previousEvents">Previous Events</Radio>
								</Space>
							</Radio.Group>
						</div>
					</div>

					<div className="filter-container">
						<h3>Categories</h3>
						<div className="filter-box">
							<Button
								onClick={() => {
									setFilterPost({
										...filterPost,
										categoryId: "",
										parentId: [],
										applyFilter: false,
									});
									setFilter({
										...filterPost,
										categoryId: "",
										parentId: [],
										applyFilter: false,
									});
									setActiveCategory("");
									setCategoryName("");
									setActiveSubCateogry("");
									setSubCategory([]);
								}}
								className={activeCategory === "" && "active"}
								variant="primary"
							>
								All
							</Button>

							{bioShopMenu?.payload?.message?.data?.categories.map((item, i) => {
								return (
									<Button
										className={activeCategory === item.parent_id && "active"}
										onClick={() => {
											if (activeCategory === item.parent_id) {
												setActiveCategory("");
												setFilterPost({
													...filterPost,
													categoryId: "",
													parentId: [],
													subCategoryId: "",
													applyFilter: false,
												});
												setFilter({
													...filterPost,
													categoryId: "",
													parentId: [],
													subCategoryId: "",
													applyFilter: false,
												});
											} else {
												setCategoryName(item?.category_name);
												setActiveCategory(item.parent_id);
												setFilterPost({
													...filterPost,
													categoryId: item.category_id,
													parentId: [item.parent_id],
													subCategoryId: "",
													applyFilter: false,
												});
												setFilter({
													...filterPost,
													categoryId: item.category_id,
													parentId: [item.parent_id],
													subCategoryId: "",
													applyFilter: false,
												});
											}
										}}
										key={i}
									>
										{item?.category_name}
									</Button>
								);
							})}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{" "}
					<Button
						onClick={() => {
							setFilterPost({
								...filterPost,
								categoryId: "",
								sortBy: "",
								eventType: "",
								parentId: [],
								applyFilter: Math.random(),
							});
							setFilter({
								...filterPost,
								categoryId: "",
								sortBy: "",
								eventType: "",
								parentId: [],
								applyFilter: Math.random(),
							});
							setActiveCategory("");
							setCategoryName("");
							setActiveSubCateogry("");
							setSubCategory([]);
							setShowFilter(false);
						}}
						variant="secondary"
					>
						Reset all
					</Button>
					<Button
						onClick={() => {
							setFilterPost({ ...filterPost, applyFilter: Math.random() });
							setFilter({ ...filterPost, applyFilter: Math.random() });
							setShowFilter(false);
						}}
						variant="primary"
					>
						Apply filter
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
