import { FACEBOOK_RESET_REQUEST, FACEBOOK_RESET_SUCCESS, FACEBOOK_RESET_ERROR } from "../../types/types";

export default function resetFacebook(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case FACEBOOK_RESET_REQUEST:
      return {
        loading: true,
      };
    case FACEBOOK_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case FACEBOOK_RESET_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}