import { POST_NOTIFY_REQUEST, POST_NOTIFY_SUCCESS, POST_NOTIFY_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const notifyMe = (token, event_id) => (dispatch) => {
  dispatch({ type: POST_NOTIFY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}public/notify/me`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      event_id
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_NOTIFY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_NOTIFY_ERROR,
        payload: error.response,
      });
    });
};