import { GET_MULTIPLE_SUB_CATEGORIES_REQUEST, GET_MULTIPLE_SUB_CATEGORIES_SUCCESS, GET_MULTIPLE_SUB_CATEGORIES_ERROR, GET_POPULAR_CATEGORIES_REQUEST, GET_POPULAR_CATEGORIES_SUCCESS, GET_POPULAR_CATEGORIES_ERROR, GET_PARENT_CATEGORIES_REQUEST, GET_PARENT_CATEGORIES_SUCCESS, GET_PARENT_CATEGORIES_ERROR, GET_CATEGORIES_SETUP_REQUEST, GET_CATEGORIES_SETUP_SUCCESS, GET_CATEGORIES_SETUP_ERROR, GET_SUB_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_SUCCESS, GET_SUB_CATEGORIES_ERROR, GET_POPULAR_INFLUENCER_REQUEST, GET_POPULAR_INFLUENCER_SUCCESS, GET_POPULAR_INFLUENCER_ERROR, GET_ALL_CATEGORIES_REQUEST, GET_ALL_CATEGORIES_SUCCESS, GET_ALL_CATEGORIES_ERROR, POST_SAVE_CATEGORIES_REQUEST, POST_SAVE_CATEGORIES_SUCCESS, POST_SAVE_CATEGORIES_ERROR, VERIFY_CATEGORIES_REQUEST, VERIFY_CATEGORIES_SUCCESS, VERIFY_CATEGORIES_ERROR, SUB_CATEGORIES_REQUEST, SUB_CATEGORIES_SUCCESS, SUB_CATEGORIES_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getParentCategories = () => (dispatch) => {
	dispatch({ type: GET_PARENT_CATEGORIES_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/parentCategories`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_PARENT_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_PARENT_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getCategoriesSetup = (id) => (dispatch) => {
	dispatch({ type: GET_CATEGORIES_SETUP_REQUEST });

	return axios({
		method: "GET",
		url: `${BASEURL}users/receive/categories?id=${id}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_CATEGORIES_SETUP_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_CATEGORIES_SETUP_ERROR,
				payload: error.response,
			});
		});
};

export const getAllCategories = () => (dispatch) => {
	dispatch({ type: GET_ALL_CATEGORIES_REQUEST });

	return axios({
		method: "GET",
		url: `${BASEURL}usercategory/receive`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getSubCategories = (id) => (dispatch) => {
	dispatch({ type: GET_SUB_CATEGORIES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/subCategories`,
		headers: {
			Accept: "application/json",
		},
		data: {
			parent_id: id,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_SUB_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_SUB_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getMultipleSubCategories =
	(ids = []) =>
	(dispatch) => {
		dispatch({ type: GET_MULTIPLE_SUB_CATEGORIES_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/events/getsubcategories`,
			headers: {
				Accept: "application/json",
			},
			data: {
				ids: ids,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_MULTIPLE_SUB_CATEGORIES_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_MULTIPLE_SUB_CATEGORIES_ERROR,
					payload: error.response,
				});
			});
	};

export const getPopularCategories = (page) => async (dispatch) => {
	dispatch({ type: GET_POPULAR_CATEGORIES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/getPopular`,
		headers: {
			Accept: "application/json",
		},
		data: {
			key: "category",
		},

		params: {
			limit: 12,
			page,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_POPULAR_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_POPULAR_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getPopularInfluencer = (page) => async (dispatch) => {
	dispatch({ type: GET_POPULAR_INFLUENCER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/getPopular`,
		headers: {
			Accept: "application/json",
		},
		data: {
			key: "influencer",
		},
		params: {
			limit: 12,
			page,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_POPULAR_INFLUENCER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_POPULAR_INFLUENCER_ERROR,
				payload: error.response,
			});
		});
};

export const postSaveCategories = (category, sort) => (dispatch) => {
	dispatch({ type: POST_SAVE_CATEGORIES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}usercategory/reserve`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			categories: category,
			sort: sort,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_SAVE_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_SAVE_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const verifyCategories = (category_id) => (dispatch) => {
	dispatch({ type: VERIFY_CATEGORIES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}usercategory/verify`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			category_id: category_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: VERIFY_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: VERIFY_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const subCategories = (id) => (dispatch) => {
	dispatch({ type: SUB_CATEGORIES_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}users/receive/subcategories?category_id=${id}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: SUB_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SUB_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};
