import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import error from "../../images/error.png";
import logo from "../../images/konnectbiologo.svg";
import { NavLink } from "react-router-dom";
export default () => {
  const [mobileSearch, setMobileSearch] = useState(false);
  return (
    <>
      <div className="password_page">
        <div className="password-header">
          <div className="container">
            <div className="p-h-left">
              <NavLink to="/">KBSHOP</NavLink>
            </div>
            <div className="enter-using-ps">
              <span>
                <i class="fa fa-lock" aria-hidden="true"></i> Enter using
                password
              </span>
              <div className="enter-pass-box">
                <div className="arrow-up">
                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                </div>
                <h3>Enter using password</h3>
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    name="username"
                    className="enter-email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Password"
                      type="password"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large">
                      Enter
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="password-box">
          <div className="pswd-iner">
            <h3>Opening Soon</h3>
            <p>We are preparing something exciting & amazing for you.</p>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                className="enter-email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  placeholder="Your email address"
                  type="email"
                  size="large"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" size="large">
                  Notify me
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
