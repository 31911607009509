//local

// export const BASEURL = "http://172.16.1.85:9090/v1/";
// export const BASEURL1 = "http://172.16.1.85:9090/";

//development

// export const BASEURL = "https://apiv2.kblive.com/v1/";
// export const BASEURL1 = "https://apiv2.kblive.com/";


//production
export const BASEURL = "https://apiv2.konnect.bio/v1/";
export const BASEURL1 = "https://apiv2.konnect.bio/";





export const TEMPURL = "https://kbsly.com/v1/";
export const SOCKETURL = "wss://v2-server-web-sockets1.herokuapp.com";
