import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import { getPopularCategories } from "../../redux/actions/category.action";
import "../../css/category.scss";
import "../../css/influencer.scss";

import poster1 from "../../images/reviews/ank-1.jpg";
import posteravt1 from "../../images/reviews/ank-1-avt.jpg";

import poster2 from "../../images/reviews/ank-2.jpg";
import posteravt2 from "../../images/reviews/ank-2-avt.jpg";

import poster3 from "../../images/reviews/ank-3.jpg";
import posteravt3 from "../../images/reviews/ank-3-avt.jpg";

import poster4 from "../../images/reviews/poster-4.jpg";
import posteravt4 from "../../images/reviews/vid-4-avatar.jpg";

import poster5 from "../../images/reviews/poster-5.jpg";
import posteravt5 from "../../images/reviews/vid-5-avatar.jpg";

import poster6 from "../../images/reviews/poster-6.jpg";
import posteravt6 from "../../images/reviews/vid-6-avatar.jpg";


const responsive5 = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1023, min: 768 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 767, min: 0 },
    items: 2.5,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2.5,
    slidesToSlide: 2, // optional, default to 1.
  },
};

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [PopularCategory, setPopularCategory] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getPopularCategories()).then((res) => {
      setPopularCategory(res?.payload.data);
      setLoading(false);
    });
  }, []);

  return (
    <>
    
      {/* <div className="influencer-layout-main">
	  <div className="event-filter">
		<Button>Filters</Button>
	  </div>
        <div className="influencer-layout-inner">
          <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster1}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Lindsey Martin
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt1}
                          />
                        </span>
                        <span class="auth-title">lindsey_martin</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster2}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Andreas LookBook
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt2}
                          />
                        </span>
                        <span class="auth-title">andreaslookbook</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster3}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Jessgli Stening
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt3}
                          />
                        </span>
                        <span class="auth-title">jessglistening</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster4}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Janine Graff
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt4}
                          />
                        </span>
                        <span class="auth-title">janinegraff</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster5}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Shawn Killinger
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt5}
                          />
                        </span>
                        <span class="auth-title">shawnkillinger</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster6}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Cynde Watson
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt6}
                          />
                        </span>
                        <span class="auth-title">cyndewatson</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster2}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
                      Andreas LookBook
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt2}
                          />
                        </span>
                        <span class="auth-title">andreaslookbook</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster1}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
					Lindsey Martin
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt1}
                          />
                        </span>
                        <span class="auth-title">lindsey_martin</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster6}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
					Cynde Watson
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt6}
                          />
                        </span>
                        <span class="auth-title">cyndewatson</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster3}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
					Jessgli Stening
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt3}
                          />
                        </span>
                        <span class="auth-title">jessglistening</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster4}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
					Janine Graff
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt4}
                          />
                        </span>
                        <span class="auth-title">janinegraff</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
		  <div className="event-col-box">
            <a
              class="live-vid-link"
              href="#"
            >
              <div class="vid-item ">
                <img
                  alt=""
                  src={poster5}
                />
                <div class="vid-item-detail">
                  <div class="vid-time">
				  <span class="vid-views"><i class="fa fa-play" aria-hidden="true"></i> 59.5k</span>
                  </div>
                  <div class="vid-title">
                    <span class="v-title">
					Shawn Killinger
                    </span>
                    <div class="author-name justify-content-between">
                      <div class="auth-left">
                        <span class="auth-image">
                          <img
                            alt=""
                            src={posteravt5}
                          />
                        </span>
                        <span class="auth-title">shawnkillinger</span>
                      </div>
                      <div class="auth-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div> */}
      <div className="popular_cat_main">
        <div className="midd-width-ifti">
          <div className="popular_cat">
            <div className="pik-header">
              <h3>Popular Categories</h3>
            </div>
            <div className="popular_cat_carousal">
              {loading ? (
                <>
                  <Carousel
                    responsive={responsive5}
                    autoPlay={false}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                  >
                    {new Array(7).fill().map(() => {
                      return (
                        <div className="cash-item">
                          <NavLink to={`#`}>
                            <div className="cat_height"></div>
                            <h4>
                              <Spin size="small" />
                            </h4>
                          </NavLink>
                        </div>
                      );
                    })}
                  </Carousel>
                </>
              ) : (
                <>
                  <Carousel
                    responsive={responsive5}
                    autoPlay={false}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                  >
                    {PopularCategory.map((item) => {
                      return (
                        <>
                          <div className="cash-item">
                            <NavLink
                              to={`/sub-influencer-category/${item._id}`}
                            >
                              <div className="cat_height">
                                <img src={item.image_url} />
                              </div>
                              <h4>{item.category_name}</h4>
                            </NavLink>
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
