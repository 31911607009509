import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import initFacebookSDK from './helper/fbSDK/initFacebookSDK';
//import 'react-chat-elements/dist/main.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

const renderApp = () => {
  ReactDOM.render(root.render(<App />));
};

initFacebookSDK().then(() => {
  renderApp();
});
