import Layout from "./component/layout";
import ManageCategory from "./component/manageCategory";

export default () => {
	return (
		<>
			<Layout title="Manage Category">
				<ManageCategory />
			</Layout>
		</>
	);
};
