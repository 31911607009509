import { POST_CREATE_MEDIA_REQUEST, POST_CREATE_MEDIA_SUCCESS, POST_CREATE_MEDIA_ERROR } from "../../types/types";
export default function createdMedia(state = [], action) {
	const { type, payload } = action;


	switch (type) {
		case POST_CREATE_MEDIA_REQUEST:
			return {
				loading: true,
			};
		case POST_CREATE_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_CREATE_MEDIA_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
