import { ADD_CREDIT_CARD_REQUEST, ADD_CREDIT_CARD_SUCCESS, ADD_CREDIT_CARD_ERROR, GET_ALL_CREDIT_CARD_REQUEST, GET_ALL_CREDIT_CARD_SUCCESS, GET_ALL_CREDIT_CARD_ERROR, SET_DEFAULT_CREDIT_CARD_REQUEST, SET_DEFAULT_CREDIT_CARD_SUCCESS, SET_DEFAULT_CREDIT_CARD_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const createCreditCard = (card_number, expiry, cvc,zipCode, token) => (dispatch) => {
	dispatch({ type: ADD_CREDIT_CARD_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}users/cards/createnewcard`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			card_number,
			expiry,
			cvc,
			zipCode
		},
	})
		.then((response) => {
			return dispatch({
				type: ADD_CREDIT_CARD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_CREDIT_CARD_ERROR,
				payload: error.response,
			});
		});
};

export const getAllCreditCard = (token) => (dispatch) => {
	dispatch({ type: GET_ALL_CREDIT_CARD_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}users/cards/getallcards`,
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_CREDIT_CARD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_CREDIT_CARD_ERROR,
				payload: error.response,
			});
		});
};

export const updateDefaultCard = (cardNumber, token) => (dispatch) => {
	dispatch({ type: SET_DEFAULT_CREDIT_CARD_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}users/cards/setdefaultcard/${cardNumber}`,
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return dispatch({
				type: SET_DEFAULT_CREDIT_CARD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SET_DEFAULT_CREDIT_CARD_ERROR,
				payload: error.response,
			});
		});
};