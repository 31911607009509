import React, { useEffect, useState, useRef } from "react";
import { Col, Modal } from "react-bootstrap";
import { Spin } from "antd";
import { notification, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

let gb = [];
function ImageShop({ media, selectPost, imgData, children, category, subcategory, url, promoCode, source, updateProduct, postItem }) {
	const videoRef = useRef();
	const previousUrl = useRef(postItem.media_url);

	const [circles, setCircles] = useState([]);
	const [addImageModal, setAddImageModal] = useState(false);
	const [detailImageModal, setDetailImageModal] = useState(false);
	const [imageFiles, setImageFiles] = useState([]);
	const [multiImage, setMultiImage] = useState([]);
	//
	const [ProductName, setProductName] = useState("");
	const [sourceId, setSourceId] = useState("");
	const [ProductSku, setProductSku] = useState("");
	const [ProductUrl, setProductUrl] = useState("");
	const [referral_percent, setRefFee] = useState("");
	const [influencerFee, setInfluencerFee] = useState("");
	const [productDesc, setProductDesc] = useState("");
	const [productAmount, setProductAmount] = useState("");
	const [productCategory, setProductCategory] = useState([]);
	const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
	const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
	const [submitData, setSubmitData] = useState([]);
	const [updateSubmitData, setUpdateSubmitData] = useState([]);
	const [coordinates, setCoordinates] = useState([]);
	const [skuData, setSkuData] = useState("");
	const [skuDataOther, setOtherSku] = useState("");
	const [imgId, setImgId] = useState();
	const [flag, setFlag] = useState(false);
	const [flagsb, setFlagSb] = useState(false);
	const [imgtag, setImgtag] = useState(true);
	// const [productSource, setProductSource] = useState("ecommerce");
	const [imageError, setImageError] = useState(false);
	const [imageFormatError, setImageFormatError] = useState(false);

	// useEffect(() => {
	// 	//videoRef.current.click();
	// }, [addVideoLink]);

	// useEffect(() => {
	// 	if (addVideoLink === true) {
	// 		if (source === "other") {
	// 			setAddImageModal(true);
	// 			setFlagSb(true);
	// 			setFlag(true);
	// 		}
	// 	}
	// }, [addVideoLink]);

	const parentRef = useRef();

	let arr = [];
	useEffect(() => {
		setImageFiles([]);
		setSubmitData([]);
		setMultiImage([]);
		setImgtag(true);
		setRefFee("");
		setInfluencerFee("");
		setCircles([]);
		setSkuData("");
		setCoordinates("");
		setProductSku("");
		setUpdateSubmitData([]);
		imgData([]);
	}, [selectPost]);

	useEffect(() => {
		if (previousUrl.current === postItem.media_url) {
			return;
		}
		if (videoRef.current) {
			videoRef.current.load();
		}
		previousUrl.current = postItem.media_url;
	}, [postItem.media_url]);

	useEffect(() => {
		if (postItem.media_type === "CAROUSEL_ALBUM" || postItem.media_type === "IMAGE" || postItem.media_type === "VIDEO") {
			setImageFiles([]);
			setSubmitData([]);
			setMultiImage([]);
			setImgtag(true);
			setCircles([]);
			setSkuData("");
			setRefFee("");
			setInfluencerFee("");
			setCoordinates("");
			setProductSku("");
			setUpdateSubmitData([]);
			imgData([]);
		}
	}, [postItem.media_type]);

	useEffect(() => {
		if (category?.length >= 0) {
			setProductCategory(category);
		}
	}, [category]);

	useEffect(() => {
		if (children?.length !== 0) {
			childrenAttr();
			imgData(children);
			setImgtag(true);
		} else {
			setSubmitData([]);
			setCircles([]);
			imgData([]);
		}
	}, [children]);

	useEffect(() => {
		if (category?.length >= 0) {
			setProductCategory(category);
		}
	}, [category]);

	useEffect(() => {
		if (subcategory?.length > 0 && url == "") {
			setImgtag(true);
		} else {
		}
	}, [subcategory]);

	const childrenAttr = () => {
		let circleA = [];
		children?.map((item) => {
			let obj = item.coordinates[0];
			circleA.push(obj);
		});
		setCircles(circleA);
		setSubmitData(children);
	};

	const onChangeInputImage = (e) => {
		e.preventDefault();
		setImageFormatError(false);
		if (e.target.files.length && (e.target.files[0]?.type === "image/png" || e.target.files[0]?.type === "image/jpg" || e.target.files[0]?.type === "image/jpeg" || e.target.files[0]?.type === "image/svg+xml" || e.target.files[0]?.type === "image/svg")) {
			const files = multiImage;
			let reader = new FileReader();
			let file = e.target.files[0];

			reader.onloadend = () => {
				files.push({
					file: file,
					media_url: reader.result,
				});

				setImageFiles(files.reverse());
			};

			reader.readAsDataURL(file);
		} else {
			setImageError(false);
			notification.error({
				message: "Invalid Image Format",
				className: "toast-error",
			});
			setImageFormatError(true);
		}
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const onSubmitting = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		setImgtag(true);
		let allData = [];
		let updateData = [];
		let updateMatched = {};
		let newAdd = {};
		let data = {};

		if (updateProduct == true) {
			if (source === "other" && imageFiles.length === 0) {
				setImageError(true);
				setImageFormatError(false);
			} else {
				setAddImageModal(false);

				if (source === "other") {
					var get_type = imageFiles[0]?.file.type;
					var split = get_type.split("/");
					var file_type = split[1];
					var formImage = await convertBase64(imageFiles[0]?.file);
				} else {
					var media_url = imageFiles;
				}
				submitData?.map((item) => {
					if (item.imgid === imgId) {
						let id = item.id;
						let coordinates = item.coordinates;
						let imgid = item.imgid;
						updateMatched = {
							ProductSku,
							skuDataOther,
							ProductName,
							sourceId,
							productAmount,
							productDesc,
							ProductUrl,
							productCategory,
							productPromoCodePromo,
							productPromoCodeDscs,
							file_type,
							media_url,
							coordinates,
							imgid,
							id,
							referral_percent,
							influencerFee,
						};
						updateData.push(updateMatched);
					} else {
						updateData.push(item);
						setUpdateSubmitData(item);
					}
					setUpdateSubmitData(updateData);
				});

				if (flag == true) {
					var imgid = Math.floor(Math.random() * 100000);
					var media_url = imageFiles;

					if (source == "other") {
						newAdd = {
							media_url: formImage,
							ProductSku,
							skuDataOther,
							ProductName,
							sourceId,
							productAmount,
							productDesc,
							ProductUrl,
							productCategory,
							productPromoCodePromo,
							productPromoCodeDscs,
							coordinates,
							file_type,
							imgid,
							referral_percent,
							influencerFee,
						};
						updateData.push(newAdd);
						setUpdateSubmitData(updateData);
					} else {
						newAdd = {
							media_url,
							ProductSku,
							skuDataOther,
							ProductName,
							sourceId,
							productAmount,
							productDesc,
							ProductUrl,
							productCategory,
							productPromoCodePromo,
							productPromoCodeDscs,
							coordinates,
							file_type,
							imgid,
							referral_percent,
							influencerFee,
						};
						updateData.push(newAdd);
						setUpdateSubmitData(updateData);
					}
				}

				setSubmitData(updateData);
				imgData(updateData);
				setImageFiles([]);
				setProductSku("");
				setRefFee("");
				setInfluencerFee("");

				setProductName("");
				setSourceId("");
				setOtherSku("");
				if (promoCode == "KB0") {
					setproductPromoCodePromo("KB0");
					setProductPromoCodeDscs("0%");
				} else {
				}
				setProductUrl("");
				setProductAmount();
				setProductDesc("");
				setRefFee("");
				setInfluencerFee("");
				setInfluencerFee("");
				setSkuData("");
				setImageError(false);
				setImageFormatError(false);
				setFlag(false);
			}
		} else {
			setFlag(false);

			if (source === "other" && imageFiles.length === 0) {
				setImageError(true);
			} else {
				setAddImageModal(false);

				if (source === "other") {
					var get_type = imageFiles[0]?.file.type;
					var split = get_type.split("/");
					var file_type = split[1];

					var formImage = await convertBase64(imageFiles[0]?.file);
				} else {
					var media_url = imageFiles;
				}
				submitData?.map((item, indx) => {
					if (item.imgid === imgId) {
						submitData.splice(indx, 1);

						let coordinates = item.coordinates;
						let imgid = item.imgid;
						updateMatched = {
							ProductSku,
							skuDataOther,
							ProductName,
							sourceId,
							productAmount,
							productDesc,
							ProductUrl,
							productCategory,
							productPromoCodePromo,
							productPromoCodeDscs,
							file_type,
							media_url,
							coordinates,
							imgid,
							referral_percent,
							influencerFee,
						};

						submitData.push(updateMatched);
					}
				});

				/////New Tag add for ecommerce and other both
				///check cond for other then assing media_rl variable before not use formImage direct linke sku

				if (flagsb == true) {
					var imgid = Math.floor(Math.random() * 100000);

					if (source == "other") {
						data = {
							file: formImage,
							ProductSku,
							skuDataOther,
							ProductName,
							sourceId,
							productAmount,
							productDesc,
							ProductUrl,
							productCategory,
							productPromoCodePromo,
							productPromoCodeDscs,
							coordinates,
							file_type,
							media_url: formImage,
							imgid,
							referral_percent,
							influencerFee,
						};

						submitData.push(data);
					} else {
						data = {
							file: formImage,
							ProductSku,
							skuDataOther,
							ProductName,
							sourceId,
							productAmount,
							productDesc,
							ProductUrl,
							productCategory,
							productPromoCodePromo,
							productPromoCodeDscs,
							coordinates,
							file_type,
							media_url,
							imgid,
							referral_percent,
							influencerFee,
						};

						submitData.push(data);
					}
				}

				imgData(submitData);
				// setSubmitData(updateData);
				setImageFiles([]);
				setProductSku("");
				setRefFee("");
				setInfluencerFee("");
				setProductName("");
				setSourceId("");
				setOtherSku("");
				// setProductCategory([]);
				if (promoCode == "KB0") {
					setproductPromoCodePromo("KB0");
					setProductPromoCodeDscs("0%");
				} else {
				}
				setProductUrl("");
				setProductAmount();
				setRefFee("");
				setInfluencerFee("");
				setProductDesc("");
				setSkuData("");
				setFlag(false);
				setFlagSb(false);
				// setProductSource("ecommerce");
				setImageError(false);
				setImageFormatError(false);
			}
		}
	};

	const clearImage = () => {
		setImageFiles([]);
		// setMultiImage(multiImage.slice(1));
	};

	const ImageModal = () => {
		return (
			<Modal
				show={addImageModal}
				// onHide={() => setAddImageModal(false)}
				centered
				dialogClassName="post-modal"
				animation={false}
				backdrop={true}
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title className="mb-0">{updateProduct && ProductSku && !flag ? (source === "other" ? "Edit Web Link" : "Edit Web Store") : source === "other" ? "Add Web Link" : "Add Web Store"}</Modal.Title>
					<button
						type="button"
						className="close"
						onClick={() => {
							setAddImageModal(false);

							setImageFiles([]);
							setCoordinates("");
							// setSource("");

							setProductName("");
							setSourceId("");
							setOtherSku("");
							setproductPromoCodePromo("KB0");
							setProductPromoCodeDscs("0%");
							setProductAmount("");
							setProductUrl("");
							setProductDesc("");
							setProductSku("");
							setSkuData("");
							setImageError(false);
							setImageFormatError(false);

							{
								skuData !== "" && !flag ? setSkuData("") : setCircles(circles.slice(0, -1));
							}
							setFlag(false);
						}}
					>
						<span aria-hidden="true">×</span>
						<span className="sr-only">Close</span>
					</button>
				</Modal.Header>
				<Modal.Body className="pl-0 pr-0">
					<form onSubmit={onSubmitting}>
						<div className="d-flex web-link-form">
							<div className="sku-image-box col-md-4">
								<div className="fileinput file-profile">
									<input accept=".jpg, .jpeg, .png, .svg" onChange={(e) => onChangeInputImage(e)} id="fileupload2" type="file" name="file" className="d-none" />
									<div className="fileinput-new mb-2">
										{imageFiles.length > 0 ? (
											<div className="">
												{/* {imageFiles.map((item) => ( */}
												<img
													width="250"
													height="250"
													alt="sku-image"
													src={imageFiles[0].media_url}
													// key={`img-id-${idx.toString()}`}
													// style={{ width: "100px", height: "100px" }}
													className="sku-image"
												/>
												{/* ))} */}
											</div>
										) : (
											""
										)}
									</div>
									{imageFiles.length > 0 ? (
										<Button
											type="primary"
											danger
											onClick={clearImage}
											// className="fa fa-trash clear_circle"
											title="Clear Images"
										>
											Clear
										</Button>
									) : (
										<>
											<Button type="button" className={`select-image default-btn ${imageFiles.length > 0 ? "" : "image-space"}`}>
												<label for="fileupload2">Choose Image</label>
											</Button>
											{imageError && <div className="img-error">Image required</div>}
											{imageFormatError && <div className="img-error">Invalid Image Format</div>}
										</>
									)}
								</div>
							</div>
							<div className="col-md-8">
								<div className="mb-3">
									<label>Enter URL</label>
									<input type="text" name="url" placeholder="Enter URL" onInput={(e) => setProductUrl(e.target.value)} value={ProductUrl} className="form-control" required autoComplete="off" />
								</div>

								{/* <div className="row mb-3">
									<div className="col-md-12 ">
										<label>Enter SKU</label>
										<input type="number" name="product_name" placeholder="Enter Sku" onInput={(e) => setOtherSku(e.target.value)} value={skuDataOther} className="form-control" required autoComplete="off" />
									</div>

									<div className="col-md-12 ">
										<label>Enter Product Name</label>
										<input type="text" name="sku" placeholder="Enter Product Name" onInput={(e) => setProductName(e.target.value)} value={ProductName} className="form-control" required autoComplete="off" />
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-md-6 ">
										<label>Amount</label>
										<div className="d-flex flex-row hashtag-box">
											<span className="input-group-text">$</span>
											<input type="number" name="amount" placeholder="Enter Amount" required onInput={(e) => setProductAmount(e.target.value)} value={productAmount} className="form-control" autoComplete="off" />
										</div>
									</div>
									<div className="col-md-6">
										<label>Enter Description</label>
										<input type="text" name="description" placeholder="Enter Description" onInput={(e) => setProductDesc(e.target.value)} value={productDesc} className="form-control" autoComplete="off" />
									</div>
								</div> */}

								<div className="mb-3">
									<Button type="primary" size="large" htmlType="submit" className="w-100">
										Save
									</Button>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	};

	const style = {
		tagAreaMain: {
			position: "relative",
		},
	};

	const addCircle = (e) => {
		if (submitData.length < 3) {
			setFlag(true);
			setFlagSb(true);

			if (source) {
				setAddImageModal(true);
				setCoordinates([{ x: `1%`, y: `2%` }]);

				//	setCircles([...circles, { x: `1%`, y: `2%` }]);
			} else {
				notification.error({
					message: "Please select source to add Image",
					className: "toast-error",
				});
			}
		} else {
			// setImageError("Only 3 image tag allowed")
			notification.error({
				message: "Only 3 tags allowed",
				className: "toast-error",
			});
		}
	};

	const imgDelete = (id) => {
		Swal.fire({
			title: `Are you sure you want to remove this product?`,
			icon: "warning",
			cancelButtonText: "No",
			showCancelButton: true,
			confirmButtonColor: "#010b40",
			cancelButtonColor: "#d33",
			confirmButtonText: `Yes`,
		}).then((result) => {
			if (result.isConfirmed) {
				let imgFilter = submitData.filter(function (el) {
					return el.imgid !== id;
				});

				imgData(imgFilter);

				setSubmitData(imgFilter);

				let circles = [];
				imgFilter.map((item) => {
					let obj = item.coordinates[0];

					circles.push(obj);
				});
				setCircles(circles);
			}
		});
	};

	const ImageDetailModal = (data) => {
		return (
			<Modal dialogClassName="post-modal add-image-modal" show={detailImageModal} centered animation={false} backdrop={true} keyboard={false}>
				<Modal.Header className="modal-header-style2">
					<Modal.Title className="mb-0">Product Detail</Modal.Title>
					<button
						type="button"
						className="close"
						onClick={() => {
							setDetailImageModal(false);
						}}
					>
						<span aria-hidden="true">×</span>
						<span className="sr-only">Close</span>
					</button>
				</Modal.Header>

				<Modal.Body className="pl-0 pr-0">
					<div className="mt-3 ml-4 mr-4">
						<div className=" row">
							<Col md={4} className="sku-image-box">
								<div className="fileinput file-profile">
									<div className="fileinput-new mb-2">{source === "other" ? <img alt="sku-image" src={data?.media_url} className="sku-image" /> : <img alt="sku-image" src={data?.media_url} className="sku-image" />}</div>
								</div>
							</Col>
							<Col md={8}>
								<div className="row analytic-box">
									<div className="col-12 count-box">
										<h5 className="count-title">Product Url</h5>
										<h3 className="count product-url-link">
											{
												<a target="_blank" rel="noreferrer" href={data?.ProductUrl} className="prod-link" title={data?.ProductUrl}>
													{data?.ProductUrl && data?.ProductUrl.slice(0, 50) + "..."}
												</a>
											}{" "}
										</h3>
									</div>
								</div>
							</Col>
						</div>
					</div>
					{/* </Form> */}
				</Modal.Body>
			</Modal>
		);
	};

	const clickModal = (data) => {
		// setDetailImageModal(true);

		if (source === "other") {
			setDetailImageModal(true);
			gb = data;
		} else {
			setAddImageModal(true);
			gb = data;

			setImgId(gb.imgid);

			setProductSku(gb.ProductSku);
			setSkuData(gb);
			const description = gb.productDesc ? gb.productDesc.replace(/<\/?[^>]+(>|$)/g, "") : "";
			setProductName(gb.ProductName);
			setProductAmount(gb.productAmount);
			setProductUrl(gb.ProductUrl);
			setProductDesc(description);
			setRefFee(gb.referral_percent);
			setInfluencerFee(gb.influencerFee);
			setImageFiles(gb.media_url);
			setProductPromoCodeDscs(gb.productPromoCodeDscs);
			setproductPromoCodePromo(gb.productPromoCodePromo);
			setProductCategory(gb.productCategory);
		}
	};

	return (
		<>
			<div className="tag-area-main" style={style.tagAreaMain} ref={parentRef} id="tagImg">
				<video
					id="videoClick"
					onClick={(e) => {
						if (media !== "brand") addCircle(e);
					}}
					ref={videoRef}
				>
					<source src={postItem?.media_url} />
				</video>
			</div>

			<div className="row related-images">
				{imgtag && (
					<>
						{submitData.map((item, index) => (
							<Col md={4}>
								<div className="inner-image-box">
									<span onClick={() => clickModal(item)} className="image_num">
										{index + 1}
									</span>
									{source === "other" ? <img alt="product-image" src={`${item?.media_url}`} key={index} className="img1" onClick={() => clickModal(item)} /> : <img alt="product-image" src={item?.media_url} key={index} className="img1" onClick={() => clickModal(item)} />}
									<span
										className="close"
										title="Remove"
										onClick={() => {
											if (media !== "brand")
											imgDelete(item.imgid);
										}}
									>
										<span aria-hidden="true">×</span>
									</span>
								</div>
							</Col>
						))}
					</>
				)}

				{submitData.length === 0 && (
					<Col md={12}>
						{" "}
						<p style={{ color: "red" }}>Tag at least one Image</p>{" "}
					</Col>
				)}
			</div>

			{ImageModal()}
			{ImageDetailModal(gb)}
		</>
	);
}
export default ImageShop;
