import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import { getPurchaseReport } from "../../../redux/actions/purchase.action";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import numeral from "numeral";
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [source, setSource] = useState("all");
  const [groupBy, setGroupBy] = useState("none");
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState([]);
  const [submit, setSubmit] = useState("none");

  const { purchaseReport } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getPurchaseReport(userInfo?.token, startDate, endDate, source, groupBy)).then((res) => {
      setLoading(false);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(getPurchaseReport(userInfo?.token, startDate, endDate, source, groupBy)).then((res) => {
      setLoading(false);
    });
    setSubmit(groupBy);
  };

  const handleSource = (e) => {
    setSource(e);
  };
  const handleGroupBy = (e) => {
    setGroupBy(e);
  };

  const handleReset = (res) => {
    setSource("all");
    setGroupBy("none");
    setSubmit("none");

    setLoading(true);

    setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    dispatch(getPurchaseReport(userInfo?.token, moment().startOf("month").format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"), "all", "none")).then((res) => {
      setLoading(false);
    });
  };

  useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	  }, []);
	
	  // This is componentWillUnmount
	  useEffect(() => {
		return () => {
		//  componentWillUnmount.current = true;
		  document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	  }, []);

  function Summary() {
    return (
      purchaseReport?.payload?.message?.summary.length > 0 && (
        <div className="sales-summery">
          <div className="row">
            <div className="col-lg-6 col-xl-4">
              {purchaseReport?.payload?.message?.summary.map((item) => {
                return (
                  <>
                    <div className="summarry-box">
                      <h4 className="summary-hd">Summary</h4>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Orders</h4>
                        <h5 className="count">{item?.order_count ? item?.order_count : 0}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Quantity</h4>
                        <h5 className="count">{item?.total_qty ? item?.total_qty : 0}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Price</h4>
                        <h5 className="count">{numeral(item?.total_sale).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Discount</h4>
                        <h5 className="count">{numeral(item?.discount).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Amount Paid</h4>
                        <h5 className="count">{numeral(item?.order_totalprice).format("$0,0.0'")}</h5>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Cashback Amount</h4>
                        <h5 className="count">{numeral(item?.cashback_amount).format("$0,0.0'")}</h5>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )
    );
  }
  function DefaultTable() {
    let data = purchaseReport?.payload?.message?.summary;
    if (data) {
      return (
        <>
          <Table responsive className="transactions-box table">
            <thead className="table_heading">
              <tr>
                <th>S.#</th>
                <th>Order Date</th>
                <th>Category</th>
                <th>Order#</th>
                <th>Brand</th>
                <th>Source</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount Paid</th>
                <th>Cashback</th>
              </tr>
            </thead>
            <tbody>
              {purchaseReport.loading ? (
                <div className="antd-loading-ift">
                  <Spin size="large"></Spin>
                </div>
              ) : purchaseReport?.payload?.message?.data.length === 0 ? (
                <tr>
                  <td colspan="12">
                    <Empty
                      style={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </td>
                </tr>
              ) : (
                purchaseReport?.payload?.message?.data.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.created_date}</td>
                      <td>{item?.category_name}</td>
                      <td>
                        <span
                          className="open-modal"
                          onClick={() => {
                            setModal(true);
                            setOrder(item);
                          }}
                        >
                          {item?.order_id}
                        </span>
                      </td>
                      <td>{item?.brand_name}</td>
                      <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
                      <td>{item?.total_qty}</td>
                      <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>
                      <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                      <td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
                      <td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
                    </tr>
                  );
                })
              )}

              {purchaseReport?.payload?.message?.summary.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Total</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_qty).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_sale).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].discount).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_totalprice).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].cashback_amount).format("$0,0.0'")}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
    }
  }

  function DateGroupTable() {
    let data = purchaseReport?.payload?.message?.summary;
    if (data) {
      return (
        <>
          <Table responsive className="transactions-box table">
            <thead className="table_heading">
              <tr>
                <th>S.#</th>
                <th>Order Date</th>
                <th>Category</th>
                <th>Source</th>
				<th>No. Of Orders</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount Paid</th>
                <th>Cashback</th>
              </tr>
            </thead>
            <tbody>
              {purchaseReport.loading ? (
                <div className="antd-loading-ift">
                  <Spin size="large"></Spin>
                </div>
              ) : purchaseReport?.payload?.message?.data.length === 0 ? (
                <tr>
                  <td colspan="10">
                    <Empty
                      style={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </td>
                </tr>
              ) : (
                purchaseReport?.payload?.message?.data.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.created_date}</td>
                      <td>{item?.category_name}</td>
                      <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
					  <td>{item?.order_count}</td>
                      <td>{item?.total_qty}</td>
                      <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>
                      <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                      <td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
                      <td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
                    </tr>
                  );
                })
              )}
              {purchaseReport?.payload?.message?.summary.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                 
                  <td>
                    <b>Total</b>
                  </td>
				  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_count).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_qty).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_sale).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].discount).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_totalprice).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].cashback_amount).format("$0,0.0'")}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
    }
  }

  function CategoryGroupTable() {
    let data = purchaseReport?.payload?.message?.summary;
    if (data) {
      return (
        <>
          <Table responsive="scrollable" className="transactions-box table">
            <thead className="table_heading">
              <tr>
                <th>S.#</th>

                <th>Category</th>
                
                <th>Source</th>
				<th>No. Of Orders</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount Paid</th>
                <th>Cashback</th>
              </tr>
            </thead>
            <tbody>
              {purchaseReport.loading ? (
                <div className="antd-loading-ift">
                  <Spin size="large"></Spin>
                </div>
              ) : purchaseReport?.payload?.message?.data.length === 0 ? (
                <tr>
                  <td colspan="10">
                    <Empty
                      style={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </td>
                </tr>
              ) : (
                purchaseReport?.payload?.message?.data.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>

                      <td>{item?.category_name}</td>
                      <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
					  <td>{item?.order_count}</td>
                      <td>{item?.total_qty}</td>
                      <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>
                      <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                      <td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
                      <td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
                    </tr>
                  );
                })
              )}
              {purchaseReport?.payload?.message?.summary.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  
                  <td>
                    <b>Total</b>
                  </td>
				  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_count).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_qty).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_sale).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].discount).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_totalprice).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].cashback_amount).format("$0,0.0'")}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
    }
  }

  function BrandGroupTable() {
    let data = purchaseReport?.payload?.message?.summary;
    if (data) {
      return (
        <>
          <Table responsive="scrollable" className="transactions-box table">
            <thead className="table_heading">
              <tr>
                <th>S.#</th>
                <th>Brand</th>
                <th>Source</th>
				<th>No. Of Orders</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount Paid</th>
                <th>Cashback</th>
              </tr>
            </thead>
            <tbody>
              {purchaseReport.loading ? (
                <div className="antd-loading-ift">
                  <Spin size="large"></Spin>
                </div>
              ) : purchaseReport?.payload?.message?.data.length === 0 ? (
                <tr>
                  <td colspan="10">
                    <Empty
                      style={
                        {
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </td>
                </tr>
              ) : (
                purchaseReport?.payload?.message?.data.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>

                      <td>{item?.brand_name}</td>
                      <td>{item?.event === "eventshop" || item?.event === "eventshare" ? "Event" : "Social Store"}</td>
					  <td>{item?.order_count}</td>
                      <td>{item?.total_qty}</td>
                      <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>
                      <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                      <td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
                      <td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
                    </tr>
                  );
                })
              )}
              {purchaseReport?.payload?.message?.summary.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                 
                  <td>
                    <b>Total</b>
                  </td>
				  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_count).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_qty).format("0")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].total_sale).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].discount).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].order_totalprice).format("$0,0.0'")}</b>
                  </td>
                  <td>
                    <b>{numeral(purchaseReport?.payload?.message?.summary[0].cashback_amount).format("$0,0.0'")}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
    }
  }

  return (
    <>
      <div className="account-left-content">
        <div className="purchase-content-main">
          <Form form={form}>
            <div className="row">
              <div className="col-xl-3 col-md-6 col-12 mb-20">
                <p className="fw-bold">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
                  allowClear={false}
                  ranges={{
                    Today: [moment(), moment()],
                    Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
                    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                    "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-20">
                <p>Source </p>
                <Select className="select-style dark-field w-100" placeholder="Source" size="large" focus={false} onBlur={false} onChange={handleSource} value={source === "" ? null : source}>
                  <Option value="all">All</Option>
                  <Option value="store">Social Store</Option>
                  <Option value="event">Event</Option>
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-20">
                <p>Group By</p>
                <Select className="select-style dark-field w-100" placeholder="Group By" size="large" focus={false} onBlur={false} onChange={handleGroupBy} value={groupBy === "" ? null : groupBy}>
                  <Option value="none">None</Option>
                  <Option value="date">Date</Option>
                  <Option value="category">Category</Option>
                  <Option value="brand">Brand</Option>
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-20">
                <Button onClick={handleSubmit} className="ant-btn ant-btn-default ant-btn-lg">
                  Search
                </Button>
                <Button onClick={handleReset} className="ant-btn ant-btn-default ant-btn-lg">
                  Reset
                </Button>
              </div>
            </div>
          </Form>
          <div className="purchase-data referral-data">
            {loading ? (
              <div className="antd-loading-ift">
                <Spin size="large"></Spin>
              </div>
            ) : (
              <>
                {submit === "none" && <DefaultTable />}
                {submit === "date" && <DateGroupTable />}
                {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />}
                <Summary />
              </>
            )}
          </div>
        </div>
      </div>

      <Modal dialogClassName="code-activated-popup" size="lg" scrollable show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2 className="modal-hd1">Order Details</h2>
          <div className="order-detail-title-area">
            <span className="order-num">#{order?.order_id}</span>
            <span className="order-date">{moment(order.created_date).format("MMMM Do YYYY [from Online Store]")}</span>
          </div>
          <Table responsive="scrollable" size="sm" className="transactions-box">
            <thead className="table_heading">
              <tr>
                <th>Product SKU</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order &&
                order?.order?.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.id}</td>
                      <td>{item.qty}</td>
                      <td>${item.amt}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div className="order-sum">
            <div className="paid-mid-row">
              <div className="left-txt multi-content">
                <div className="left-hd">Subtotal</div>
                <div className="left-info">{order.total_qty} item</div>
              </div>
              <div className="right-txt">${order.total_sale}</div>
            </div>
            <div className="paid-mid-row">
              <div className="left-txt multi-content">
                <div className="left-hd">Discount</div>
                {order.promo && <div className="left-info">{order.promo}</div>}
              </div>
              <div className="right-txt">-${order.discount}</div>
            </div>
            <div className="paid-mid-row">
              <div className="left-txt multi-content">
                <div className="left-hd fw-bold">Total</div>
              </div>
              <div className="right-txt fw-bold">${order.order_totalprice}</div>
            </div>
            <div className="paid-bottom-row">
              <div className="left-txt">Paid by customer</div>
              <div className="right-txt">${order.order_totalprice}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false)}>Go Back</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
