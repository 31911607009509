import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Button, Empty } from "antd";
import moment from "moment";
// import { Button } from "react-bootstrap";
import { ChatRoom, DeleteMessageRequest, DisconnectUserRequest, SendMessageRequest } from "amazon-ivs-chat-messaging";
import { connect, useDispatch } from "react-redux";
import "../../../css/chat.scss";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";
import { saveChat } from "../../../redux/actions/chat";

const Editor = ({ onChange, onSubmit, submitting, value }) => (
	<>
		<Form.Item>
			<TextArea rows={4} onChange={onChange} value={value} />
		</Form.Item>
		<Form.Item>
			<Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
				Add Comment
			</Button>
		</Form.Item>
	</>
);
const { TextArea } = Input;
function Chat({ myChatToken, eventId, chat, publishEvent }) {
	const dispatch = useDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [comments, setComments] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [value, setValue] = useState("");
	const [connected, setConnected] = useState(false);
	const [chatRoom, setChatRoom] = useState([]);
	const scrollToBottomMethod = useScrollToBottom();

	const scrollToBottom = () => {
		scrollToBottomMethod();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!value) return;
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
			sendMessage(value);
			setValue("");
			// setComments([
			// 	...comments,
			// 	{
			// 		author: "Han Solo",
			// 		avatar: "https://joeschmoe.io/api/v1/random",
			// 		content: <p>{value}</p>,
			// 		datetime: moment().fromNow(),
			// 	},
			// ]);
		}, 1000);
	};

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		if (chat) {
			setComments(chat);
		}
	}, [chat]);

	useEffect(() => {
		if (myChatToken?.token) {
			const room = new ChatRoom({
				regionOrUrl: "us-east-1",
				tokenProvider: () => ({
					token: myChatToken.token,
					sessionExpirationTime: new Date(myChatToken.sessionExpirationTime),
					tokenExpirationTime: new Date(myChatToken.tokenExpirationTime),
				}),
			});
			setChatRoom(room);
			// Connect to the chat room
		}
	}, [myChatToken]);

	useEffect(() => {
		// If chat room listeners are not available, do not continue
		if (!chatRoom.addListener) {
			return;
		}
		/**
		 * Called when room is establishing the initial connection or reestablishing
		 * connection after socket failure/token expiration/etc
		 */
		const unsubscribeOnConnecting = chatRoom.addListener("connecting", () => {});

		/** Called when connection has been established. */
		const unsubscribeOnConnected = chatRoom.addListener("connect", (a, b) => {
			setConnected(true);
			//   console.log('aadfasfdaa', a);
			//   console.log('bbbbbbbbbbbbbbbbb', b);
		});

		/** Called when a room has been disconnected. */
		const unsubscribeOnDisconnected = chatRoom.addListener("disconnect", () => {});

		/** Called when a chat message has been received. */
		const unsubscribeOnMessageReceived = chatRoom.addListener("message", (message) => {
			// setComments((preState) => [
			//   ...preState,
			//   {
			//     // author: message?.attributes?.name,
			//     author: message?.attributes?.name,
			//     avatar: message?.attributes?.image,
			//     user_id: message?.attributes?.user_id,
			//     content: message.content,
			//     accountType: message.attributes?.accountType,
			//     datetime: moment(message?.sendTime).format('LT'),
			//   },
			// ]);
			const data = {
				author: message?.attributes?.name,
				avatar: message?.attributes?.image,
				user_id: message?.attributes?.user_id,
				content: message.content,
				accountType: message?.attributes?.accountType,
				datetime: moment(message?.sendTime).format(),
			};
			setComments((preState) => [...preState, data]);

			{
				message?.attributes?.user_id === userInfo?._id && eventId && dispatch(saveChat(eventId, data, userInfo?.token));
			}
			scrollToBottom();
			/* Example message:
			 * {
			 *   id: "5OPsDdX18qcJ",
			 *   sender: { userId: "user1" },
			 *   content: "hello world",
			 *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
			 *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de"
			 * }
			 */
		});

		/** Called when a chat event has been received. */
		const unsubscribeOnEventReceived = chatRoom.addListener("event", (event) => {
			/* Example event:
			 * {
			 *   id: "5OPsDdX18qcJ",
			 *   eventName: "customEvent,
			 *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
			 *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de",
			 *   attributes: { "Custom Attribute": "Custom Attribute Value" }
			 * }
			 */
		});

		/** Called when `aws:DELETE_MESSAGE` system event has been received. */
		const unsubscribeOnMessageDelete = chatRoom.addListener("messageDelete", (deleteMessageEvent) => {
			/* Example delete message event:
			 * {
			 *   id: "AYk6xKitV4On",
			 *   messageId: "R1BLTDN84zEO",
			 *   reason: "Spam",
			 *   sendTime: new Date("2022-10-11T12:56:41.113Z"),
			 *   requestId: "b379050a-2324-497b-9604-575cb5a9c5cd",
			 *   attributes: { MessageID: "R1BLTDN84zEO", Reason: "Spam" }
			 * }
			 */
		});

		/** Called when `aws:DISCONNECT_USER` system event has been received. */
		const unsubscribeOnUserDisconnect = chatRoom.addListener("userDisconnect", (disconnectUserEvent) => {
			/* Example event payload:
			 * {
			 *   id: "AYk6xKitV4On",
			 *   userId": "R1BLTDN84zEO",
			 *   reason": "Spam",
			 *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
			 *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
			 *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
			 * }
			 */
		});

		chatRoom.connect();
		return () => {
			unsubscribeOnConnected();
			unsubscribeOnDisconnected();
			unsubscribeOnUserDisconnect();
			unsubscribeOnConnecting();
			unsubscribeOnMessageReceived();
			unsubscribeOnEventReceived();
			unsubscribeOnMessageDelete();
		};
	}, [chatRoom]);

	const sendMessage = async (message) => {
		const content = `${message.replace(/\\/g, "\\\\").replace(/"/g, '\\"')}`;
		const request = new SendMessageRequest(content);
		try {
			await chatRoom.sendMessage({
				...request,
				Attributes: {
					user_id: userInfo?._id,
					account_type: userInfo.account_type,
					name: userInfo?.nick_name ? userInfo.nick_name : userInfo.name,
					image: userInfo?.profile_image_url,
					accountType: userInfo?.account_type,
				},
			});
		} catch (error) {
			alert(JSON.stringify(error));
			// handleError(error);
		}
	};

	if (!connected) {
		return (
			<div className="load-container-main">
				<div className="load-container">
					<Spin size="medium"></Spin>
				</div>
			</div>
		);
	}

	return (
		<div className="live-chat-comp">
			<div id="frame">
				<div className="content">
					{/* <div className="messages"> */}
					<ScrollToBottom className="messages">
						<ul className="message-ul">
							{!comments.length > 0 ? (
								<div className="align-items-center justify-content-center brand-description">
									<Empty description={false} />
								</div>
							) : (
								comments.map((it) => {
									return (
										<li className={it.accountType}>
											{/* <img src={it.avatar} alt="" /> */}
											<div className="message-box-main">
												{/* <p className="msg-content"></p> */}
												<div className="msg-content">
													<span className="user-name">{it?.author}</span>
													<span className="user-message">{it?.content}</span>
												</div>
												{/* <div className="msg-date-main">
												<small className="msg-date">{it.datetime}</small>
											</div> */}
											</div>
										</li>
									);
								})
							)}
						</ul>
					</ScrollToBottom>
					{/* </div> */}

					{!publishEvent && (
						<div className="message-input">
							<div className="logged-in-name">
								Logged in as <span>{userInfo?.nick_name ? userInfo?.nick_name : userInfo?.name}</span>
							</div>
							<div className="wrap">
								<form onSubmit={handleSubmit}>
									<input value={value} type="text" placeholder="Write your message..." onChange={handleChange} />
									{/* <i className="fa fa-paperclip attachment" aria-hidden="true"></i> */}
									<button onSubmit={handleSubmit} className="submit">
										<i className="fa fa-paper-plane" aria-hidden="true"></i>
									</button>
								</form>
							</div>
						</div>
					)}
				</div>
			</div>
			{/* {comments.length > 0 && <CommentList comments={comments} />}
			<Comment avatar={<Avatar src={userInfo?.profile_image_url} alt={userInfo?.username} />} content={<Editor onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />} /> */}
		</div>
	);
}
function mapStateToProps({ myChatToken }) {
	return { myChatToken };
}
export default connect(mapStateToProps)(Chat);
