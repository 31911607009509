import { BASEURL } from "../../config";
import axios from "axios";
import { GET_ALL_BRAND_REQUEST, GET_ALL_BRAND_SUCCESS, GET_ALL_BRAND_ERROR, GET_FAVOURITE_BRAND_REQUEST, GET_FAVOURITE_BRAND_SUCCESS, GET_FAVOURITE_BRAND_ERROR, GET_FEATURE_BRAND_REQUEST, GET_FEATURE_BRAND_SUCCESS, GET_FEATURE_BRAND_ERROR, GET_CATEGORY_WISE_BRAND_REQUEST, GET_CATEGORY_WISE_BRAND_SUCCESS, GET_CATEGORY_WISE_BRAND_ERROR, GET_CATEGORY_FAVOURITE_BRAND_REQUEST, GET_CATEGORY_FAVOURITE_BRAND_SUCCESS, GET_CATEGORY_FAVOURITE_BRAND_ERROR, GET_CATEGORY_FEATURE_BRAND_REQUEST, GET_CATEGORY_FEATURE_BRAND_SUCCESS, GET_CATEGORY_FEATURE_BRAND_ERROR, GET_SUB_CATEGORY_WISE_BRAND_REQUEST, GET_SUB_CATEGORY_WISE_BRAND_SUCCESS, GET_SUB_CATEGORY_WISE_BRAND_ERROR, POST_NEW_BRAND_REQUEST, POST_NEW_BRAND_SUCCESS, POST_NEW_BRAND_ERROR, POST_FOLLOWERS_REQUEST, POST_FOLLOWERS_SUCCESS, POST_FOLLOWERS_ERROR } from "../types/types";

var userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getAllBrand =
  (page = 1, limit = 100, name, sort = "") =>
  (dispatch) => {
    dispatch({ type: GET_ALL_BRAND_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}mobile/category/allBrands`,
      headers: {
        Accept: "application/json",
      },
      data: {
        name: name === "all" ? "" : name,
        sort: sort ? parseInt(sort) : "",
      },
      params: {
        limit,
        page,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_ALL_BRAND_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_BRAND_ERROR,
          payload: error.response,
        });
      });
  };

export const getFeatureBrand =
  (page = 1, country) =>
  async (dispatch) => {
    dispatch({ type: GET_FEATURE_BRAND_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}mobile/category/getFeatured`,
      headers: {
        Accept: "application/json",
      },
      data: {
        key: "brand",
        country: country,
      },

      params: {
        limit: 12,
        page,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_FEATURE_BRAND_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_FEATURE_BRAND_ERROR,
          payload: error.response,
        });
      });
  };

export const getCategoryFeatureBrand = (page, category, type) => async (dispatch) => {
  dispatch({ type: GET_CATEGORY_FEATURE_BRAND_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}mobile/category/getCategoryFeatured`,
    headers: {
      Accept: "application/json",
    },
    data: {
      key: "brand",
      category_id: category,
      type: type,
    },

    params: {
      limit: 12,
      page,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_CATEGORY_FEATURE_BRAND_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CATEGORY_FEATURE_BRAND_ERROR,
        payload: error.response,
      });
    });
};

export const getCategoryWiseBrand =
  (page = 1, limit = 100, categoryId, name = "", sort = "") =>
  (dispatch) => {
    dispatch({ type: GET_CATEGORY_WISE_BRAND_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}mobile/category/categoryWiseInfluencerAndBrand`,
      headers: {
        Accept: "application/json",
      },
      data: {
        categoryId: categoryId,
        account_type: "brand",
        name: name,
        sort: sort ? parseInt(sort) : "",
      },
      params: {
        limit,
        page,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_CATEGORY_WISE_BRAND_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_CATEGORY_WISE_BRAND_ERROR,
          payload: error.response,
        });
      });
  };

export const getFavouriteBrand = () => (dispatch) => {
  dispatch({ type: GET_FAVOURITE_BRAND_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}mobile/category/getLovedBrands`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_FAVOURITE_BRAND_SUCCESS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_FAVOURITE_BRAND_ERROR,
        payload: error.response,
      });
    });
};

export const getCategoryFavouriteBrand = (category, type) => (dispatch) => {
  dispatch({ type: GET_CATEGORY_FAVOURITE_BRAND_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}mobile/category/getCategoryLovedBrands`,
    data: { category_id: category, type: type },
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_CATEGORY_FAVOURITE_BRAND_SUCCESS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CATEGORY_FAVOURITE_BRAND_ERROR,
        payload: error.response,
      });
    });
};

export const getSubCategoryWiseBrand =
  (categoryId, key, name, page = 1, limit = 100) =>
  (dispatch) => {
    let categoryUrl = `mobile/category/categoryWiseInfluencerAndBrand`;
    if (key === "subcategory") {
      categoryUrl = `mobile/category/getInfluencerSubCategoryWise?limit=${limit}&page=${page}`;
    }
    dispatch({ type: GET_SUB_CATEGORY_WISE_BRAND_REQUEST });
    return axios({
      method: "POST",
      url: BASEURL + categoryUrl,
      headers: {
        Accept: "application/json",
      },
      data: {
        account_type: "brand",
        categoryId: categoryId,
        name: name ? name : "",
        key: key,
      },
      params: {
        limit,
        page,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_SUB_CATEGORY_WISE_BRAND_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_SUB_CATEGORY_WISE_BRAND_ERROR,
          payload: error.response,
        });
      });
  };

//

export const vcomBrand = () => (dispatch) => {
  dispatch({ type: POST_NEW_BRAND_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}vcom/brand`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_NEW_BRAND_SUCCESS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_NEW_BRAND_ERROR,
        payload: error.response,
      });
    });
};

export const brandFollower = (user) => (dispatch) => {
  dispatch({ type: POST_FOLLOWERS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}public/graph/ig/user`,
    data: {
      user,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_FOLLOWERS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_FOLLOWERS_ERROR,
        payload: error.response,
      });
    });
};
