import React, { useState, useEffect } from "react";
import { Select, Tabs } from "antd";
import BioShopGrid from "../../../components/BioShopGrid/BioShopGrid";
const { TabPane } = Tabs;

export default function BioShopSetup() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(searchParams.get("type") === null ? "BioShopPost" : searchParams.get("type"));
  const [eventStatus, setEventStatus] = useState(123);

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set("type", key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  return (
    <>
      <div className="account-left-content">
        <div className="favourite-brands-container">
          <div>
            <Tabs
              // defaultActiveKey="Upcoming"
              defaultActiveKey={eventTitle}
              // onChange={onChange}
              className="events-tabs-main"
              onTabClick={callbackTabClicked}
            >
              <TabPane tab="Social Store Post" key="BioShopPost">
                <BioShopGrid className={gridActive} title={eventTitle} eventStatus={eventStatus} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
