import Layout from "./component/layout";
// import galleryGrid from "../../components/mediaGalleryGrid";
import MediaGallery from "./mediaGallery/index";

export default () => {
	return (
		<>
			<Layout title="Manage Gallery">
				<MediaGallery />
			</Layout>
		</>
	);
};
