import { GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR, GET_UPDATE_ACCOUNT_REQUEST, GET_UPDATE_ACCOUNT_SUCCESS, GET_UPDATE_ACCOUNT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getAccount = (token) => (dispatch) => {
	dispatch({ type: GET_ACCOUNT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/auth/appTokenValidate`,
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_ACCOUNT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ACCOUNT_ERROR,
				payload: error.response,
			});
		});
};

export const updateAccount =
	({ name, gender, country, state, city, bio, token, zip, first_name, last_name, nick_name }) =>
	(dispatch) => {
		dispatch({ type: GET_UPDATE_ACCOUNT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/private/updateMobileUserPofile`,
			headers: { Authorization: `Bearer ${token}` },
			data: {
				country,
				state,
				city,
				bio,
				name,
				gender,
				zip,
				first_name, 
				last_name, 
				nick_name,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_UPDATE_ACCOUNT_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_UPDATE_ACCOUNT_ERROR,
					payload: error.response,
				});
			});
	};
