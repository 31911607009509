import {
    POST_RESERVE_REQUEST,
    POST_RESERVE_SUCCESS,
    POST_RESERVE_ERROR,
  } from '../../types/types';
  
  export default function reservedPost(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case POST_RESERVE_REQUEST:
        return {
          loading: true,
        };
      case POST_RESERVE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      case POST_RESERVE_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      default:
        return state;
    }
  }