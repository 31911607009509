import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { getChangePassword } from "../../redux/actions/changePassword.action";
import { LoadingOutlined } from "@ant-design/icons";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
export const history = createBrowserHistory({ forceRefresh: true });

export default () => {
	const dispatch = useDispatch();
	const { changePassword, showChangePasswordModal } = useSelector((state) => {
		return state;
	});
	const [error, setError] = useState(false);
	const antIcon = (
		<LoadingOutlined
			style={{
				fontSize: 24,
			}}
			spin
		/>
	);

	const [showPasswordModal, setShowPasswordModal] = useState(false);
	useEffect(() => {
		setShowPasswordModal(showChangePasswordModal?.payload);
	}, [showChangePasswordModal]);

	const onFinish = async (values) => {
		const userInfo = JSON.parse(localStorage.getItem("userInfo"));
		const currentPassword = values.currentPassword;
		const newPassword = values.password;
		dispatch(getChangePassword(userInfo._id, currentPassword, newPassword)).then((res) => {
			if (res?.payload?.success) {
				setShowPasswordModal(false);
				Swal.fire({
					icon: "success",
					title: "Your password changed successfully !",
				});
			} else {
				setError(res?.payload?.data?.message);
			}
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Modal
			dialogClassName="signup-modal form-close-modal"
			size="md"
			show={showPasswordModal}
			// onHide={() => setLoginModal(false)}
			centered
			animation={false}
			backdrop={true}
			keyboard={false}
		>
			<Modal.Header>
				<button
					type="button"
					className="close"
					onClick={() => {
						setShowPasswordModal(false);
					}}
				>
					<span aria-hidden="true">×</span>
					<span className="sr-only">Close</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="login-signup-ift">
					<Form
						name="basic"
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 24,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<h2>Change Password</h2>
						{/* <p className="text-danger">{changePassword?.payload?.data?.message}</p> */}

						<Form.Item
							name="currentPassword"
							hasFeedback
							validateStatus={error && "error"}
							help={error && error}
							rules={[
								{
									required: true,
									message: "Please input your current password!",
								},
							]}
						>
							<Input.Password placeholder="Current password" size="large" />
						</Form.Item>

						<Form.Item
							name="password"
							hasFeedback
							rules={[
								{
									required: true,
									message: "Please enter new password",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("currentPassword") === value) {
											if (getFieldValue("currentPassword") && value) {
												// console.log("test", getFieldValue("currentPassword"));
												return Promise.reject(new Error("Passwords that you entered should not be same as current!"));
											}
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input.Password placeholder="New password" size="large" />
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							dependencies={["password"]}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Please confirm your password!",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}

										return Promise.reject(new Error("Passwords that you entered do not match!"));
									},
								}),
							]}
						>
							<Input.Password placeholder="Confirm password" size="large" />
						</Form.Item>
						{changePassword.loading ? (
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Button type="primary" size="large" block>
									<Spin indicator={antIcon} />
								</Button>
							</Form.Item>
						) : (
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Button type="primary" htmlType="submit" size="large" block>
									Submit
								</Button>
							</Form.Item>
						)}
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};
