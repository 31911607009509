import React from "react";
import GallerySummaryComponent from "./gallerySummaryComponent";
class GalleryDashboard extends React.Component {
  state = {
    username: this.props.username,
  };
  render() {
    return (
      <React.Fragment>
          <div className="brand_container_main col-xl-6 col-lg-8 col-md-12 p-0">
              <GallerySummaryComponent
                username={this.state.username}
                className="col-md-8"
              />
          </div>
      </React.Fragment>
    );
  }
}
export default GalleryDashboard;