import { GET_GALLERY_POST_REQUEST, GET_GALLERY_POST_SUCCESS, GET_GALLERY_POST_ERROR, GET_INSTA_POST,DELETE_MEDIA_REQUEST, DELETE_MEDIA_SUCCESS, DELETE_MEDIA_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const galleryPost = () => (dispatch) => {
	dispatch({ type: GET_GALLERY_POST_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}library/receive/source/gallery?limit=50&page=1`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {},
	})
		.then((response) => {
			return dispatch({
				type: GET_GALLERY_POST_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_GALLERY_POST_ERROR,
				payload: error.response,
			});
		});
};

export const deleteMedia = (id) => (dispatch) => {
	dispatch({ type: DELETE_MEDIA_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}library/remove/${id}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: DELETE_MEDIA_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_MEDIA_ERROR,
				payload: error.response,
			});
		});
};

export const getMedia =
	(status, page = 1, limit = 8) =>
	async (dispatch) => {
		const res = await axios.get(`${BASEURL}library/receive?status=${status}&limit=${limit}&page=${page}`, { headers: { Authorization: `Bearer ${userInfo?.token}` } });
		dispatch({
			type: GET_INSTA_POST,
			payload: res.data.message,
		});
	};
