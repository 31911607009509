import React, { useState } from "react";
import { connect } from "react-redux";
import * as instaPostActions from "../../../redux/actions/instaPost";
import Dropzone from "react-dropzone-uploader";
import { Spin, notification } from "antd";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons";

function UploadMedia({ createMedia, title }) {
	const [submit, setSubmit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [imgSize, setImgsize] = useState(false);
	const [ImgMsg, setImgMsg] = useState("");
	const [flag, setFlag] = useState(false);

	const [fields, setFields] = useState({
		title: "",
		image: "",
	});

	function bytesToSize(bytes) {
		var sizes = ["B", "KB", "MB", "GB", "TB", "PB"];
		for (var i = 0; i < sizes.length; i++) {
			if (bytes <= 1024) {
				return bytes + " " + sizes[i];
			} else {
				bytes = parseFloat(bytes / 1024).toFixed(2);
			}
		}
		return bytes + " P";
	}

	const fileParams = ({ meta }) => {
		return { url: "https://httpbin.org/post" };
	};

	const onSubmit = (files, allFiles) => {
		setSubmit(true);
		if (fields.image) {
			setLoading(true);
			createMedia(fields).then(() => {
				notification.success({
					message: "Media Upload Successfully",
					className: "toast-success",
				});

				setLoading(false);
				files.forEach((f) => f.remove());
				setImgMsg("");
				setFields({
					title: "",
					image: "",
				});
				setSubmit(false);
			});
		}
	};
	const getFilesFromEvent = (e) => {
		return new Promise((resolve) => {
			getDroppedOrSelectedFiles(e).then((chosenFiles) => {
				resolve(chosenFiles.map((f) => f.fileObject));
			});
		});
	};
	const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
		const textMsg = files.length > 0 ? "Upload Again" : "Browse Media";
		return (
			<>
				<div className="upload_area_2">
					<FontAwesomeIcon icon={faCloudArrowUp} />
					<h4>Drag & Drop Your Media Here</h4>
					<h4>Or</h4>
					{imgSize ? <h5 class="text-danger">{ImgMsg}</h5> : <></>}
					<label className="btn btn-primary mr-0 mb-0 default-btn medium-btn txt-btn-pad">
						{textMsg}
						<input
							style={{ display: "none" }}
							type="file"
							accept={accept}
							multiple={false}
							onChange={(e) => {
								getFilesFromEvent(e).then((chosenFiles) => {
									onFiles(chosenFiles);
								});
							}}
							l
						/>
					</label>
				</div>
			</>
		);
	};

	const remove = (allFiles) => {
		setFlag(false);
		setImgMsg("");
		setSubmit(false);
		setLoading(false);
		allFiles.forEach((f) => f.remove());
	};

	const Preview = ({ meta, files }) => {
		const { name, percent, status, previewUrl, size } = meta;
		if (size > 20500000) {
			setFlag(true);
			setImgsize(true);
			remove(files);
			setImgMsg("Your File Size Can Not Be Exceed More Than 20 MB.");
		}

		return (
			<>
				<div className="preview-box-media">
					<div className="pre-img-upload">
						<img src={previewUrl} />{" "}
					</div>

					<div className="pre-content-upload">
						<div className="glry-img-name">{name}</div> <div className="glry-img-size">{bytesToSize(size)}</div>
						{status !== "done" ? <span>Uploading</span> : "Done"}
						<div className="status">{status}</div>
						<div className="pro-brar-ift">
							{flag === false ? <ProgressBar animated now={percent} label={`${percent.toFixed(0)}%`} /> : <></>}
							<FontAwesomeIcon icon={faXmark} onClick={removeFile(files)} />
						</div>
					</div>
				</div>
			</>
		);
	};

	const removeFile = (allFiles) => () => {
		setFlag(false);
		setImgMsg("");
		setSubmit(false);
		setLoading(false);
		allFiles.forEach((f) => f.remove());
	};
	const onFileChange = ({ file }) => {
		if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/gif" || file.type === "image/svg+xml" || file.type === "video/mp4" || file.type === "video/flv" || file.type === "video/mov" || file.type === "video/avi" || file.type === "video/wmv") {
			setFields({
				...fields,
				image: file,
				media_type: file.type === "video/mp4" || file.type === "video/flv" || file.type === "video/mov" || file.type === "video/avi" || file.type === "video/wmv" ? "VIDEO" : "IMAGE",
			});
		} else {
			notification.error({
				message: '"We Only Support PNG, GIF, JPG, MP4, FLV, MOV, AVI, WMV Format"',
				className: "toast-error",
			});
		}
	};

	return (
		<div className="brand_container_main col-xl-6 col-lg-8 col-md-12 p-0">
			<div className="upload_box_main">
				<div className=" brand-section dash_block_profile dash_content_profile">
					<div className="upload_area">
						<h4>Upload Your Media</h4>
						<p className="text-muted">PNG, JPG, SVG, GIF, MP4, FLV, MOV, AVI, WMV Files Are Allowed</p>
					</div>
					<Dropzone
						onSubmit={onSubmit}
						onChangeStatus={onFileChange}
						InputComponent={selectFileInput}
						getUploadParams={fileParams}
						getFilesFromEvent={getFilesFromEvent}
						accept=".jpg, .jpeg, .png, .gif, .svg, .mp4, 3gp, ogg"
						// accept="image/*,video/*"
						maxFiles={1}
						maxSizeBytes={20500000}
						//minSizeBytes={20500}
						// inputContent="Drop A File"
						addClassNames={{
							dropzone: "drag-drop-ift",
							submitButtonContainer: "upload_btn",
						}}
						PreviewComponent={Preview}
						submitButtonContent={() =>
							loading ? (
								<span className="spinclass spin-color-white pos-top-3 position-relative">
									<Spin size="small" />
								</span>
							) : (
								"Upload"
							)
						}
						submitButtonDisabled={loading}
						styles={{
							dropzoneActive: { borderColor: "green" },
						}}
					/>
					{submit && !fields.image ? <small style={{ color: "red" }}>Please Select Media</small> : null}
				</div>
			</div>
		</div>
	);
}
export default connect(null, instaPostActions)(UploadMedia);