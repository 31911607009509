import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFeatureBrand } from "../../redux/actions/brand.action";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CategoryFeatureBrand() {
	const dispatch = useDispatch();
	const { featureBrand, selectedCountry } = useSelector((state) => {
		return state;
	});
	const [modalData, setModalData] = useState([]);
	const [showCustomerModal, setShowCustomerModal] = useState(false);

	useEffect(() => {
		if (selectedCountry.payload) {
			dispatch(getFeatureBrand(1, selectedCountry?.payload));
		}
	}, [selectedCountry.payload]);

	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 6,
		rows: 2,
		swipe: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1800,
				settings: {
					slidesToShow: 6,
					swipe: true,
					arrows: false,
				},
			},
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 4,
					arrows: true,
					swipe: true,
				},
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 3.2,
					arrows: false,
					swipe: true,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1.3,
					arrows: false,
					swipe: true,
				},
			},
		],
	};

	return (
		<>
			<div className="top_picks_main">
				<div className="midd-width-ifti">
					<div className="top_picks">
						<div className="pik-header">
							<h3>Featured Brands</h3>
							{/* <a href="#">See All Stores</a> */}
						</div>

						<div className="brand-logos">
							{featureBrand.loading ? (
								"loading"
							) : (
								<Slider {...settings}>
									{featureBrand?.payload?.data?.map((item, i) => {
										return (
											<>
												<div className="indv-brand">
													{item?.brandData[0]?.promo !== "KB0" && item?.brandData[0]?.promo ? (
														<a
															onClick={() => {
																if (item) {
																	setModalData(item);
																}
																setShowCustomerModal(true);
															}}
														>
															<div className="brand-boxes">
																<div className="brand-boxes-logos">
																	<img src={item.brandData[0]?.profile_image_url} />
																</div>
																<div className="brand-user">
																	<div className="brnd-user-title">
																		<span>{item?.brandData[0]?.brand_name}</span>
																	</div>
																	<div className="brand-user-off">{item.brandData[0]?.promo !== "KB0" && item.brandData[0]?.promo && <h4>{item.brandData[0]?.discount} Off</h4>}</div>
																</div>
															</div>
														</a>
													) : (
															<NavLink to={`/brand-events/${item.brandData[0]?.instagram_username ? item.brandData[0]?.instagram_username : item.brandData[0]?.pixel_id}`}>
															<div className="brand-boxes">
																<div className="brand-boxes-logos">
																	<img src={item.brandData[0]?.profile_image_url} />
																</div>
																<div className="brand-user">
																	<div className="brnd-user-title">
																		<span>{item?.brandData[0]?.brand_name}</span>
																	</div>
																	<div>{item?.promo !== "KB0" && item?.promo && <h4>{item.discount} Off</h4>}</div>
																</div>
															</div>
														</NavLink>
													)}
												</div>
											</>
										);
									})}
								</Slider>
							)}
						</div>
					</div>
				</div>
			</div>

			<Modal dialogClassName="code-activated-popup" size="md" scrollable show={showCustomerModal} onHide={() => setShowCustomerModal(false)} centered>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="act-brand-logo">
						<img src={`${modalData && modalData.brandData && modalData?.brandData[0]?.profile_image_url}`} />
					</div>
					<div className="code-detail">
						<h1>
							{modalData && modalData.brandData && modalData?.brandData[0]?.discount} OFF
							<span>CODE ACTIVATED</span>
						</h1>
						<NavLink to={`/${modalData && modalData.brandData && modalData?.brandData[0]?.instagram_username}`} className="code-button">
							GOT IT
						</NavLink>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
