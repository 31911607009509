import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getSubCategoryWiseBrand } from "../../redux/actions/brand.action";
import { NavLink } from "react-router-dom";
import { Empty } from "antd";
import CatInfLoader from "../../components/loader/categoryBrandInfLoader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;

export default ({ heading }) => {
  const dispatch = useDispatch();
  const [activeAlpha, setActiveAlpha] = useState("");
  const [modalData, setModalData] = useState([]);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const { allBrand } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const categoryId = params.id;
  const category = params.category;

  useEffect(() => {
    dispatch(getSubCategoryWiseBrand(category, "subcategory"));
  }, []);

  const getBrandByAlphaBets = (alphabet) => {
    setActiveAlpha(alphabet);
    dispatch(getSubCategoryWiseBrand(categoryId, "subcategory", alphabet, 1, 100));
  };

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 21,
    slidesToScroll: 2,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1430,
        settings: {
          slidesToShow: 19,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 18,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 14,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 7.7,
          arrows: false,
          slidesToScroll: 7,
        },
      },
    ],
  };

  const alphabet = () => {
    return new Array(26).fill().map((_, i) => {
      let alphabet = (i + 10).toString(36).toUpperCase();
      return (
        <div key={i}>
          <span className={activeAlpha === alphabet ? "active-tab categ-button" : "categ-button"} onClick={() => getBrandByAlphaBets(alphabet)}>
            {alphabet}
          </span>
        </div>
      );
    });
  };

  return (
    <>
      <div className="cat_brands_main">
        <div className="midd-width-ifti">
          <div className="cat_brands">
            <div className="pik-header">
              <h3>{heading}</h3>
              <div className="sort-filter">
                <Select size="large" defaultValue="Top Discount">
                  <Option value="">Top Discount</Option>
                  <Option value="1">Alphabet A-Z</Option>
                  <Option value="-1">Alphabet Z-A</Option>
                </Select>
              </div>
            </div>

            <div className="category_slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <span
                      className={activeAlpha === "" ? "active-tab categ-button" : "categ-button"}
                      onClick={() => {
                        getBrandByAlphaBets("");
                      }}
                    >
                      All
                    </span>
                  </div>
                  {alphabet()}
                </Slider>
                {/* <div>{this.state.sortData}</div> */}
                {allBrand.loading ? (
                  <CatInfLoader />
                ) : (
                  <>
                    {allBrand?.payload?.data?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                    <div className="filter_brands">
                      {allBrand?.payload?.data?.map((item, key) => {
                        return (
                          <div key={key} className="ft_box">
                            {item?.promo !== "KB0" && item?.promo ? (
                              <a
                                onClick={() => {
                                  setModalData(item);
                                  setShowCustomerModal(true);
                                }}
                                className="ft_box_link"
                              >
                                <div className="ft_logo">
                                  <img src={item?.profile_image_url} />
                                </div>
                                <div className="ft_content">
                                  <div className="ft-1">
                                    <span className="ft-name">{item?.brand_name}</span>
                                  </div>

                                  {item?.promo !== "KB0" && item.promo && (
                                    <div className="ft-2">
                                      <span className="ft-off">{item.discount} Off Sitewide</span>
                                    </div>
                                  )}
                                </div>
                              </a>
                            ) : (
                              <NavLink
                                onClick={() => {
                                  setModalData(item);
                                  setShowCustomerModal(true);
                                }}
                                to={`/category/${item?.instagram_username ? item?.instagram_username : item?.pixel_id}/${item._id}/${category}/${categoryId}`}
                                className="ft_box_link"
                              >
                                <div className="ft_logo">
                                  <img src={item?.profile_image_url} />
                                </div>
                                <div className="ft_content">
                                  <div className="ft-1">
                                    <span className="ft-name">{item?.brand_name}</span>
                                  </div>
                                </div>
                              </NavLink>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal dialogClassName="code-activated-popup" size="md" scrollable show={showCustomerModal} onHide={() => setShowCustomerModal(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="act-brand-logo">
            <img src={`${modalData.profile_image_url}`} />
          </div>
          <div className="code-detail">
            <h1>
              {modalData.discount} OFF
              <span>CODE ACTIVATED</span>
            </h1>
            <NavLink to={`/${modalData.instagram_username}`} className="code-button">
              GOT IT
            </NavLink>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
