import Dashboard from "./component/dashboard";
import Layout from "./component/layout";
export default () => {
	return (
		<>
			<Layout title="Dashboard">
				<Dashboard />
			</Layout>
		</>
	);
};
