import { Button, Form, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putResetPassword } from "../../redux/actions/resetPassword.action";
import { createBrowserHistory } from "history";
import { useParams,useNavigate } from "react-router-dom";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
const history = createBrowserHistory({ forceRefresh: true });

export default function ForgetPassword({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgetPassword } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const token = params.id;
  const onFinish = (values) => {
    dispatch(putResetPassword(token, values.password)).then((res) => {
      if (res?.payload?.success) {
        Swal.fire({
          icon: "success",
          title: "Password Reset Successfully",
        }).then(() => {
          navigate("/");
          window.location.reload();
        });
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="account-container-main">
      <div className="midd-width-ifti">
        <div className="reset-page account-inr-container">
          <div className="login-signup-ift">
            <Form
              name="basic"
              
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <h2>Reset Password</h2>
              <p className="text-danger text-center">
                {forgetPassword?.payload?.data?.message}
              </p>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" size="large" />
              </Form.Item>

              <Form.Item
                name="retypePassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Retype Password" size="large" />
              </Form.Item>
              <Form.Item
                
              >
                {forgetPassword.loading ? (
                  <Button type="primary" size="large" block loading>
                    Loading
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit" size="large" block>
                    Reset Password
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
