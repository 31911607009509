import Layout from "./component/layout";
import Wallet from "./component/wallet";
export default () => {
	return (
		<>
			<Layout title="Payout">
				<Wallet />
			</Layout>
		</>
	);
};