import {
    GET_GALLERY_POST_REQUEST,
    GET_GALLERY_POST_SUCCESS,
    GET_GALLERY_POST_ERROR,
  } from '../../types/types';
  
  export default function galleryPosting(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_GALLERY_POST_REQUEST:
        return {
          loading: true,
        };
      case GET_GALLERY_POST_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      case GET_GALLERY_POST_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      default:
        return state;
    }
  }
  
  