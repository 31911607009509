import {
  GET_LIVE_EVENT_REQUEST,
  GET_LIVE_EVENT_SUCCESS,
  GET_LIVE_EVENT_ERROR,
  GET_EVENT_ID_REQUEST,
  GET_EVENT_ID_SUCCESS,
  GET_EVENT_ID_ERROR,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  SHOPIFY_GET_CART_REQUEST,
  SHOPIFY_GET_CART_SUCCESS,
  SHOPIFY_GET_CART_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
export const getLiveEvent = () => (dispatch) => {
  dispatch({ type: GET_LIVE_EVENT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}mobile/category/getBanners`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_LIVE_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_LIVE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getEventsBy_Id = (id,userId) => (dispatch) => {
  dispatch({ type: GET_EVENT_ID_REQUEST });

  return axios({
    method: "GET",
    url: `${BASEURL}public/events/getoneevent/${id}?user=${userId}`,
    headers: {
      Accept: "application/json",
    },
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      // console.log(response);
      return dispatch({
        type: GET_EVENT_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_ID_ERROR,
        payload: error.response,
      });
    });
};


export const getShopifyCart = (id, token) => (dispatch) => {
  dispatch({ type: SHOPIFY_GET_CART_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}shopify/getcart`,

    headers: { Authorization: `Bearer ${token}` },
    data: {
      brand: id,
    },
  })
    .then((response) => {
      return dispatch({
        type: SHOPIFY_GET_CART_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SHOPIFY_GET_CART_ERROR,
        payload: error.response,
      });
    });
};


export const getEvents = (limit, page, title, sort) => (dispatch) => {
  let status = '';
  dispatch({ type: GET_EVENT_REQUEST });

  if (title === 'Live Events') {
    status = 'live';
  }
  if (title === 'Upcoming') {
    status = 'scheduled';
  }
  if (title === 'Recorded') {
    status = 'recorded';
  }
  if (title === 'scheduled,live') {
    status = 'scheduled,live';
  }

  return axios({
    method: 'GET',
    url: `${BASEURL}events/getallevents?limit=${limit}&page=${page}&status=${status}&sortby=${
      status === 'recorded' ? sort : ''
    }`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_EVENT_SUCCESS,
        payload: response.data,
      });
      status = '';
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_ERROR,
        payload: error.response,
      });
    });
};