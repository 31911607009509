import { SET_DEFAULT_SHIPPING_ADDRESS_REQUEST, 
	SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS, 
	SET_DEFAULT_SHIPPING_ADDRESS_ERROR, 
	CREATE_SHIPPING_ADDRESS_REQUEST, 
	CREATE_SHIPPING_ADDRESS_SUCCESS, 
	CREATE_SHIPPING_ADDRESS_ERROR, 
	GET_ALL_SHIPPING_ADDRESS_REQUEST, 
	GET_ALL_SHIPPING_ADDRESS_SUCCESS, 
	GET_ALL_SHIPPING_ADDRESS_ERROR, 
	SET_UPDATED_SHIPPING_REQUEST, 
	SET_UPDATED_SHIPPING_SUCCESS, 
	SET_UPDATED_SHIPPING_ERROR,
	POST_DELETE_SHIPPING_REQUEST,
	POST_DELETE_SHIPPING_SUCCESS,
	POST_DELETE_SHIPPING_ERROR
 } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const createShippingAddress = (data, token) => (dispatch) => {
  dispatch({ type: CREATE_SHIPPING_ADDRESS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}users/shipping/createnewaddress`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address,
      address2: data.apartment,
      country: data.country,
      province: data.state,
      city: data.city,
      zip: data.zip,
      phone: data.phone,
      company: data.company,
    },
  })
    .then((response) => {
      return dispatch({
        type: CREATE_SHIPPING_ADDRESS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: CREATE_SHIPPING_ADDRESS_ERROR,
        payload: error.response,
      });
    });
};

export const getAllShippingAddress = (token) => (dispatch) => {
  dispatch({ type: GET_ALL_SHIPPING_ADDRESS_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}users/shipping/getalladdresses`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_SHIPPING_ADDRESS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_SHIPPING_ADDRESS_ERROR,
        payload: error.response,
      });
    });
};

export const updateDefaultShippingAddress = (id, token) => (dispatch) => {
  dispatch({ type: SET_DEFAULT_SHIPPING_ADDRESS_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}users/shipping/setdefaultaddress/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SET_DEFAULT_SHIPPING_ADDRESS_ERROR,
        payload: error.response,
      });
    });
};

export const updatedShippingAddress = (data, token) => (dispatch) => {
  dispatch({ type: SET_UPDATED_SHIPPING_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}users/shipping/updateaddress`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      address_id: data.address_id,
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address,
      address2: data.apartment,
      country: data.country,
      province: data.state,
      city: data.city,
      zip: data.zip,
      phone: data.phone,
      company: data.company,
    },
  })
    .then((response) => {
      return dispatch({
        type: SET_UPDATED_SHIPPING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SET_UPDATED_SHIPPING_ERROR,
        payload: error.response,
      });
    });
};

export const deleteShipAddress = (address_id, token) => (dispatch) => {
  dispatch({ type: POST_DELETE_SHIPPING_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}users/shipping/deleteaddress`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      address_id,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_DELETE_SHIPPING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_DELETE_SHIPPING_ERROR,
        payload: error.response,
      });
    });
};
