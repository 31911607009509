import React, { useEffect } from "react";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAccount } from "../../../redux/actions/account.action";
import { openPasswordModal } from "../../../redux/actions/changePassword.action";
import "../../../css/account-setup.scss";
import ChangePassword from "../../../pages/auth/changePassword";
import PasswordSetting from "../../../components/passwordSetting/passwordSetting";
import placeholderImage from "../../../images/placeholder-image.jpg";

export default () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state);
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo?.token && account?.length === 0)
      dispatch(getAccount(userInfo?.token));
  }, []);

  useEffect(() => {
    dispatch(openPasswordModal(false));
  }, []);

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);
  console.log("account", account);
  return (
    <>
      <div className="account-left-content">
        <div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
          <div className="user-profile">
            <div className="user-profile-image">
              <div className="prof-img">
                <span className="profile-pic">
                <img
                  alt="..."
                  src={
                    account?.payload?.Data?.profile_image_url
                      ? account?.payload?.Data?.profile_image_url
                      : placeholderImage
                  }
                  style={{ width: "76px", height: "76px" }}
                />
                </span>
                <span className="profile-name">Profile Image</span>
              </div>
            </div>
            <ul>
              {userInfo?.account_type === "brand" ? (
                <li>
                  <span>Company Name</span>{" "}
                  <span>{account?.payload?.Data?.name}</span>
                </li>
              ) : (
                <>
                  <li>
                    <span>Nick Name</span>{" "}
                    <span>{account?.payload?.Data?.nick_name}</span>
                  </li>
                  <li>
                    <span>First Name</span>{" "}
                    <span>{account?.payload?.Data?.first_name}</span>
                  </li>
                  <li>
                    <span>Last Name</span>{" "}
                    <span>{account?.payload?.Data?.last_name}</span>
                  </li>
                </>
              )}
              <li>
                <span>Email</span> <span>{account?.payload?.Data?.email}</span>
              </li>
              {/* <li>
                <span>Zip code</span> <span>{account?.payload?.Data?.zip}</span>
              </li> */}

              {userInfo?.account_type === "brand" && (
                <>
                  <li>
                    <span>Country</span>{" "}
                    <span>{account?.payload?.Data?.country}</span>
                  </li>
                  <li>
                    <span>State</span>{" "}
                    <span>{account?.payload?.Data?.state}</span>
                  </li>
                  <li>
                    <span>City</span>{" "}
                    <span>{account?.payload?.Data?.city}</span>
                  </li>
                </>
              )}
            </ul>
            <div className="edit-button">
              <Button
                onClick={() => {
                  navigate("/accountDetail");
                }}
              >
                Edit Profile
              </Button>
            </div>
          </div>
          <div className="mt-2">
            <PasswordSetting />
            <ChangePassword />
          </div>
        </div>
      </div>
    </>
  );
};
