import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import placeholder from "../../../images/placeholder.svg";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import Swal from "sweetalert2";
import { notification, Button, Form } from "antd";
import { verifyCategories, postSaveCategories, getCategoriesSetup, getAllCategories } from "../../../redux/actions/category.action";
import EditCustomCategory from "../../category/editCategory";

export default () => {
	const dispatch = useDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [loading, setLoading] = useState(false);
	const [sort, setSort] = useState(false);
	const [categoryError, setCategoryError] = useState("");
	const [categoryLimit, setCategoryLimit] = useState("");
	const [myCategory, setMyCategory] = useState("");
	const [saveCategories, setSaveCategories] = useState("");
	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	useEffect(() => {
		dispatch(getAllCategories()).then((res) => {
			if (res.type == "GET_ALL_CATEGORIES_SUCCESS") {
				const selectCategories = [];
				const myCategories = res.payload.message;
				myCategories.map(({ parent_id, category_name, image_url }) => {
					return selectCategories.push({
						value: parent_id,
						label: category_name,
						image: image_url,
					});
				});
				setMyCategory(selectCategories);
				setCategoryLimit(res.payload.category_limit);
			} else {
			}
		});
		dispatch(getCategoriesSetup()).then((res) => {
			if (res.type == "GET_CATEGORIES_SETUP_SUCCESS") {
				const saveCategories = [];
				const optionCategories = res?.payload?.message;
				console.log("options", optionCategories);
				optionCategories.map(({ parent_id, category_name, image_url, editable, category_id }) => {
					return saveCategories.push({
						value: parent_id,
						label: category_name,
						image: image_url,
						editable: editable,
						category_id: category_id,
					});
				});
				setSaveCategories(saveCategories);
			} else {
			}
		});
	}, []);

	const SortableItem = SortableElement(({ value }) => (
		<div key={value.value} className="cat-box col-sm-3 col-4">
			<img key={value.value} src={value.image === "" || value.image === undefined ? placeholder : value.image} alt="cat-logo" className="img-fluid cat-image" />
			<div className="cat-lable">{value.label}</div>
			{value.editable ? (
				<div className="action">
					<EditCustomCategory
						userID={userInfo._id}
						fetchMyCategory={() =>
							dispatch(getAllCategories()).then((res) => {
								if (res.type == "GET_ALL_CATEGORIES_SUCCESS") {
									const selectCategories = [];
									const myCategories = res.payload.message;
									myCategories.map(({ parent_id, category_name, image_url }) => {
										return selectCategories.push({
											value: parent_id,
											label: category_name,
											image: image_url,
										});
									});
									setMyCategory(selectCategories);
									setCategoryLimit(res.payload.category_limit);
								} else {
								}
							})
						}
						fetchSaveCategory={() =>
							dispatch(getCategoriesSetup()).then((res) => {
								if (res.type == "GET_CATEGORIES_SETUP_SUCCESS") {
									const saveCategories = [];
									const optionCategories = res.payload.message;
									optionCategories.map(({ parent_id, category_name, image_url, editable, category_id }) => {
										return saveCategories.push({
											value: parent_id,
											label: category_name,
											image: image_url,
											editable: editable,
											category_id: category_id,
										});
									});
									setSaveCategories(saveCategories);
								} else {
								}
							})
						}
						catData={value}
					/>
				</div>
			) : null}
		</div>
	));

	const SortableList = SortableContainer(({ items }) => (
		<Row>
			{items.map((value, index) => (
				<SortableItem key={`item-${index.toString()}`} index={index} value={value} />
			))}
		</Row>
	));

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setSaveCategories(arrayMove(saveCategories, oldIndex, newIndex));
		setSort(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let category =
			saveCategories === null
				? []
				: saveCategories.map((category) => {
						return {
							category_name: category.label,
							category_id: category.editable ? category.category_id : category.value,
							image_url: category.image,
							editable: category.editable,
						};
				  });
		setLoading(true);
		dispatch(postSaveCategories(category, sort)).then((res) => {
			if (res.type == "POST_SAVE_CATEGORIES_SUCCESS") {
				setCategoryError("");
				setLoading(false);
				setSort(false);
				notification.success({
					message: res.payload.message,
					className: "toast-success",
				});
				dispatch(getCategoriesSetup()).then((res) => {
					if (res.type == "GET_CATEGORIES_SETUP_SUCCESS") {
						const saveCategories = [];
						const optionCategories = res.payload.message;
						optionCategories.map(({ parent_id, category_name, image_url, editable, category_id }) => {
							return saveCategories.push({
								value: parent_id,
								label: category_name,
								image: image_url,
								editable: editable,
								category_id: category_id,
							});
						});
						setSaveCategories(saveCategories);
					} else {
					}
				});
			} else {
				notification.error({
					message: res.payload.data.message,
					className: "toast-error",
				});
				setCategoryError("");
				setLoading(false);
			}
		});
	};

	const handleSelect = (e, options) => {
		if (e.removedValue) {
			if (e.removedValue.category_id) {
				dispatch(verifyCategories(e.removedValue.category_id)).then((res) => {
					if (res.payload.success) {
						Swal.fire({
							title: "Are You Sure You Want To Delete This Category?",
							text: "This Will Uncategorize All Your Post Related To This Category.",
							icon: "warning",
							showCancelButton: true,
							confirmButtonColor: "#010b40",
							cancelButtonColor: "#d33",
							confirmButtonText: "Yes, delete it!",
						}).then((result) => {
							if (result.isConfirmed) {
								setSaveCategories(options);
							} else {
								setSaveCategories(saveCategories);
							}
						});
					} else {
						notification.error({
							message: res.payload.data.message,
							className: "toast-error",
							duration: 6,
						});
					}
				});
			} else {
				options = options === null ? [] : options;
				setSaveCategories(options === null ? [] : options);
				setCategoryError("");
			}
		} else {
			options = options === null ? [] : options;
			setSaveCategories(options === null ? [] : options);
			setCategoryError("");
		}
	};

	return (
		<>
			<div className="account-left-content">
				<div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
					<div className="category-setup">
						<div className="brand-section dash_block_profile">
							<div className="dash_content_profile">
								<Row>
									<Col md={12}>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<h3 className="py-0 px-0">Add Category</h3>
											<p>({saveCategories.length})</p>
										</div>

										{saveCategories === "" ? null : <Select isMulti={true} name="category" className="selectCustomization" options={myCategory} value={saveCategories} placeholder="Select Category" onChange={(options, e) => handleSelect(e, options)} isClearable={false} />}
										<span className="text-danger">{categoryError}</span>

										{saveCategories.length === 0 ? (
											<Row>
												<span className="ml-4 mt-2 mb-2">No Category Selected</span>
											</Row>
										) : (
											<SortableList items={saveCategories} onSortEnd={onSortEnd} axis="xy" lockToContainerEdges={true} lockOffset="10%" distance={1} />
										)}
									</Col>
								</Row>

								<div className="mt-4 category-save-button ">
									<Form.Item>
										<Button id="cat-save" disabled={!loading ? false : true} onClick={handleSubmit} type="primary" htmlType="button" size="large" block>
											Save
										</Button>
									</Form.Item>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
