import { useEffect, useState } from "react";
import { Radio, Space } from "antd";
import { Modal, Button } from "react-bootstrap";
import "../../css/live-events.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import CategoryFilterModal from "../../components/categoryFilterModal/categoryFilterModal";
import { useDispatch, useSelector } from "react-redux";
import UpComingEvent from "../../components/upComingEvent/upComingEvent";
import LiveEvent from "../../components/liveEvent/liveEvent";
import PublishEvent from "../../components/publishEvent/publishEvent";
import { getParentCategories } from "../../redux/actions/category.action";
import closeicon from "../../images/brand-logos/close-icon.svg";

// import PopularChannel from "../../components/popularChannel/popularChannel";
import { getAllPublishedEvent } from "../../redux/actions/liveShopping.action";
import InfluencersReview from "../../components/influencersReview/influencersReview";

export default function LiveShopping() {
	const [category, setCategory] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [allCategory, setAllCategory] = useState([]);
	const [activeCategory, setActiveCategory] = useState("");
	const [setSubCategory] = useState([]);

	const [categoryId, setCategoryId] = useState([]);

	const [filterPost, setFilterPost] = useState({
		categoryId: [],
		subCategoryId: "",
		sortBy: "",
		eventType: "",
		applyFilter: "all",
	});

	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const { brand, account, bioShopMenu } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		dispatch(getParentCategories()).then((res) => {
			setAllCategory(res.payload.data);
		});
	}, []);

	useEffect(() => {
		//alert(socialStore)
		// document.body.classList.add("bioshop-body");
		//dispatch(getBioShopMenu(params.username, userInfo?.token));
	}, []);

	useEffect(() => {
		if (brand?.payload?.success) {
			dispatch(getAllPublishedEvent(1, 25, category, brand?.payload?.message)).then((res) => {
				setData(res?.payload?.message?.data);
			});
		}
	}, [category]);

	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	return (
		<>
			<div className="live-event-main">
				<div className="midd-width-ifti">
					<div className="live-shows">
						<div className="event-filter">
							<Button
								onClick={() => {
									setShowFilter(true);
								}}
								variant="primary"
							>
								Filters
							</Button>{" "}
						</div>
						{!brand?.loading && <LiveEvent origin={"home"} category={filterPost?.parentId} sortBy={filterPost?.sortBy} eventType={filterPost?.eventType} applyFilter={filterPost?.applyFilter} source="home" />}
						{!brand?.loading && <UpComingEvent origin={"home"} category={filterPost?.parentId} sortBy={filterPost?.sortBy} eventType={filterPost?.eventType} applyFilter={filterPost?.applyFilter} source="home" />}
						{!brand?.loading && <PublishEvent origin={"home"} category={filterPost?.parentId} sortBy={filterPost?.sortBy} eventType={filterPost?.eventType} applyFilter={filterPost?.applyFilter} source="home" />}
						<InfluencersReview origin={'home'} source={"home"} />
					</div>
				</div>
			</div>

			<Modal
				backdropClassName="custom-backdrop"
				dialogClassName="filter-modal-box"
				show={showFilter}
				scrollable
				onHide={() => {
					setShowFilter(false);
				}}
				centered
			>
				<span
					className="filter-close"
					onClick={() => {
						setShowFilter(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>

				<Modal.Body>
					<div className="filter-container">
						<h3>Sort by</h3>
						<div className="radio-box">
							<Radio.Group
								onChange={(e) => {
									setFilterPost({ ...filterPost, sortBy: e.target.value, applyFilter: false });
								}}
								value={filterPost.sortBy}
								size="large"
							>
								<Space direction="vertical">
									<Radio value={""}>Date</Radio>
									<Radio value={"topdiscount"}>Top Discount</Radio>
									<Radio value={"referralfee"}>Referral Fee</Radio>
									{userInfo && account?.payload?.Data?.account_type !== "customer" ? <Radio value={"influencerfee"}>Influencer Fee</Radio> : <></>}
								</Space>
							</Radio.Group>
						</div>
					</div>

					<div className="filter-container">
						<h3>Event Type</h3>
						<div className="radio-box">
							<Radio.Group
								onChange={(e) => {
									setFilterPost({ ...filterPost, eventType: e.target.value, applyFilter: false });
								}}
								value={filterPost.eventType}
								size="large"
							>
								<Space direction="vertical">
									<Radio value="">All</Radio>
									<Radio value="liveEvents">Live Events</Radio>
									<Radio value="upcomingEvents">Upcoming Events</Radio>
									<Radio value="previousEvents">Previous Events</Radio>
								</Space>
							</Radio.Group>
						</div>
					</div>

					<div className="filter-container">
						<h3>Categories</h3>
						<div className="filter-box">
							<Button
								onClick={() => {
									setFilterPost({
										...filterPost,
										categoryId: "",
										parentId: [],
										applyFilter: false,
									});
									setActiveCategory("");
									setCategoryId([])
									setSubCategory([]);
								}}
								className={activeCategory === "" && "active"}
								variant="primary"
							>
								All
							</Button>

							{allCategory?.length > 0 &&
								allCategory.map((item) => (
									<Button
										className={categoryId.includes(item?._id) && "active"}
										onClick={() => {
											let allCategories = [];
											if (categoryId.includes(item?._id)) {
												let catId = categoryId.filter(function (items) {
													return items !== item?._id;
												});
												allCategories = catId;
												setCategoryId(catId);

												setFilterPost({
													...filterPost,
													categoryId: "",
													sortBy: "",
													eventType: "",
													parentId: allCategories,
													applyFilter: false,
												});

												//	setActiveCategory("");
											} else {
												allCategories = [...categoryId, item?._id];
												setCategoryId([...categoryId, item?._id]);
												setActiveCategory(item?._id);

												setFilterPost({
													...filterPost,
													categoryId: "",
													sortBy: "",
													eventType: "",
													parentId: allCategories,
													applyFilter: false,
												});
											}
										}}
										variant="primary"
									>
										{item?.category_name}
									</Button>
								))}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{" "}
					<Button
						onClick={() => {
							setShowFilter(false);
							setCategoryId([])
							setFilterPost({
								...filterPost,
								categoryId: "",
								sortBy: "",
								eventType: "",
								parentId: [],
								applyFilter: Math.random(),
							});

							setActiveCategory("");
							setSubCategory([]);
							

						}}
						variant="secondary"
					>
						Reset all
					</Button>
					<Button
						onClick={() => {
							setFilterPost({ ...filterPost, applyFilter: Math.random() });
							setShowFilter(false);
						}}
						variant="primary"
					>
						Apply filter
					</Button>
				</Modal.Footer>
			</Modal>

			{/* <CategoryFilterModal setCategory={setCategory} showFilter={showFilter} setShowFilter={setShowFilter} /> */}
		</>
	);
}
