import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import SkeletonPosts from "../../../components/skeletons/SkeletonPosts";
import { getBioShopAllPost, getBioShopCategoryPost, getBioShopPostClick, getBioShopViewCount } from "../../../redux/actions/bioShop.action";
import { dateInDays } from "../../../helper/date";
import Popup from "./popup";
import numeral from "numeral";

export default (props) => {
	const dispatch = useDispatch();
	const { bioShopMenu, account } = useSelector((state) => state);
	const [showCustomerModal, setsShowCustomerModal] = useState(false);
	const params = useParams();
	const [post, setPost] = useState([]);
	const [loading, setLoading] = useState(true);

	const [singleItem, setSingleItem] = useState("");
	const [hasMore, sethasMore] = useState(true);
	const [page, setpage] = useState(2);
	const userId = bioShopMenu?.payload?.message?.data._id;

	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setpage(2);
	}, [params.username]);

	let userInfo = localStorage.getItem("userInfo");

	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	useEffect(() => {
		if (props.filterPost.applyFilter || props.filterPost.applyFilter == "all") {
			if (props.filterPost.categoryId === "") {
				setLoading(true);
				setpage(2);
				dispatch(getBioShopAllPost(params.username, 1, 18, props.filterPost.postType, props.filterPost.sortBy)).then((res) => {
					setPost(res?.payload?.message?.result.data);
					sethasMore(res?.payload?.message?.result?.next?.page ? true : false);
					setLoading(false);
					dispatch(getBioShopViewCount(res?.payload?.message?.user_id, params.username));
				});
			}
		}
	}, [params.username, props.filterPost]);

	useEffect(() => {
		if (props.filterPost.applyFilter) {
			if (props.filterPost.categoryId) {
				setpage(2);
				setLoading(true);
				dispatch(getBioShopCategoryPost(params.username, 1, 18, props.filterPost.categoryId, props.filterPost.parentid, props.filterPost.subCategoryId, props.filterPost.postType, props.filterPost.sortBy)).then((res) => {
					setPost(res?.payload?.message?.result.data);
					sethasMore(res?.payload?.message?.result?.next?.page ? true : false);
					setLoading(false);
				});
			}
		}
	}, [props.filterPost]);

	const fetchMorePost = async () => {
		if (hasMore) {
			if (props.filterPost.categoryId === "") {
				return dispatch(getBioShopAllPost(params.username, page, 18, props.filterPost.postType, props.filterPost.sortBy)).then((res) => {
					if (res?.payload?.message?.result?.data?.length) {
						sethasMore(res?.payload?.message?.result?.next?.page ? true : false);
						return res?.payload?.message?.result.data;
					}
				});
			} else {
				return dispatch(getBioShopCategoryPost(params.username, page, 18, props?.filterPost?.categoryId, props?.filterPost.parentid, props.filterPost.subCategoryId, props.filterPost.postType, props.filterPost.sortBy)).then((res) => {
					sethasMore(res?.payload?.message?.result?.next?.page ? true : false);
					return res?.payload?.message?.result.data;
				});
			}
		}
	};

	const discountPercent = (percent, actualAmount) => {
		let num = percent?.replace(/[^0-9]/g, "");
		return ((num / 100) * actualAmount).toFixed(2);
	};

	const fetchData = async () => {
		return await fetchMorePost().then((postData) => {
			if (postData) {
				setPost([...post, ...postData]);
				setpage(page + 1);
			}
		});
	};

	const clickModal = (data) => {
		setsShowCustomerModal(true);
		setSingleItem(data);
	};

	return (
		<>
			<div className="bio_cat_images">
				<section className="tabsSecMain">
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane fade show active">
							{loading ? (
								[1, 2].map((n) => <SkeletonPosts key={n} theme="light" />)
							) : post?.length > 0 ? (
								<>
									<InfiniteScroll
										dataLength={post.length}
										className="post-grid"
										next={fetchData}
										hasMore={hasMore}
										loader={
											<div className={`container`}>
												<div className="d-flex justify-content-center">
													<div className="content-loader"></div>
												</div>
											</div>
										}
										// useWindow={false}
									>
										{post.map((item, i) => (
											<div
												key={i}
												className={`post-image ${item?.source === "brand_event" ? "post-event-box" : ""}`}
												//	onClick={() => clickModal(true)}
											>
												<div
													onClick={() => {
														if (item?.children?.length > 0) clickModal({ ...item, index: i });
													}}
													className={`image-box ${item.media_type === "VIDEO" && "play-button"}`}
												>
													{item.media_type === "VIDEO" && <i className="fa fa-play-circle" aria-hidden="true"></i>}
													{item.source === "brand_event" && (
														<div className="inf-item-detail">
															<div className="vid-time">
																<div className="vid-date">{dateInDays(item.end_date)}</div>
																{item?.discount !== "0%" && item?.discount ? <div className="vid-date off-percentage">{item.discount} Off</div> : ""}
															</div>
															<div className="author-name justify-content-between">
																<div class="auth-left">
																	<div class="auth-image">{item?.brand[0]?.profile_image_url && <img alt="" src={item?.brand[0]?.profile_image_url} />}</div>
																	{item.brand[0].brand_name && <div class="auth-title">{item.brand[0].brand_name}</div>}
																</div>
																<div class="auth-right">
																	{userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.children[0]?.influencerFee !== "0" && item?.children[0]?.influencerFee !== "undefined" && item.influencerFee ? <div className="inf-fee">{item?.children[0]?.influencerFee}% Influencer Fee</div> : "" : ""}
																	{userInfo?.account_type !== "influencer" ? item?.children[0]?.referral_percent !== "0" && item?.children[0]?.referral_percent !== "undefined" && item?.children[0]?.referral_percent ? <div className="ref-fee">{item?.children[0]?.referral_percent}% Referral Fee</div> : "" : ""}
																</div>
															</div>
														</div>
													)}
													{item?.children?.length ? (
														<>
															<span className={item.source === "brand_event" && item.children[0]?.default_banner && "default-image"}>
																{item.media_type === "VIDEO" ? (
																	<>
																		<video id={`post-video-${item.post_id}`} controlsList="nodownload" preload="metadata" poster={item.media_url}>
																			<source src={item.media_url + "#t=0.1"} type="video/mp4"></source>
																		</video>
																	</>
																) : (
																	<>
																		<img src={item.media_url} alt="media_url" className="rounded" />
																	</>
																)}
															</span>
														</>
													) : (
														<>
															{item.media_type === "VIDEO" ? (
																<>
																	{" "}
																	<span>
																		<video
																			muted
																			onMouseDown={(e) => {
																				if (e.nativeEvent.button === 1) {
																					window.open(item.redirected_url);
																					dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																				}
																			}}
																			onClick={(ev) => {
																				window.open(item.redirected_url);
																				dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																			}}
																			key={i}
																			id={`post-video-${item.post_id}`}
																			controlsList="nodownload"
																			preload="metadata"
																			poster={item.media_url}
																		>
																			<source src={item.media_url + "#t=0.001"} type="video/mp4"></source>
																		</video>
																	</span>
																</>
															) : (
																<span
																	onMouseDown={(e) => {
																		if (e.nativeEvent.button === 1) {
																			window.open(item?.redirected_url);
																			dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																		}
																	}}
																	onClick={(ev) => {
																		dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																	}}
																	key={i}
																>
																	<a href={item?.redirected_url} target="_blank" className="image_link">
																		<img src={item?.media_url} alt="media_image" className="rounded image-inner-media" />
																	</a>
																</span>
															)}
														</>
													)}
												</div>
												{item?.children?.length > 0 && (
													<div className="cat-pro-detail">
														<div className="cat-pro-title">
															<span className="bio-item-title">{item?.children[0]?.ProductName}</span>
															<div className="inf-ref-fee-main">
																{userInfo && account?.payload?.Data?.account_type !== "customer" && item?.source !== "brand_event" ? item.children[0]?.influencerFee !== "0%" && item.children[0]?.influencerFee ? <span className="inf-item-fee">{item.children[0]?.influencerFee}% Influencer Fee</span> : "" : ""}
																{userInfo?.account_type !== "influencer" ? item.children[0]?.referral_percent !== "0%" && item.children[0]?.referral_percent ? <span className="bio-item-fee">{item.children[0]?.referral_percent}% Referral Fee</span> : "" : ""}
															</div>
														</div>

														<div className="cat-pro-price">
															{item.children[0]?.productPromoCodePromo === "KB0" ? <span></span> : <>{item?.source !== "brand_event" && <span className="original-price">${numeral(item.children[0]?.productAmount).format("0.00")}</span>}</>}
															{item.children[0]?.productAmount && <span className="discounted-price">{item.children[0]?.productPromoCodePromo === "KB0" ? `$${numeral(item.children[0]?.productAmount).format("0.00")}` : item.children[0]?.productPromoCodeDscs?.includes("%") ? `$${numeral(item.children[0]?.productAmount - discountPercent(item.children[0]?.productPromoCodeDscs, item.children[0]?.productAmount)).format("0.00")}  ` : `$${numeral(item.children[0]?.productAmount - item.children[0]?.productPromoCodeDscs?.replace(/[^0-9]/g, "")).format("0.00")}`}</span>}
														</div>
													</div>
												)}
											</div>
										))}
									</InfiniteScroll>
								</>
							) : (
								<div className="no-data-found">
									<h1>Nothing Here Yet</h1>
									<p>{params.username} hasn't add anything.</p>
								</div>
							)}
						</div>
					</div>
				</section>
			</div>
			<Popup
				socialStore={props.socialStore}
				setStore={props.setStore}
				store={props.store}
				fetchData={(status) => {
					if (status) {
						fetchData();
					}
				}}
				setVisible={setVisible}
				showCustomerModal={showCustomerModal}
				setsShowCustomerModal={setsShowCustomerModal}
				username={params.username}
				item={singleItem}
				post={post?.map((item, i) => {
					return {
						...item,
						index: i,
					};
				})}
			/>

			{/* Antd Drawer */}
		</>
	);
};
