import { GET_REFRESH_FACEBOOK_REQUEST, GET_REFRESH_FACEBOOK_SUCCESS, GET_REFRESH_FACEBOOK_ERROR, GET_FACEBOOK_REQUEST, GET_FACEBOOK_SUCCESS, GET_FACEBOOK_ERROR, POST_FACEBOOK_USER_REQUEST, POST_FACEBOOK_USER_SUCCESS, POST_FACEBOOK_USER_ERROR, GET_FACEBOOK_ACCESS_REQUEST, GET_FACEBOOK_ACCESS_SUCCESS, GET_FACEBOOK_ACCESS_ERROR, GET_FACEBOOK_PAGES_REQUEST, GET_FACEBOOK_PAGES_SUCCESS, GET_FACEBOOK_PAGES_ERROR, FACEBOOK_RESET_REQUEST, FACEBOOK_RESET_SUCCESS, FACEBOOK_RESET_ERROR, FACEBOOK_TOKEN_REQUEST, FACEBOOK_TOKEN_SUCCESS, FACEBOOK_TOKEN_ERROR, GET_TOKEN_REQUEST, GET_TOKEN_SUCCESS, GET_TOKEN_ERROR, POST_SELECTED_PAGES_REQUEST, POST_SELECTED_PAGES_SUCCESS, POST_SELECTED_PAGES_ERROR, POST_FACEBOOK_MEDIA_REQUEST, POST_FACEBOOK_MEDIA_SUCCESS, POST_FACEBOOK_MEDIA_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

var userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const refreshFb = () => (dispatch) => {
	dispatch({ type: GET_REFRESH_FACEBOOK_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}social/fb/refreshfacebooktoken`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_REFRESH_FACEBOOK_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_REFRESH_FACEBOOK_ERROR,
				payload: error.response,
			});
		});
};

export const facebookAccess = (token) => (dispatch) => {
	dispatch({ type: GET_FACEBOOK_REQUEST });
	return axios({
		method: "GET",
		url: `https://graph.facebook.com/v12.0/oauth/access_token?
      grant_type=fb_exchange_token&
      client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&
      client_secret=${process.env.REACT_APP_FACEBOOK_SECRET_ID}&
      fb_exchange_token=${token}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_FACEBOOK_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FACEBOOK_ERROR,
				payload: error.response,
			});
		});
};

export const facebookUser = (token, fbToken) => (dispatch) => {
	dispatch({ type: POST_FACEBOOK_USER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}social/fb/token/user`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			fbToken: fbToken,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_FACEBOOK_USER_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_FACEBOOK_USER_ERROR,
				payload: error.response,
			});
		});
};

export const facebookAccesstoken = (facebookPageId, facebookUserAccessToken) => (dispatch) => {
	dispatch({ type: GET_FACEBOOK_ACCESS_REQUEST });
	return axios({
		method: "GET",
		url: `https://graph.facebook.com/v12.0/${facebookPageId}?fields=instagram_business_account&access_token=${facebookUserAccessToken}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_FACEBOOK_ACCESS_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FACEBOOK_ACCESS_ERROR,
				payload: error.response,
			});
		});
};

export const getFbPages = (facebookUserAccessToken) => (dispatch) => {
	dispatch({ type: GET_FACEBOOK_PAGES_REQUEST });
	return axios({
		method: "GET",
		url: `https://graph.facebook.com/v12.0/me/accounts?access_token=${facebookUserAccessToken}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_FACEBOOK_PAGES_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FACEBOOK_PAGES_ERROR,
				payload: error.response,
			});
		});
};

export const facebookReset = (token, userId) => (dispatch) => {
	dispatch({ type: FACEBOOK_RESET_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}users/revise/resetfacebook/${userId}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: FACEBOOK_RESET_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: FACEBOOK_RESET_ERROR,
				payload: error.response,
			});
		});
};

export const fbsToken = (selectedPage, insta, facebookUserAccessToken) => (dispatch) => {
	dispatch({ type: FACEBOOK_TOKEN_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}social/fb/token/page`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			fbPage: selectedPage,
			instaId: insta,
			fb_page_access_token: facebookUserAccessToken,
		},
	})
		.then((response) => {
			return dispatch({
				type: FACEBOOK_TOKEN_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: FACEBOOK_TOKEN_ERROR,
				payload: error.response,
			});
		});
};

export const tokenAccess = (selectedPage, facebookUserAccessToken) => (dispatch) => {
	dispatch({ type: GET_TOKEN_REQUEST });
	return axios({
		method: "GET",
		url: `https://graph.facebook.com/${selectedPage}?fields=access_token&access_token=${facebookUserAccessToken}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_TOKEN_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_TOKEN_ERROR,
				payload: error.response,
			});
		});
};

export const facebookSelected = (selectedPage, accessToken) => (dispatch) => {
	dispatch({ type: POST_SELECTED_PAGES_REQUEST });
	return axios({
		method: "POST",
		url: `https://graph.facebook.com/v12.0/${selectedPage}/subscribed_apps?subscribed_fields=feed&access_token=${accessToken}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: POST_SELECTED_PAGES_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_SELECTED_PAGES_ERROR,
				payload: error.response,
			});
		});
};

export const publishFb = (media_url, caption, link) => (dispatch) => {
	dispatch({ type: POST_FACEBOOK_MEDIA_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}graph/fb/publishmedia/publish`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			media_url,
			caption,
			link,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_FACEBOOK_MEDIA_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_FACEBOOK_MEDIA_ERROR,
				payload: error.response,
			});
		});
};
