import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, notification, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getCountry } from "../../../redux/actions/country.action";
import { getState } from "../../../redux/actions/state.action";
import { getCity } from "../../../redux/actions/city.action";
import { openPasswordModal } from "../../../redux/actions/changePassword.action";
import { getAccount, updateAccount } from "../../../redux/actions/account.action";
import placeholderImage from "../../../images/placeholder-image.jpg";
import { getUserInfo } from "../../../redux/actions/userInfo.action";
import { basicProfileImage } from "../../../redux/actions/profileImageBasic.action";
import Swal from "sweetalert2";
import ChangePassword from "../../../pages/auth/changePassword";
import "../../../css/account-setup.scss";
import { useNavigate } from "react-router-dom";
import PasswordSetting from "../../../components/passwordSetting/passwordSetting";
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default () => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const { account, accountUpdate, userInfo: userrInfo } = useSelector((state) => state);
	const [countryList, setCountryList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [country, setCountry] = useState([]);
	const [state, setState] = useState([]);
	const [image, setImage] = useState([]);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const onFinish = async (values) => {
		if (userInfo?.token) {
			dispatch(updateAccount({ ...values, token: userInfo?.token })).then(() => {
				Swal.fire({
					icon: "success",
					title: "Profile updated!",
				});
				dispatch(getAccount(userInfo?.token)).then(() => {
					navigate("/myaccount");
				});
			});
		}
	};

	useEffect(() => {
		dispatch(getUserInfo()).then((res) => {
			if (res.success) {
				console.log("res", res);
			}
		});
	}, []);
	console.log("userrInfo", userrInfo);
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	useEffect(() => {
		dispatch(openPasswordModal(false));
		getCountryFunc();
	}, []);

	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);

	useEffect(() => {
		if (account) {
			if (account?.payload?.Data?.country) {
				setCountry(account?.payload?.Data?.country);
				getStateFunc(account?.payload?.Data?.country);
				getCityFunc(account?.payload?.Data?.country, account?.payload?.Data?.state);
			}
			form.setFieldsValue({
				name: account?.payload?.Data?.name,
				email: account?.payload?.Data?.email,
				gender: account?.payload?.Data?.gender,
				country: account?.payload?.Data?.country,
				state: account?.payload?.Data?.state,
				city: account?.payload?.Data?.city,

				zip: account?.payload?.Data?.zip,
				nick_name: account?.payload?.Data?.nick_name,
				first_name: account?.payload?.Data?.first_name,
				last_name: account?.payload?.Data?.last_name,

				//bio: account?.payload?.Data?.bio,
			});
		}
	}, [account]);

	const getCountryFunc = () => {
		if (country.length === 0) {
			dispatch(getCountry()).then((res) => {
				const selectCountries = [];
				res.payload.message.map(({ name, code1 }) => {
					return selectCountries.push({ value: code1, label: name });
				});
				setCountryList(selectCountries);
			});
		}
	};

	const getStateFunc = (country) => {
		dispatch(getState(country)).then((res) => {
			const selectStates = [];
			res.payload.message.map(({ name, isoCode }) => {
				return selectStates.push({ value: isoCode, label: name });
			});
			setStateList(selectStates);
			setCityList([]);
		});
	};

	const getCityFunc = (country, state) => {
		dispatch(getCity(country, state)).then((res) => {
			const selectStates = [];
			res.payload.message.map(({ name }) => {
				return selectStates.push({ value: name, label: name });
			});
			setCityList(selectStates);
		});
	};

	const changeCountry = (value) => {
		setCountry(value);
		getStateFunc(value);
		form.resetFields(["state"]);
		form.resetFields(["city"]);
	};

	const changeState = (value) => {
		setState(value);
		getCityFunc(country, value);
		form.resetFields(["city"]);
	};

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	const clearImage = () => {
		setImage([]);
	};

	const uploadImage = async () => {
		setLoading(true);
		var formData = new FormData();
		formData.append("image", image?.[0]);
		formData.append("instagram_username", form.getFieldValue().username);
		dispatch(basicProfileImage(formData))
			.then((res) => {
				if (res.success) {
					// dispatch(getUserInfo()).then((res) => {

					// });
					dispatch(getAccount(userInfo.token)).then(() => {
						setImage([]);
						notification.success({
							message: "Image Has Been Uploaded!",
							className: "toast-success",
						});
						setLoading(false);
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				setImage([]);
			});
	};

	const onChangeInputImage = (e) => {
		if (e.target.files.length === 1) {
			if (e.target.files[0]?.type === "image/jpeg" || e.target.files[0]?.type === "image/webp" || e.target.files[0]?.type === "image/png" || e.target.files[0]?.type === "image/svg+xml") {
				const files = [];
				const reader = new FileReader();
				files.push(e.target.files[0]);
				reader.onloadend = () => {
					files[0].preview = reader.result;
					files[0].toUpload = true;
					setImage(files);
				};
				reader.readAsDataURL(e.target.files[0]);
			} else {
				notification.error({
					message: "We Only Support PNG, WEBP, Or JPG Image!",
					className: "toast-error",
				});
			}
		}
	};

	return (
		<>
			<div className="account-left-content">
				<div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
					<div className="pr-op-block">
						<div className="user-profile-image">
							<div className="prof-img">
								<span className="profile-pic">
									<img alt="..." src={image?.[0]?.preview ? image?.[0]?.preview : account?.payload?.Data?.profile_image_url ? account?.payload?.Data?.profile_image_url : placeholderImage} style={{ width: "76px", height: "76px" }} className="circle profile-icon" />
								</span>
								<span className="profile-name">Profile Image</span>
							</div>
							<div className="porf-btns">
								<input accept=".jpg, .jpeg, .png, .webp, .gif" onChange={onChangeInputImage} onClick={(e) => (e.target.value = null)} id="fileupload5" type="file" name="file" className="d-none" />
								<label htmlFor="fileupload5">Change Image</label>
								{loading ? (
									<Button>
										<Spin indicator={antIcon} />
									</Button>
								) : (
									<Button onClick={uploadImage} disabled={image.length > 0 ? false : true} loading={loading}>
										Save
									</Button>
								)}

								<Button onClick={clearImage} disabled={image.length > 0 ? false : true} type="button" color="default">
									Cancel
								</Button>
							</div>
						</div>

						<Form layout={"vertical"} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
							<div className="profile-form-main">
								{userInfo?.account_type === "brand" ? (
									<div className="profile-form-col">
										<Form.Item
											label="Company Name"
											name="name"
											rules={[
												{
													required: true,
													message: "Please input Company name",
												},
											]}
										>
											<Input placeholder="" size="large" />
										</Form.Item>
									</div>
								) : (
									<>
										<div className="profile-form-col">
											<Form.Item
												label="Nick Name"
												name="nick_name"
												rules={[
													{
														required: true,
														message: "Please input Nick name",
													},
												]}
											>
												<Input placeholder="" size="large" />
											</Form.Item>
										</div>
										<div className="profile-form-col">
											<Form.Item
												label="First Name"
												name="first_name"
												rules={[
													{
														required: true,
														message: "Please input your First name",
													},
												]}
											>
												<Input placeholder="" size="large" />
											</Form.Item>
										</div>
										<div className="profile-form-col">
											<Form.Item
												label="Last Name"
												name="last_name"
												rules={[
													{
														required: true,
														message: "Please input your Last name",
													},
												]}
											>
												<Input placeholder="" size="large" />
											</Form.Item>
										</div>
									</>
								)}
								<div className="profile-form-col">
									<Form.Item
										label="Email"
										name={["email"]}
										rules={[
											{
												type: "email",
												required: true,
												message: "Please Enter Email",
											},
										]}
									>
										<Input disabled placeholder="" size="large" />
									</Form.Item>
								</div>
								{/* <div className="profile-form-col">
									<Form.Item
										label="Zip Code"
										name="zip"
										rules={[
											{
												required: true,
												message: "Please input Zip code",
											},
										]}
									>
										<Input placeholder="" size="large" />
									</Form.Item>
								</div> */}

								{/* {userInfo.account_type != "brand" && (
									<div className="profile-form-col">
										<Form.Item
											label="Gender"
											name="gender"
											rules={[
												{
													required: true,
													message: "Please Enter Gender",
												},
											]}
										>
											<Select showSearch placeholder="Select Gender" optionFilterProp="children" size="large" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
												<Option value="male">Male</Option>
												<Option value="female">Female</Option>
												<Option value="other">Other</Option>
											</Select>
										</Form.Item>
									</div>
								)} */}
								{userInfo?.account_type === "brand" && (
									<>
										<div className="profile-form-col">
											<Form.Item
												label="Country"
												name="country"
												rules={[
													{
														required: true,
														message: "Please Select Country",
													},
												]}
											>
												<Select showSearch placeholder="Select Country" size="large" optionFilterProp="children" onChange={changeCountry} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{countryList?.map((item) => (
														<Option value={item.value}>{item.label}</Option>
													))}
												</Select>
											</Form.Item>
										</div>
										<div className="profile-form-col">
											<Form.Item
												label="State"
												name="state"
												rules={[
													{
														required: true,
														message: "Please Enter State",
													},
												]}
											>
												<Select showSearch placeholder="Select State" optionFilterProp="children" size="large" onChange={changeState} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{stateList?.map((item) => (
														<Option value={item?.value}>{item?.label}</Option>
													))}
												</Select>
											</Form.Item>
										</div>
										<div className="profile-form-col">
											<Form.Item
												label="City"
												name="city"
												rules={[
													{
														required: cityList?.length ? true : false,
														message: "Please Enter City",
													},
												]}
											>
												<Select showSearch placeholder="Select City" size="large" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{cityList?.map((item) => (
														<Option value={item.value}>{item.label}</Option>
													))}
												</Select>
											</Form.Item>
										</div>
									</>
								)}

								{/* <div className="profile-form-textarea">
                  <Form.Item name="bio" label="Enter Bio">
                    <TextArea rows={4} placeholder="" />
                  </Form.Item>
                </div> */}
								<div className="profile-form-button">
									{accountUpdate.loading ? (
										<Form.Item>
											<Button>
												<Spin indicator={antIcon} />
											</Button>
										</Form.Item>
									) : (
										<Form.Item>
											<Button htmlType="submit">Save</Button>
										</Form.Item>
									)}
									<Form.Item>
										<Button
											onClick={() => {
												navigate("/myaccount");
												dispatch(openPasswordModal(false));
											}}
											htmlType="submit"
										>
											Cancel
										</Button>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
					<PasswordSetting />
				</div>
			</div>
			<ChangePassword />
		</>
	);
};
