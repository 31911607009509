import React from "react";
import InfluencersReview from "../../../components/influencersReview/influencersReview";

export default () => {
	return (
		<>
			<div className="live-event-main">
				<div className="bioshop-live-event-container">
					<div className="live-shows">
						<InfluencersReview  source="bioshop"/>
					</div>
				</div>
			</div>
		</>
	);
};
