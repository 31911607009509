import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Spin, InputNumber } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { getBecomeInfluencer } from "../../redux/actions/becomeInfluencer.action";
import { getAccount } from "../../redux/actions/account.action";
// import { getCountry } from "../../redux/actions/country.action";
// import { getState } from "../../redux/actions/state.action";
// import { getCity } from "../../redux/actions/city.action";
import { LoadingOutlined } from "@ant-design/icons";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
export const history = createBrowserHistory({ forceRefresh: true });

export default ({ becomeInfluencerModal, setBecomeInfluencerModal }) => {
	// const { Option } = Select;
	// const [countryList, setCountryList] = useState([]);
	// const [stateList, setStateList] = useState([]);
	// const [cityList, setCityList] = useState();
	// const [country, setCountry] = useState("US");
	// const [state, setState] = useState();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const { becomeInfluencer } = useSelector((state) => {
		return state;
	});

	// useEffect(() => {
	// 	getCountryFunc();
	// 	getStateFunc(country);
	// 	getCityFunc();
	// }, []);

	// const getCountryFunc = () => {
	// 	dispatch(getCountry()).then((res) => {
	// 		const selectCountries = [];
	// 		res.payload.message.map(({ name, code1 }) => {
	// 			return selectCountries.push({ value: code1, label: name });
	// 		});
	// 		setCountryList(selectCountries);
	// 	});
	// };

	// const getStateFunc = (country) => {
	// 	dispatch(getState(country)).then((res) => {
	// 		const selectStates = [];
	// 		res.payload.message.map(({ name, isoCode }) => {
	// 			return selectStates.push({ value: isoCode, label: name });
	// 		});
	// 		setStateList(selectStates);
	// 		setCityList([]);
	// 	});
	// };

	// const getCityFunc = (country, state) => {
	// 	dispatch(getCity(country, state)).then((res) => {
	// 		const selectStates = [];
	// 		res.payload.message.map(({ name }) => {
	// 			return selectStates.push({ value: name, label: name });
	// 		});
	// 		setCityList(selectStates);
	// 	});
	// };

	// const changeCountry = (value) => {
	// 	setCountry(value);
	// 	getStateFunc(value);
	// 	form.resetFields(["state"]);
	// 	form.resetFields(["city"]);
	// };

	// const changeState = (value) => {
	// 	setState(value);
	// 	getCityFunc(country, value);
	// 	form.resetFields(["city"]);
	// };

	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	const onFinish = async (values) => {
		const userInfo = JSON.parse(localStorage.getItem("userInfo"));
		dispatch(getBecomeInfluencer({ ...values, token: userInfo?.token })).then((res) => {
			if (res?.payload?.success) {
				setBecomeInfluencerModal(false);
				Swal.fire({
					icon: "success",
					title: "Your request is submitted!",
				}).then(() => {
					dispatch(getAccount(userInfo?.token));
				});
			}
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Modal dialogClassName="signup-modal form-close-modal" size="md" show={becomeInfluencerModal} centered animation={false} backdrop={true} keyboard={false}>
			<Modal.Header>
				<button
					type="button"
					className="close"
					onClick={() => {
						setBecomeInfluencerModal(false);
					}}
				>
					<span aria-hidden="true">×</span>
					<span className="sr-only">Close</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="login-signup-ift">
					<Form
						form={form}
						name="basic"
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 24,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<h2>Influencer Application</h2>
						<p className="text-danger">{becomeInfluencer?.payload?.data?.message}</p>

						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: "Please input your Instagram Username",
								},
							]}
						>
							<Input placeholder="Instagram Username" size="large" />
						</Form.Item>

						<Form.Item
							name="no_of_followers"
							rules={[
								{
									required: true,
									message: "Please add followers",
								},
							]}
						>
							<InputNumber placeholder={`No of Followers`} size="large" />
						</Form.Item>

						{becomeInfluencer.loading ? (
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Button type="primary" size="large" block>
									<Spin indicator={antIcon} />
								</Button>
							</Form.Item>
						) : (
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Button type="primary" htmlType="submit" size="large" block>
									Submit
								</Button>
							</Form.Item>
						)}
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};
