import { GET_COMMISSION_REPORT_REQUEST, GET_COMMISSION_REPORT_SUCCESS, GET_COMMISSION_REPORT_ERROR, GET_COMMISSION_EARN_REPORT_REQUEST, GET_COMMISSION_EARN_REPORT_SUCCESS, GET_COMMISSION_EARN_REPORT_ERROR, GET_ALL_WITHDRAW_REQUEST, GET_ALL_WITHDRAW_SUCCESS, GET_ALL_WITHDRAW_ERROR, POST_WITHDRAW_REQUEST, POST_WITHDRAW_SUCCESS, POST_WITHDRAW_ERROR, GET_BRAND_WALLET_REQUEST, GET_BRAND_WALLET_SUCCESS, GET_BRAND_WALLET_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getCommissionReport =
	(token, fromDate, toDate, source = "all", sortBy, groupBy, page = 1, limit = 25) =>
	(dispatch) => {
		let dateRange = "";
		if (fromDate && toDate) dateRange = `&from_date=${fromDate}&to_date=${toDate}`;
		dispatch({ type: GET_COMMISSION_REPORT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/private/getCustomerSalesReportForShop?page=${page}&limit=${limit}${dateRange}`,
			headers: { Authorization: `Bearer ${token}` },
			data: {
				event_type: source,
				sort_by: sortBy,
				group_by: groupBy,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_COMMISSION_REPORT_SUCCESS,
					payload: response.data,
					brand: process.env.REACT_APP_BRAND_ID,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_COMMISSION_REPORT_ERROR,
					payload: error.response,
				});
			});
	};

export const getEarnCommission =
	(token, fromDate, toDate, page = 1, limit = 25) =>
	(dispatch) => {
		let dateRange = "";
		if (fromDate && toDate) dateRange = `&from_date=${fromDate}&to_date=${toDate}`;
		dispatch({ type: GET_COMMISSION_EARN_REPORT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/private/customerBalanceDetails?page=${page}&limit=${limit}`,
			data: { from_date: fromDate, to_date: toDate },
			headers: { Authorization: `Bearer ${token}` },
		})
			.then((response) => {
				return dispatch({
					type: GET_COMMISSION_EARN_REPORT_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_COMMISSION_EARN_REPORT_ERROR,
					payload: error.response,
				});
			});
	};

export const getWithdrawRequest = (token, status) => (dispatch) => {
	dispatch({ type: GET_ALL_WITHDRAW_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}withdraw/getallrequest`,
		headers: { Authorization: `Bearer ${token}` },
		data: { status: status },
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_WITHDRAW_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_WITHDRAW_ERROR,
				payload: error.response,
			});
		});
};

export const postWithdrawRequest = (token, email, amount) => (dispatch) => {
	dispatch({ type: POST_WITHDRAW_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}withdraw/request`,
		headers: { Authorization: `Bearer ${token}` },
		data: { email: email, amount, amount },
	})
		.then((response) => {
			return dispatch({
				type: POST_WITHDRAW_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_WITHDRAW_ERROR,
				payload: error.response,
			});
		});
};

export const getBrandWallet = (token) => (dispatch) => {
	dispatch({ type: GET_BRAND_WALLET_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}deposit/getbalance`,
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_BRAND_WALLET_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BRAND_WALLET_ERROR,
				payload: error.response,
			});
		});
};
