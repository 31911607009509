import React, { useState, useEffect } from "react";
import { DatePicker, Button, Form, Tabs, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faHandHoldingDollar, faDollarSign, faPercent, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardEvent, getDashboardSocial } from "../../../redux/actions/dashboard.action";
import moment from "moment";
import numeral from "numeral";
import "../../../css/account-setup.scss";
const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

export default function Dashboard() {
	const { dashboardPayloadEvent, dashboardPayloadSocial } = useSelector((state) => state);
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [activeKey, setActiveKey] = useState("1");

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	const [form] = Form.useForm();
	const [formone] = Form.useForm();
	const dispatch = useDispatch();

	const fromDate = moment().startOf("month").format("YYYY-MM-DD");
	const toDate = moment(new Date()).format("YYYY-MM-DD");
	const [startDate, setStartDate] = useState(fromDate);
	const [endDate, setEndDate] = useState(toDate);
	const getSum = (a1, a2) => parseFloat(a1) + parseFloat(a2);

	// const [dateType, setDateType] = useState("mtd");

	// const { purchaseReport } = useSelector((state) => {
	// 	return state;
	// });

	useEffect(() => {
		if (userInfo.token) {
			dispatch(getDashboardEvent(startDate, endDate, userInfo?.token));
		}
	}, []);

	const onFinish = async () => {
		if (userInfo.token) dispatch(getDashboardEvent(startDate, endDate, userInfo?.token));
	};

	const onFinishsale = async () => {
		if (userInfo.token) dispatch(getDashboardSocial(startDate, endDate, userInfo?.token));
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const onFinishSocial = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const dateRangePickerChanger = (value, dataString) => {
		const startDate = dataString[0];
		const endDate = dataString[1];

		setStartDate(startDate);
		setEndDate(endDate);
	};

	const reset = () => {
		if (userInfo.token) dispatch(getDashboardEvent(fromDate, toDate, userInfo?.token));
		form.resetFields();
		setStartDate(fromDate);
		setEndDate(toDate);
	};

	const resetsocial = () => {
		if (userInfo.token) dispatch(getDashboardSocial(fromDate, toDate, userInfo?.token));
		formone.resetFields();
		setStartDate(fromDate);
		setEndDate(toDate);
	};

	const onChange = (key) => {
		setActiveKey(key);
		if (key === "1") {
			dispatch(getDashboardEvent(startDate, endDate, userInfo?.token));
		}
		if (key === "2") {
			dispatch(getDashboardSocial(startDate, endDate, userInfo?.token));
		}
	};
	return (
		<>
			<div className="account-left-content">
				<div className="favourite-brands-container">
					<div>
						<Tabs defaultActiveKey={"1"} activeKey={activeKey} onChange={onChange}>
							<TabPane tab="Event Details" key="1">
								{activeKey === "1" && (
									<Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
										<div className="new-dash-content container ml-0 p-0">
											<div className="row">
												<div className="col-xl-3 col-md-6 col-12 mb-4">
													<p>Select Start Date / End Date</p>
													<Form.Item name="date">
														<RangePicker
															size="large"
															className="w-100"
															key={4}
															value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
															defaultValue={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
															allowClear={false}
															ranges={{
																Today: [moment(), moment()],
																Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
																Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
																"This Month": [moment().startOf("month"), moment().endOf("month")],
																"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
															}}
															format={dateFormat}
															onChange={dateRangePickerChanger}
														/>
													</Form.Item>
												</div>
												<div className="col-xl-3 col-md-6 col-12 d-flex align-items-end srh-btn mb-4">
													<Button disabled={dashboardPayloadEvent?.loading} htmlType="submit" type="primary" size="large" className="m-width-100">
														Search
													</Button>
													<Button
														disabled={dashboardPayloadEvent?.loading}
														onClick={() => {
															reset();
														}}
														type="primary"
														size="large"
													>
														Reset
													</Button>
												</div>
											</div>

											<div className="row dash-boxes mt-2">
												<div className="col-12 mb-3">
													<h2 className="title-hd">Shopping Details</h2>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faBagShopping} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.shop_purchases[0]?.total_sale).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">TOTAL AMOUNT</div>
													</div>
												</div>

												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faPercent} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.shop_purchases[0]?.discount).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">DISCOUNTS</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faMoneyBillTransfer} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.shop_purchases[0]?.order_totalprice).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">AMOUNT PAID</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faHandHoldingDollar} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div> {numeral(dashboardPayloadEvent?.payload?.message?.shop_purchases[0]?.cashback_amount).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">CASH BACK</div>
													</div>
												</div>
												<div className="col-12 mb-3">
													<h2 className="title-hd">Referral Details</h2>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faBagShopping} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.share_purchases[0]?.total_sale).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">TOTAL AMOUNT</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faPercent} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.share_purchases[0]?.discount).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">DISCOUNTS</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faMoneyBillTransfer} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.share_purchases[0]?.order_totalprice).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">AMOUNT PAID</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faDollarSign} />
														<div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(getSum(dashboardPayloadEvent?.payload?.message?.share_purchases[0]?.customer_commission, dashboardPayloadEvent?.payload?.message?.share_purchases[0]?.influencer_commission)).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">EARNINGS</div>
													</div>
												</div>
											</div>
										</div>
									</Form>
								)}
							</TabPane>

							<TabPane tab="Social Store" key="2">
								{activeKey === "2" && (
									<Form onFinish={onFinishsale} onFinishFailed={onFinishSocial} form={formone}>
										<div className="new-dash-content container ml-0 p-0">
											<div className="row">
												<div className="col-xl-3 col-md-6 col-12 mb-4">
													<p>Select Start Date / End Date</p>
													<Form.Item name="date">
														<RangePicker
															size="large"
															className="w-100"
															key={4}
															value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
															defaultValue={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
															allowClear={false}
															ranges={{
																Today: [moment(), moment()],
																Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
																Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
																"This Month": [moment().startOf("month"), moment().endOf("month")],
																"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
															}}
															format={dateFormat}
															onChange={dateRangePickerChanger}
														/>
													</Form.Item>
												</div>
												<div className="col-xl-3 col-md-6 col-12 d-flex align-items-end srh-btn mb-4">
													<Button disabled={dashboardPayloadSocial?.loading} htmlType="submit" type="primary" size="large" className="m-width-100">
														Search
													</Button>
													<Button
														disabled={dashboardPayloadSocial?.loading}
														onClick={() => {
															resetsocial();
														}}
														type="primary"
														size="large"
													>
														Reset
													</Button>
												</div>
											</div>
											<div className="row dash-boxes mt-2">
												<div className="col-12 mb-3">
													<h2 className="title-hd">Shopping Details</h2>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faBagShopping} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadSocial?.payload?.message?.shop_sales[0]?.total_sale).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">TOTAL AMOUNT</div>
													</div>
												</div>

												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faPercent} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadSocial?.payload?.message?.shop_sales[0]?.discount).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">DISCOUNTS</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faMoneyBillTransfer} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadSocial?.payload?.message?.shop_sales[0]?.order_totalprice).format("$0,0.0'")}</div>}</div>

														<div className="total-info-hd">AMOUNT PAID</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faHandHoldingDollar} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div> {numeral(dashboardPayloadSocial?.payload?.message?.shop_sales[0]?.cashback_amount).format("$0,0.0'")}</div>}</div>

														<div className="total-info-hd">CASH BACK</div>
													</div>
												</div>
												<div className="col-12 mb-3">
													<h2 className="title-hd">Referral Details</h2>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faBagShopping} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadSocial?.payload?.message?.share_sales[0]?.total_sale).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">TOTAL AMOUNT</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faPercent} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadSocial?.payload?.message?.share_sales[0]?.discount).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">DISCOUNTS</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faMoneyBillTransfer} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadSocial?.payload?.message?.share_sales[0]?.order_totalprice).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd">AMOUNT PAID</div>
													</div>
												</div>
												<div className="col-12 col-xl-3 col-lg-3 col-md-6">
													<div className="dashboard-info-box">
														<FontAwesomeIcon className="info-icon-main" icon={faDollarSign} />
														<div className="value-info-hd">{dashboardPayloadSocial?.loading ? <Spin size="small" /> : <div>{numeral(getSum(dashboardPayloadSocial?.payload?.message?.share_sales[0]?.customer_commission, dashboardPayloadSocial?.payload?.message?.share_sales[0]?.influencer_commission)).format("$0,0.0'")}</div>}</div>
														<div className="total-info-hd"> EARNINGS</div>
													</div>
												</div>
											</div>
										</div>
									</Form>
								)}
							</TabPane>
						</Tabs>
					</div>
				</div>

				{/* <div className="dashboard-content-full">
					<div className="dashboard-inner-content">
						<div className="col-12 col-xl-12">
							<div className="row">
								<div className="col-12 col-xl-4 col-lg-4 laptop-width-box">
									<div className="dashboard-box-main">
										<div className="box-top-area">
											<div className="box-top-hd-left">Total shares</div>
											<div class="box-top-hd-right">March 22, 08:00 PM</div>
										</div>
										<div className="container-fluid p-0">
											<div className="box-bottom-area d-flex align-items-end justify-content-between">
												<div class="box-bottom-left d-flex justify-content-between w-100">
													<div class="box-bottom-hd1">1280</div>
													<div class="box-bottom-hd2">
														<RiseOutlined />
														+23% Today
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-xl-4 col-lg-4 laptop-width-box">
									<div className="dashboard-box-main">
										<div className="box-top-area">
											<div class="box-top-hd-left">Total Earnings</div>
											<div class="box-top-hd-right">March 22, 08:00 PM</div>
										</div>
										<div className="container-fluid p-0">
											<div className="box-bottom-area d-flex align-items-end justify-content-between">
												<div class="box-bottom-left d-flex justify-content-between w-100">
													<div class="box-bottom-hd1">1280</div>
													<div class="box-bottom-hd2">
														<RiseOutlined />
														+23% Today
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-xl-4 col-lg-4 laptop-width-box">
									<div className="dashboard-box-main">
										<div className="box-top-area">
											<div class="box-top-hd-left">Total Payout</div>
											<div class="box-top-hd-right">March 22, 08:00 PM</div>
										</div>
										<div className="container-fluid p-0">
											<div className="box-bottom-area d-flex align-items-end justify-content-between">
												<div class="box-bottom-left d-flex justify-content-between w-100">
													<div class="box-bottom-hd1">1280</div>
													<div class="box-bottom-hd2">
														<RiseOutlined />
														+23% Today
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-xl-8 col-lg-8 laptop-width-box2">
									<div className="filters-box-main">
										<div className="row filters-top-area d-flex align-items-center mb-0">
											<div className="col-xl-4">
												<div class="filters-top-hd-left">Summary</div>
												<div class="filters-top-hd-right">March 21, 08:00 PM</div>
											</div>
											<div className="col-xl-8">
												<div className="row">
													<div className="col-12 mb-20">
														<p>Select Start Date / End Date</p>
														<Form>
															<div className="d-flex date-range">
																<Space direction="horizontal w-100">
																	<Form.Item name="date">
																		<RangePicker size="large w-100" />
																	</Form.Item>
																</Space>
																<Form.Item>
																	<Button htmlType="submit" size="large">
																		Submit
																	</Button>
																</Form.Item>
															</div>
														</Form>
													</div>
												</div>
											</div>
										</div>
										<div className="filters-bottom-area">
											<div class="filters-data-main table-responsive scrollbar-style">
												<Table className="filters-data table text-nowrap table-stripe">
													<thead className="table_heading">
														<tr>
															<th></th>
															<th>YTD</th>
															<th>MTD</th>
															<th>Today</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Total shares</td>
															<td>1500</td>
															<td>1500</td>
															<td>99</td>
														</tr>
														<tr>
															<td>Total shares</td>
															<td>1500</td>
															<td>1500</td>
															<td>99</td>
														</tr>
														<tr>
															<td>Total shares</td>
															<td>1500</td>
															<td>1500</td>
															<td>99</td>
														</tr>
														<tr>
															<td>Total shares</td>
															<td>1500</td>
															<td>1500</td>
															<td>99</td>
														</tr>
														<tr>
															<td>Total shares</td>
															<td>1500</td>
															<td>1500</td>
															<td>99</td>
														</tr>
														<tr>
															<td>Total shares</td>
															<td>1500</td>
															<td>1500</td>
															<td>99</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-xl-4 col-lg-4 laptop-width-box3">
									<div className="dashboard-box-main">
										<div class="box-top-area mb-30">
											<div>
												<div class="box-top-hd-left">Summary</div>
												<div class="box-top-hd-right">March 22, 08:00 PM</div>
											</div>
											<div>
												<div class="total-hd events">Total Events</div>
												<div class="total-hd sales">Total Sales</div>
											</div>
										</div>
										<div className="chart-style-main">
											<Chart />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</>
	);
}
