import React, { useState } from "react";
import { Button, Modal, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// import BioShopPost from "../../pages/bioShopSetup/bioShopPost/BioShopPost";
import BioShopPost from "../../pages/account/bioShopSetup/bioShopPost/BioShopPost"
export default ({ title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <>
        <div className="reorder-gallery-container">
          <BioShopPost />
        </div>
      </>
      <Modal
        className="modal-generic modal-500"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
      >
        <h2 className="modal-hd1">Share</h2>
        <div class="your-copy-link">
          <div class="item-a">
            <a target="_blank" rel="noreferrer" href="">
              {/* {shareUrls?.payload?.message} */}
            </a>
          </div>
          <div class="item-b">{/* <Button>Copy</Button> */}</div>
        </div>
      </Modal>
    </>
  );
};
