import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBrandLiveEvent } from "../../redux/actions/liveShopping.action";
import { dateInDays } from "../../helper/date";
import avatarIcon from "../../images/avatar.png";

export default function BrandLiveEvent(source) {
  const params = useParams();
  const dispatch = useDispatch();
  const [count, setCount] = useState(2);
  const [data, setData] = useState([]);
  const { allLiveEvent, account, bioShopMenu } = useSelector((state) => {
    return state;
  });

  let userInfo = localStorage.getItem("userInfo");
  if (userInfo !== "undefined") {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } else {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }

  useEffect(() => {
    dispatch(getBrandLiveEvent(1, 6, userInfo?.username ? userInfo?.username : userInfo?.pixel_id ? userInfo?.pixel_id : params.username)).then((res) => {
      setData(res?.payload?.message?.data);
    });
  }, []);

  const liveEvent = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className={"slick-arrow slick-next"} />,
    prevArrow: <SamplePrevArrow className={"slick-arrow slick-next"} />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4.2,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={className}
        onClick={() => {
          setCount(() => count + 1);
          onClick();
          if (allLiveEvent?.payload?.message?.next) {
            dispatch(getBrandLiveEvent(count, 6, params.username)).then((res) => {
              setData([...data, ...res?.payload?.message?.data]);
            });
          }
        }}
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={className}
        onClick={() => {
          if (count > 0) setCount(() => count - 1);
          onClick();
        }}
      ></button>
    );
  }

  const onSwipeEvent = (swipeDirection) => {
    if (swipeDirection === "left") {
      if (allLiveEvent?.payload?.message?.next) {
        dispatch(getBrandLiveEvent(count, 6, params.username)).then((res) => {
          setData([...data, ...res?.payload?.message?.data]);
        });
      }
    }
  };
  return (
    <>
      {data?.length === 0 ? (
        <></>
      ) : (
        <div className="event-container">
          <div className="event-header">
            <h3>All Events</h3>
          </div>
          {data?.length > 0 && (
            <Slider onSwipe={onSwipeEvent} {...liveEvent}>
              {data.map((item) => {
                return (
                  <>
                    {item.event_status === "live" ? (
                      <div className="event-col-box">
                        <NavLink to={`/live-event/${item._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?referrel=live-events&name=${params.username}`} className="live-vid-link">
                          <div className={`vid-item ${item?.default_banner ? "default-image" : ""}`}>
                            <img alt="" src={item?.banner} />
                            <div className="vid-item-detail">
                              <div className="vid-time">
                                <span className="live-now">Live Now</span>
                                {item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
                              </div>
                              <div className="vid-title">
                                <span className="v-title">{item?.title}</span>
                                <div className="author-name justify-content-between">
                                  <div className="auth-left">
                                    <span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                                    <span className="auth-title">{item?.brand_name}</span>
                                  </div>
                                  <div className="auth-right">
                                    {userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item?.influencer_percent !== "undefined" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
                                    {userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item?.referral_percent !== "undefined" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    ) : (
                      <div className="event-col-box">
                        <NavLink to={`/upcoming-event/${item._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?referrel=live-events&name=${params.username}`} className="live-vid-link">
                          <div className={`vid-item ${item.default_banner ? "default-image" : ""}`}>
                            <img alt="" src={item?.banner} />
                            <div className="vid-item-detail">
                              <div className="vid-time">
                                <span>in {dateInDays(item?.start_date)} </span>
                                {item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
                              </div>
                              <div className="vid-title">
                                <span className="v-title">{item?.title}</span>
                                <div className="author-name justify-content-between">
                                  {item?.brand_profile ? (
                                    <div className="auth-left">
                                      <span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                                      <span className="auth-title">{item?.brand_name}</span>
                                    </div>
                                  ) : (
                                    <div className="auth-left">
                                      <span className="auth-title">{item?.brand_name}</span>
                                    </div>
                                  )}
                                  <div className="auth-right">
                                    {userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item?.influencer_percent !== "undefined" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
                                    {userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item?.referral_percent !== "undefined" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    )}
                  </>
                );
              })}
            </Slider>
          )}
        </div>
      )}
    </>
  );
}
