import {
    GET_EVENT_VIEW_REQUEST,
    GET_EVENT_VIEW_SUCCESS,
    GET_EVENT_VIEW_ERROR,
  } from "../types/types";
  import { BASEURL } from "../../config";
  import axios from "axios";
  
  export const getEventViews = (_id,token) => (dispatch) => {
    dispatch({ type: GET_EVENT_VIEW_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}events/geteventviews/${_id}`,
      headers: { Authorization: `Bearer ${token}` },
      data:{
        id: _id
      }
    })
      .then((response) => {
        return dispatch({
          type: GET_EVENT_VIEW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_EVENT_VIEW_ERROR,
          payload: error.response,
        });
      });
  };