import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { publishInfluencer } from "../../redux/actions/influencer.action";
import avatarIcon from "../../images/avatar.png";

export default ({ source, origin }) => {
  //	const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const { influencerReviews } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    dispatch(publishInfluencer()).then((res) => {});
  }, []);

  const liveevents = {
    className: "slider variable-width",
    dots: false,
    infinite: influencerReviews?.payload?.message?.data?.length > 6 ? true : false,
    centerMode: false,
    slidesToShow: 6,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          swipe: true,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
          infinite: false,
        },
      },
    ],
  };

  const generalReviews = () => {
    return (
      <>
        {influencerReviews.loading ? (
          <>
            <Slider {...liveevents}>
              {new Array(6).fill().map((_, i) => {
                return <div className="event-col-box"></div>;
              })}
            </Slider>
          </>
        ) : (
          <>
            {influencerReviews?.payload?.message?.data?.length && (
              <Slider {...liveevents}>
                {influencerReviews?.payload?.message?.data?.map((item) => {
                  return (
                    <div className="event-col-box">
                      <NavLink to={`/influencer-reviews/${item._id}/${item.brand?._id}?origin=${origin}`} className="live-vid-link">
                        <div className="vid-item">
                          <img src={item?.banner} />
                          <div className="vid-item-detail">
                            <div className="vid-time">
                              <span className="vid-views">
                                <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                              </span>
                            </div>
                            <div className="vid-title">
                              <div className="author-name">
                                <span className="auth-image">
                                  {item?.influencer?.profile_image_url ? <img src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
                                  {/* <img src={item?.influencer?.profile_image_url} /> */}
                                </span>
                                <span className="auth-title">{item?.influencer?.name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </Slider>
            )}
          </>
        )}
      </>
    );
  };
  const brandReviews = () => {
    return (
      <>
        <div className={`influencer-layout-main`}>
          <div className="influencer-layout-inner">
            <>
              {influencerReviews.loading ? (
                <>
                  {new Array(6).fill().map((_, i) => {
                    return <div className="event-col-box"></div>;
                  })}
                </>
              ) : (
                influencerReviews?.payload?.message?.data?.map((item) => {
                  return (
                    <div className="event-col-box">
                      <NavLink to={`/influencer-reviews/${item._id}/${item.brand?._id}?origin=${origin}&username=${params.username}`} className="live-vid-link">
                        <div className="vid-item">
                          <img src={item?.banner} />
                          <div className="vid-item-detail">
                            <div className="vid-time">
                              <span className="vid-views">
                                <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                              </span>
                            </div>
                            <div className="vid-title">
                              <div className="author-name">
                                <span className="auth-image">{item?.influencer?.profile_image_url ? <img src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                                <span className="auth-title">{item?.influencer?.name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })
              )}
            </>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`event-container ${influencerReviews?.payload?.message?.data.length === 0 && "d-none"}`} id="influencers">
        <div className="event-header">
          <h3>Influencers Reviews</h3>
        </div>
        <> {source === "bioshop" ? brandReviews() : generalReviews()}</>
      </div>
    </>
  );
};
