import React, { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "../../css/influencer-reviews.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { publishSingle } from "../../redux/actions/influencer.action";
import { brandFollower } from "../../redux/actions/brand.action";
import { publishInfluencer } from "../../redux/actions/influencer.action";
import queryString from "query-string";
import numeral from "numeral";
import closeicon from "../../images/brand-logos/close-icon.svg";
import avatarIcon from "../../images/avatar.png";

export default () => {
	const queryParams = queryString.parse(window.location.search);
	const dispatch = useDispatch();
	const params = useParams();
	const reviewsId = params.id;
	const brandId = params.brandId;
	const { influencerSingle, influencerReviews, followerBrand } = useSelector((state) => {
		return state;
	});
	const slider = useRef();
	const [slideIndex, setSlideIndex] = useState(0);
	const [showProducts, setShowProducts] = useState(false);
	const [products, setProducts] = useState([]);
	const [otherReviews, setOtherReviews] = useState([]);
	const [statisReviews, setStaticReviews] = useState([]);
	const [disableButton, setDisableButton] = useState(false);

	useEffect(() => {
		document.body.classList.add("customer-reviews-body");
		return () => {
			document.body.classList.remove("customer-reviews-body");
		};
	}, []);

	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	useEffect(() => {
		dispatch(publishSingle(reviewsId)).then((res) => {
			if (res?.payload?.message?.user_id) {
				dispatch(brandFollower(res?.payload?.message?.user_id));
			}
			setProducts(res.payload.message.products);
		});
		dispatch(publishInfluencer(brandId)).then((res) => {
			const reviews = [...res?.payload?.message?.data];
			setStaticReviews([...res?.payload?.message?.data]);
			const key = reviews.findIndex((item) => item?._id == reviewsId);
			setSlideIndex(key);
			setOtherReviews(reviews);
		});
	}, []);

	useEffect(() => {
		if (otherReviews?.length) {
			const key = otherReviews.findIndex((item) => item?._id == reviewsId);
			setSlideIndex(key);
			slider.current.slickGoTo(key);
			const currentVideo = document.getElementById(`video-${key}`);
			if (currentVideo) {
				if (currentVideo.paused) {
					currentVideo.play();
				}
			}
		}
	}, [otherReviews]);

	const settings = {
		className: "slider variable-width",
		dots: false,
		arrows: true,
		infinite: false,
		centerMode: false,
		slidesToShow: 1,
		vertical: true,
		verticalSwiping: true,
		slidesToScroll: 1,
		nextArrow: <RightNavButton />,
		prevArrow: <LeftNavButton />,
		onSwipe: (swipe) => {
			if (swipe === "up") {
				setDisableButton(true);
				setTimeout(() => {
					const prevVideo = document.getElementById(`video-${slideIndex}`);
					const nextVideo = document.getElementById(`video-${slideIndex + 1}`);

					if (prevVideo) {
						prevVideo.pause();
					}

					if (nextVideo) {
						if (nextVideo.paused) {
							nextVideo.play();
						}
					}
					setDisableButton(false);
				}, 500);

				if (slideIndex !== otherReviews.length - 1) {
					setSlideIndex(slideIndex + 1);
				}

				const nextVideoId = otherReviews?.find((item, key) => {
					if (key === slideIndex + 1) {
						return item._id;
					}
				});

				if (nextVideoId?._id) {
					dispatch(brandFollower(nextVideoId?.user_id));
				}
				setProducts(nextVideoId.products);
			} else {
				setDisableButton(true);
				setTimeout(() => {
					const currentVideo = document.getElementById(`video-${slideIndex}`);
					const nextVideo = document.getElementById(`video-${slideIndex - 1}`);

					if (currentVideo) {
						currentVideo.pause();
					}

					if (nextVideo) {
						if (nextVideo.paused) {
							nextVideo.play();
						}
					}
					setDisableButton(false);
				}, 500);

				if (slideIndex !== 0) setSlideIndex(slideIndex - 1);
				const nextVideoId = otherReviews?.find((item, key) => {
					if (key === slideIndex - 1) {
						return item._id;
					}
				});
				if (nextVideoId?._id) {
					dispatch(brandFollower(nextVideoId?.user_id));
				}
				setProducts(nextVideoId.products);
			}
		},
		beforeChange: (oldIndex, newIndex) => {},
	};

	const productReviews = {
		className: "slider variable-width",
		dots: false,
		infinite: products.length > 6 ? true : false,
		centerMode: false,
		slidesToShow: 6,
		swipe: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1650,
				settings: {
					slidesToShow: 5,
					swipe: true,
				},
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 4,
					swipe: true,
				},
			},
		],
	};

	const otherreviews = {
		className: "slider variable-width",
		dots: false,
		infinite: influencerReviews?.payload?.message?.data.length > 6 ? true : false,
		centerMode: false,
		slidesToShow: 6,
		swipe: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1650,
				settings: {
					slidesToShow: 5,
					swipe: true,
				},
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 4,
					swipe: true,
				},
			},
		],
	};

	function LeftNavButton(props) {
		const { style } = props;
		return (
			<button
				disabled={disableButton ? true : false}
				onClick={() => {
					slider.current.slickPrev();
					setDisableButton(true);
					setTimeout(() => {
						const currentVideo = document.getElementById(`video-${slideIndex}`);
						const nextVideo = document.getElementById(`video-${slideIndex - 1}`);

						if (currentVideo) {
							currentVideo.pause();
						}

						if (nextVideo) {
							if (nextVideo.paused) {
								nextVideo.play();
							}
						}
						setDisableButton(false);
					}, 500);

					if (slideIndex !== 0) setSlideIndex(slideIndex - 1);
					const nextVideoId = otherReviews?.find((item, key) => {
						if (key === slideIndex - 1) {
							return item._id;
						}
					});

					if (nextVideoId?._id) {
						dispatch(brandFollower(nextVideoId?.user_id));
					}
					setProducts(nextVideoId.products);
				}}
				style={{ ...style, display: "block" }}
				type="button"
				data-role="none"
				class={`slick-arrow slick-prev ${slideIndex === 0 && "slick-disabled"}`}
			>
				{" "}
				Previous
			</button>
		);
	}

	function RightNavButton(props) {
		const { style } = props;
		return (
			<button
				disabled={disableButton ? true : false}
				onClick={(e) => {
					setDisableButton(true);
					setTimeout(() => {
						const prevVideo = document.getElementById(`video-${slideIndex}`);
						const nextVideo = document.getElementById(`video-${slideIndex + 1}`);
						if (prevVideo) {
							prevVideo.pause();
						}

						if (nextVideo) {
							if (nextVideo.paused) {
								nextVideo.play();
							}
						}
						setDisableButton(false);
					}, 500);

					const nextVideoId = otherReviews?.find((item, key) => {
						if (key === slideIndex + 1) {
							return item._id;
						}
					});

					if (nextVideoId?._id) {
						dispatch(brandFollower(nextVideoId?.user_id));
						slider.current.slickNext();
						setProducts(nextVideoId.products);
					}

					if (slideIndex !== otherReviews.length - 1) {
						setSlideIndex(slideIndex + 1);
					}
				}}
				style={{ ...style, display: "block" }}
				type="button"
				data-role="none"
				class={`slick-arrow slick-next ${slideIndex === otherReviews.length - 1 && "slick-disabled"}`}
			>
				{" "}
				Next
			</button>
		);
	}

	return (
		<>
			<div className="infl-rev-main-container">
				<div className="rev-container">
					<div className="rev-video-container">
						<NavLink to={`${queryParams.origin == "home" ? "/" : "/brand-reviews/" + queryParams.username}`} className="close-video">
							<img src={closeicon} className="filter-gray" />
						</NavLink>
						<div className="rev-video-box-main">
							{otherReviews.length > 0 && (
								<Slider ref={(c) => (slider.current = c)} {...settings}>
									{otherReviews.map((item, key) => {
										return (
											<div key={key} className="rev-vid-panel">
												<div className="rev-vid-panel-inr">
													<div className="rev-vid-dm1">
														{item.media_url && (
															<video id={`video-${key}`} controlsList="nodownload" preload="metadata" webkit-playsInline playsInline controls loop width="100%" height="100%">
																<source src={item?.media_url} type="video/mp4"></source>
															</video>
														)}
														<div className="influ-detail">
															<span className="infl-image">
																<img src={item?.influencer?.profile_image_url} />
															</span>
															<div className="indv-infl-followers-mobile">
																<div className="inf-title">{item?.influencer?.name}</div>
																<div className="brand-followers-event">
																	{followerBrand?.payload?.message?.followers_count ? (
																		<span>
																			<strong>{numeral(followerBrand?.payload?.message?.followers_count).format("0a")}</strong> Followers
																		</span>
																	) : (
																		<></>
																	)}
																	{followerBrand?.payload?.message?.follows_count ? (
																		<span>
																			<strong>{numeral(followerBrand?.payload?.message?.follows_count).format("0a")}</strong> Following
																		</span>
																	) : (
																		<></>
																	)}
																</div>
															</div>
														</div>
														{products?.length > 0 && (
															<div className="influ-product-popup">
																<Button
																	onClick={() => {
																		setShowProducts(true);
																	}}
																	variant="primary"
																>
																	Products
																</Button>{" "}
															</div>
														)}
													</div>
												</div>
												<div className="infl-image-outer">
													<div className="infl-out-img">{item?.influencer?.profile_image_url ? <img src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}</div>
													<div className="indv-infl-followers">
														<div className="inf-title">{item?.influencer?.name}</div>
														<div className="brand-followers-event">
															{followerBrand?.payload?.message?.followers_count ? (
																<span>
																	<strong>{numeral(followerBrand?.payload?.message?.followers_count).format("0a")}</strong> Followers
																</span>
															) : (
																<></>
															)}
															{followerBrand?.payload?.message?.follows_count ? (
																<span>
																	<strong>{numeral(followerBrand?.payload?.message?.follows_count).format("0a")}</strong> Following
																</span>
															) : (
																<></>
															)}
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</Slider>
							)}
						</div>
					</div>

					<div className="rev-content-container">
						<div className="review-container">
							<div className="event-container event-prod-container">
								<h3>Products in this video</h3>
								{influencerSingle?.loading ? (
									<>
										<Slider {...productReviews}>
											{new Array(6).fill().map((_, i) => {
												return <div className="event-col-box"></div>;
											})}
										</Slider>
									</>
								) : (
									products.length > 0 && (
										<Slider {...productReviews}>
											{products.map((item) => {
												return (
													<div className="event-product-box">
														<a target="_blank" href={item?.ProductUrl} className="revi-products">
															<div className="rev-pro-image">
																<img src={item?.mediaUrl} />
																<span className="rev-buy-now">
																	<i className="fa fa-shopping-cart" aria-hidden="true"></i>
																</span>
															</div>
															<div className="rev-pro-detail">
																<span className="rev-pro-title">{item?.ProductName}</span>
																<span className="rev-pro-price">{numeral(item?.price).format("$0,0.0'")}</span>
															</div>
														</a>
													</div>
												);
											})}
										</Slider>
									)
								)}
							</div>

							<div className="event-container">
								<h3>Other reviews</h3>
								{influencerReviews?.loading ? (
									<>
										<Slider {...otherreviews}>
											{new Array(6).fill().map((_, i) => {
												return <div className="event-col-box"></div>;
											})}
										</Slider>
									</>
								) : (
									statisReviews.length > 0 && (
										<Slider {...otherreviews}>
											{statisReviews?.map((item, key) => {
												return (
													<div className="event-col-box">
														<NavLink
															onClick={() => {
																slider.current.slickGoTo(key);

																const prevVideo = document.getElementById(`video-${slideIndex}`);
																if (prevVideo) {
																	prevVideo.pause();
																}
																setSlideIndex(key);

																const currentVideo = document.getElementById(`video-${key}`);

																if (currentVideo) {
																	if (currentVideo.paused) {
																		currentVideo.play();
																	}
																}
																dispatch(brandFollower(item?.user_id));
																setProducts(item.products);
															}}
															to={`/influencer-reviews/${item._id}/${item.brand?._id}?origin=${queryParams.origin}&username=${queryParams.username}`}
															className="live-vid-link"
														>
															<div className="vid-item">
																<img src={item?.banner} />
																<div className="vid-item-detail">
																	<div className="vid-time">
																		<span className="vid-views">
																			<i className="fa fa-play" aria-hidden="true"></i> 59.5k
																		</span>
																	</div>
																	<div className="vid-title">
																		<div className="author-name">
																			<span className="auth-image">{item?.influencer?.profile_image_url ? <img src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}</span>
																			<span className="auth-title">{item?.influencer?.name}</span>
																		</div>
																	</div>
																</div>
															</div>
														</NavLink>
													</div>
												);
											})}
										</Slider>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				backdropClassName="custom-backdrop"
				dialogClassName="influ-products-modal-box"
				show={showProducts}
				scrollable
				onHide={() => {
					setShowProducts(false);
				}}
				centered
			>
				<span
					className="filter-close"
					onClick={() => {
						setShowProducts(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<div className="pro-list-main">
						<h2>Products in this Live Show</h2>
						<div className="pro-list-inner">
							<div className="list-col">
								{influencerSingle?.loading ? (
									<>loading</>
								) : (
									products?.map((item) => {
										return (
											<div className="prod-box">
												<div className="prod-image">
													<img src={item?.mediaUrl} />
												</div>
												<div className="prod-name">
													<div className="prod-title">
														<span className="prod-name-inr">{item?.ProductName}</span>
													</div>
													<div className="prod-price">
														<span className="prod-inr-price">
															<span>{numeral(item?.price).format("$0,0.0'")}</span>
															{/* <span className="original-price">{item?.price}</span> */}
														</span>
													</div>
												</div>
												<div className="prod-cart-btn">
													<a href={item?.ProductUrl} target="_blank">
														Buy Now
													</a>
												</div>
											</div>
										);
									})
								)}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
