import { useSelector } from "react-redux";
import BrandLiveEvent from "../../../components/liveEvent/liveEvent";
import BrandUpcomingEvent from "../../../components/upComingEvent/upComingEvent";
import BrandPublishedEvent from "../../../components/publishEvent/publishEvent";
import { Empty } from "antd";
// import InfluencersReview from "../../../components/influencersReview/influencersReview";
import TrendingShows from "../../../components/trendingShow/trendingShows";
export default ({ filter }) => {
	const { bioShopMenu, allLiveEvent, allUpcomingEvent, allPublishEvent } = useSelector((state) => {
		return state;
	});

	return (
		<>
			<div className="live-event-main">
				<div className="bioshop-live-event-container">
					<div className="live-shows">
						{allLiveEvent?.payload?.message?.data.length === 0 && allUpcomingEvent?.payload?.message?.data.length === 0 && allPublishEvent?.payload?.message?.data.length === 0 && (
							<div className="align-items-center justify-content-center">
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</div>
						)}
						{bioShopMenu?.payload?.message?.data._id && <BrandLiveEvent category={filter.parentId} sortBy={filter?.sortBy} eventType={filter?.eventType} applyFilter={filter?.applyFilter} source="bioshop" />}
						{bioShopMenu?.payload?.message?.data._id && <BrandUpcomingEvent category={filter?.parentId} sortBy={filter?.sortBy} eventType={filter.eventType} applyFilter={filter?.applyFilter} source="bioshop" />}
						{bioShopMenu?.payload?.message?.data._id && <BrandPublishedEvent category={filter.parentId} sortBy={filter?.sortBy} eventType={filter?.eventType} applyFilter={filter?.applyFilter} source="bioshop" />}
						<TrendingShows source="bioshop" />
					</div>
				</div>
			</div>
		</>
	);
};
