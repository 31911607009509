import Layout from "./component/layout";
import Earning from "./component/earning";
export default () => {
	return (
		<>
			<Layout title="Earnings">
				<Earning />
			</Layout>
		</>
	);
};
