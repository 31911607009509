import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Statistic } from "antd";
import moment from "moment";

const Counter = ({ endDate,id,username }) => {
  const { Countdown } = Statistic;
  const navigate = useNavigate();
  const ref = useRef();

  const onFinish = () => {
    if(username)
    navigate(`/live-event/${id}/${username}`);
  };

  useEffect(() => {
    if (ref.current) {
      if (endDate && ref.current.formatCountdown.length) {
        const oldFormatter = ref?.current?.formatCountdown;
        ref.current.formatCountdown = (...params) => {
          const result = oldFormatter(...params);
          if (result) {
            let [day, hour, minute, second] = result?.split(":");
            return (
              <>
                <div className="dhms">
                  {day} <span>DAYS</span>
                </div>
                <div className="dhms">
                  {hour} <span>HOURS</span>
                </div>
                <div className="dhms">
                  {minute} <span>MINS</span>
                </div>
                <div className="dhms">
                  {second} <span>SECS</span>
                </div>
              </>
            );
          }
        };
      }
    }
  }, [endDate]);
  if (endDate) return <Countdown ref={ref} format={"DD:HH:mm:ss"} value={moment(endDate).utc()} onFinish={onFinish} />;
};
export default Counter;
