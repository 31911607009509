import { GET_CATEGORIES_SETUP_REQUEST, GET_CATEGORIES_SETUP_SUCCESS, GET_CATEGORIES_SETUP_ERROR } from "../../types/types";

export default function parentCategory(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CATEGORIES_SETUP_REQUEST:
			return {
				loading: true,
			};
		case GET_CATEGORIES_SETUP_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_CATEGORIES_SETUP_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}