import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import deposit from "../../../images/brand-logos/deposit.svg";
import wallet2 from "../../../images/brand-logos/balance-icon.svg";
import sale from "../../../images/brand-logos/sale-tag-black.svg";
import commission from "../../../images/brand-logos/commission-discounts.svg";
import discount from "../../../images/brand-logos/discount-voucher.svg";
import netsale from "../../../images/brand-logos/chart.svg";
import { getBrandWallet } from "../../../redux/actions/wallet.action";
import numeral from "numeral";
export default () => {

  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { brandWallet } = useSelector((state) => {
    return state
  });
  useEffect(() => {
    dispatch(getBrandWallet(userInfo?.token));
  }, []);

  useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	  }, []);
	
	  // This is componentWillUnmount
	  useEffect(() => {
		return () => {
		//  componentWillUnmount.current = true;
		  document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	  }, []);

  return (
    <>
      <div className="account-left-content">
        <h1>Payout</h1>
        <div className="wallet-content-main">
          <div className="wallet-row-1 wallet-box-gap">
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={deposit} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Deposit</span>
                    <div className="box-ammount">${numeral(brandWallet?.payload?.message?.deposit_amount).format("0.00")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={wallet2} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Total Balance</span>
                    <div className="box-ammount">${numeral(brandWallet?.payload?.message?.current_balance).format("0.00")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={sale} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Gross Sales</span>
                    <div className="box-ammount">${numeral(brandWallet?.payload?.message?.order_totalprice).format("0.00")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={commission} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Commission Paid</span>
                    <div className="box-ammount">${numeral(brandWallet?.payload?.message?.spent_amount).format("0.00")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={discount} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Customer Discount</span>
                    <div className="box-ammount">${numeral(brandWallet?.payload?.message?.discount).format("0.00")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={netsale} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Net Sales</span>
                    <div className="box-ammount">${numeral(brandWallet?.payload?.message?.total_sale).format("0.00")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};