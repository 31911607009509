import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getBanner } from "../../redux/actions/banner.action";
import { NavLink } from "react-router-dom";

export default () => {
	const dispatch = useDispatch();
	const { homeBanner, selectedCountry } = useSelector((state) => {
		return state;
	});
	useEffect(() => {
		if (selectedCountry.payload) {
			dispatch(getBanner(selectedCountry?.payload));
		}
	}, [selectedCountry.payload]);

	const [slideIndex, setSlideIndex] = useState(0);
	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 1.3,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 4000,
		beforeChange: (current, next) => setSlideIndex(next),

		responsive: [
			{
				breakpoint: 1899,
				settings: {
					slidesToShow: 1.15,
				},
			},
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 1.15,
				},
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1.15,
					arrows: false,
				},
			},
		],
	};

	return (
		<div className="c-portal-banner-main">
			<div className="midd-width-ifti">
				<div className={`c-portal-banner ${slideIndex === 7.7 || slideIndex === 7.85 ? "remove-shadow" : ""}`}>
					{homeBanner.loading ? "loading" : homeBanner?.payload && (
								<Slider {...settings}>
									{homeBanner?.payload.data?.map((item) => {
										return (
											<div>
												<NavLink to={`/brand-events/${item?.brandData[0]?.instagram_username ? item?.brandData[0]?.instagram_username : item?.brandData[0]?.pixel_id}`} className="bnr-height">
													<img src={item?.desktop_url} className="lg-bnr top-bnr-gap" />
													<img src={item?.tab_url} className="dsk-bnr top-bnr-gap" />
													<img src={item?.shop_url} className="iph-bnr top-bnr-gap" />
												</NavLink>
											</div>
										);
									})}
								</Slider>
						  )}
				</div>
			</div>
		</div>
	);
};