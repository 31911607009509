import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { notification, Radio, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import outSideClick from "../../../helper/outSideClick";
import { getBioShopMenu, addUrl } from "../../../redux/actions/bioShop.action";
import { getSubCategories } from "../../../redux/actions/category.action";
import { brandFollower } from "../../../redux/actions/brand.action";
import Popup from "./popup.js";
import numeral from "numeral";

import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import closeicon from "../../../images/brand-logos/close-icon.svg";
import All from "./all";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ socialStore }) => {
	const [subCategory, setSubCategory] = useState([]);
	const dispatch = useDispatch();
	const { bioShopMenu, account, followerBrand } = useSelector((state) => {
		return state;
	});

	const [showCustomerModal, setsShowCustomerModal] = useState(false);
	const [value, setValue] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const params = useParams();
	const componentWillUnmount = useRef(false);
	const [displayCategory, setDisplayCategory] = useState(false);
	const divRef = useRef();
	const handler = useCallback(() => setDisplayCategory(false), []);
	const [categoryName, setCategoryName] = useState("");
	const [activeCategory, setActiveCategory] = useState("");
	const [activeSubCategory, setActiveSubCateogry] = useState("");
	const [filterPost, setFilterPost] = useState({
		categoryId: "",
		subCategoryId: "",
		postType: "image,campaign,video",
		sortBy: "",
		applyFilter: "all",
	});
	outSideClick(divRef, handler);

	useEffect(() => {
		document.body.classList.add("bioshop-body");
		if (bioShopMenu?.length == 0) {
			dispatch(getBioShopMenu(params.username, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.data?._id.length > 0) dispatch(brandFollower(res?.payload?.message?.data?._id));
			});
		}
	}, [params.username]);

	// This is componentWillUnmount

	const urls = "";
	useEffect(() => {
		dispatch(addUrl(userInfo?.token, urls));
		return () => {
			componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body");
		};
	}, []);

	const copyToClipboard = () => {
		const url = `${window.location.origin}/${account?.payload?.Data?.instagram_username ? account?.payload?.Data?.instagram_username : account?.payload?.Data?.pixel_id}`;
		let textField = document.createElement("textarea");
		textField.innerText = url;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		// toast.success("Copied to Clipboard!");
		notification.success({
			message: "Copied to Clipboard!",
		});
	};

	const onChange = (e) => {
		console.log("radio checked", e.target.value);
		setValue(e.target.value);
	};

	function ComponentBioShop() {
		return (
			<div className="bio_shop_body">
				<div className="bio_shop_header">
					<div className="influencer-bioshop-header-inner">
						<div className="bio_shop_header_left">
							<div className="brand-descrp">
								<div className="brand_logo">{bioShopMenu?.payload?.message?.data?.profile_image_url && <img src={bioShopMenu?.payload?.message?.data?.profile_image_url} />}</div>
								<div className="logo-right">
									<div className="current-brand-name">{bioShopMenu?.payload?.message?.data?.name}</div>
									<div className="brand-followers">
										{followerBrand?.payload?.message?.followers_count ? (
											<span>
												<strong>{numeral(followerBrand?.payload?.message?.followers_count).format("0a")}</strong> Followers
											</span>
										) : (
											<></>
										)}
										{followerBrand?.payload?.message?.follows_count ? (
											<span>
												<strong>{numeral(followerBrand?.payload?.message?.follows_count).format("0a")}</strong> Following
											</span>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>

							{bioShopMenu?.payload?.message?.data?.promo !== "KB0" && bioShopMenu?.payload?.message?.data?.promo && <div className="brand_name">GET {bioShopMenu?.payload?.message?.data?.discount} OFF USING KBSHOP APP</div>}
						</div>
						{socialStore ? (
							<div className="bio_shop_copy_link">
								<div className="your-copy-link">
									<div className="item-a">
										{/* Bio Link:{" "} */}
										<a target="_blank" rel="noreferrer" href={`${window.location.origin}/${account?.payload?.Data?.instagram_username ? account?.payload?.Data?.instagram_username : account?.payload?.Data?.pixel_id}`}>
											{`${window.location.origin}/${account?.payload?.Data?.instagram_username ? account?.payload?.Data?.instagram_username : account?.payload?.Data?.pixel_id}`}
										</a>
									</div>
									<div onClick={() => copyToClipboard()} className="item-b">
										Copy
									</div>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>

					<div className="bio_shop_header_topmenu">
						<a href="#" onClick={() => setShowFilter(true)}>
							Filters
						</a>
						{bioShopMenu?.payload?.message?.data.type !== "influencer" && !socialStore && (
							<>
								<NavLink to={`/brand-events/${params.username}`}>Shows</NavLink>
								<NavLink to={`/brand-reviews/${params.username}`}>Influencer Reviews</NavLink>
								<NavLink to={`/${params.username}`}>Social Store</NavLink>
								{/* <a href="#">Website</a> */}

								<a href={bioShopMenu?.payload?.message?.data?.website_address} style={{ visibility: !bioShopMenu?.payload?.message?.data?.website_address ? "hidden" : "visible" }} target="_blank">
									Website
								</a>
							</>
						)}
					</div>
				</div>

				<All socialStore={socialStore} filterPost={filterPost} />
			</div>
		);
	}
	return (
		<>
			<div className={`${!socialStore ? "bio_shop_main_ift" : ""}`}>
				<div className={`${!socialStore ? "midd-width-ifti" : ""}`}>
					<div className={`${!socialStore ? "bio_container_main" : ""}`}>{ComponentBioShop()}</div>
				</div>
				<Popup showCustomerModal={showCustomerModal} setsShowCustomerModal={setsShowCustomerModal} />
			</div>

			<Modal
				backdropClassName="custom-backdrop"
				dialogClassName="filter-modal-box"
				show={showFilter}
				scrollable
				onHide={() => {
					setShowFilter(false);
				}}
				centered
			>
				<span
					className="filter-close"
					onClick={() => {
						setShowFilter(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>

				<Modal.Body>
					<div className="filter-container">
						<h3>Sort by</h3>
						<div className="radio-box">
							<Radio.Group
								onChange={(e) => {
									setValue(e.target.value);
									setFilterPost({
										...filterPost,
										sortBy: e.target.value,
										applyFilter: false,
									});
								}}
								value={filterPost.sortBy}
								size="large"
							>
								<Space direction="vertical">
									<Radio value={""}>Date</Radio>
									<Radio value={"topdiscount"}>Top Discount</Radio>
									<Radio value="referralfee">Referral Fee</Radio>
									{userInfo && account?.payload?.Data?.account_type !== "customer" ? <Radio value={"influencerfee"}>Influencer Fee</Radio> : <></>}
								</Space>
							</Radio.Group>
						</div>
					</div>
					<div className="filter-container">
						<h3>Post Type</h3>
						<div className="radio-box">
							<Radio.Group
								onChange={(e) => {
									setFilterPost({
										...filterPost,
										postType: e.target.value,
										applyFilter: false,
									});
								}}
								value={filterPost.postType}
								size="large"
							>
								<Space direction="vertical">
									<Radio value="image,video,campaign">All</Radio>
									<Radio value="image">Simple Posts</Radio>
									<Radio value="video">Video Posts</Radio>
								</Space>
							</Radio.Group>
						</div>
					</div>
					<div className="filter-container">
						<h3>Categories</h3>
						<div className="filter-box">
							<Button
								onClick={() => {
									setFilterPost({
										...filterPost,
										categoryId: "",
										parentId: "",
										applyFilter: false,
									});
									setActiveCategory("");
									setCategoryName("");
									setActiveSubCateogry("");
									setSubCategory([]);
								}}
								className={activeCategory === "" && "active"}
								variant="primary"
							>
								All
							</Button>

							{bioShopMenu?.payload?.message?.data?.categories.map((item, i) => {
								return (
									<Button
										className={activeCategory === item.parent_id && "active"}
										onClick={() => {
											if (activeCategory === item.parent_id) {
												setActiveCategory("");
												setFilterPost({
													...filterPost,
													categoryId: "",
													parentId: "",
													subCategoryId: "",
													applyFilter: false,
												});
											} else {
												setCategoryName(item?.category_name);
												setActiveCategory(item.parent_id);
												setFilterPost({
													...filterPost,
													categoryId: item.category_id,
													parentId: item.parent_id,
													subCategoryId: "",
													applyFilter: false,
												});
											}

											dispatch(getSubCategories(item?.parent_id)).then((res) => {
												setSubCategory(res?.payload?.data);
											});
										}}
										key={i}
									>
										{item?.category_name}
									</Button>
								);
							})}
						</div>
					</div>

					{subCategory?.length > 0 && (
						<div className="filter-container">
							<h3>Subcategories</h3>
							<div className="filter-box">
								{subCategory?.map((item, key) => {
									return (
										<Button
											className={activeSubCategory === item._id && "active"}
											onClick={() => {
												if (activeSubCategory === item._id) {
													setActiveSubCateogry("");
													setFilterPost({
														...filterPost,
														subCategoryId: "",
														applyFilter: false,
													});
												} else {
													setCategoryName(item?.category_name);
													setActiveSubCateogry(item._id);
													setFilterPost({
														...filterPost,
														subCategoryId: item._id,
														applyFilter: false,
													});
												}
											}}
											key={key}
										>
											{item?.category_name}
										</Button>
									);
								})}
							</div>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					{" "}
					<Button
						onClick={() => {
							setShowFilter(false);
							setFilterPost({
								categoryId: "",
								subCategoryId: "",
								postType: "image,campaign,video",
								sortBy: "",
								applyFilter: "all",
							});
							setActiveCategory("");
							setCategoryName("");
							setActiveSubCateogry("");
							setSubCategory([]);
						}}
						variant="secondary"
					>
						Reset all
					</Button>
					<Button
						onClick={() => {
							setShowFilter(false);
							setFilterPost({ ...filterPost, applyFilter: Math.random() });
						}}
						variant="primary"
					>
						Apply filter
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
