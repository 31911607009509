import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryBanner } from "../../redux/actions/banner.action";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "../../css/category.scss";
import { NavLink } from "react-router-dom";
export default ({ category, type }) => {
	const dispatch = useDispatch();
	const { categoryBanner } = useSelector((state) => state);
	useEffect(() => {
		dispatch(getCategoryBanner(category, type));
	}, [category]);
	const [slideIndex, setSlideIndex] = useState(0);

	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 1.3,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1000,
      	autoplaySpeed: 4000,
		beforeChange: (current, next) => setSlideIndex(next),
		responsive: [
			{
				breakpoint: 1899,
				settings: {
					slidesToShow: 1.15,
				},
			},
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 1.15,
				},
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1.15,
					arrows: false,
				},
			},
		],
	};

	return (
		<>
			<div className="sub-category-banner-main">
				<div className="midd-width-ifti">
					<div className={`sub-category-banner ${slideIndex === 4.7 || slideIndex === 4.85 ? "remove-shadow" : ""}`}>
						{categoryBanner.loading
							? "loading"
							: categoryBanner?.payload && (
									<Slider {...settings}>
										{categoryBanner?.payload.data?.map((item) => {
											return (
												<div>
													<NavLink to={`/brand-events/${item?.brandData[0]?.instagram_username ? item?.brandData[0]?.instagram_username : item?.brandData[0]?.pixel_id}`} className="bnr-height">
														<img src={item?.desktop_url} className="top-bnr-gap" />
														<img src={item?.tab_url} className="dsk-bnr top-bnr-gap" />
														<img src={item?.shop_url} className="iph-bnr top-bnr-gap" />
													</NavLink>
												</div>
											);
										})}
									</Slider>
							  )}
					</div>
				</div>
			</div>
		</>
	);
};
