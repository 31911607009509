import Layout from "./component/layout";
import All from "./component/all";
export default ({socialStore,store,setStore}) => {

	return (
		<>
			<Layout socialStore={socialStore}>
				<All store={store} setStore={setStore} socialStore={socialStore} />
			</Layout>
		</>
	);
};
