import { GET_REFERRAL_STATS_REQUEST, GET_REFERRAL_STATS_SUCCESS, GET_REFERRAL_STATS_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getReferralStats =
	(page = 1, limit = 8, startDate, endDate, source, sortBy) =>
	(dispatch) => {
		dispatch({ type: GET_REFERRAL_STATS_REQUEST });

		return axios({
			method: "POST",
			url: `${BASEURL}kbsly/customerstatssummary?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: {
				from_date: startDate,
				to_date: endDate,
				source: source,
				sort_by: sortBy,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_REFERRAL_STATS_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_REFERRAL_STATS_ERROR,
					payload: error.response,
				});
			});
	};
