import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Spin, message, Button, Col, Row, Checkbox, notification } from "antd";
import "../../css/live-events.scss";
import Swal from "sweetalert2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import closeicon from "../../images/brand-logos/close-icon.svg";
import { getUpComingEvent } from "../../redux/actions/liveShopping.action";
import { publishFb } from "../../redux/actions/facebook.action";
import { publishedIgmedia } from "../../redux/actions/instagram.action";
import { openLogin } from "../../redux/actions/login.action";
import { addUrl } from "../../redux/actions/bioShop.action";
import { notifyMe } from "../../redux/actions/notifyMe.action";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { publishPost } from "../../redux/actions/postRetrieve.action";
import moment from "moment";
import { createBrowserHistory } from "history";
import BrandLiveEvent from "../../components/brandLiveEvent/brandLiveEvent";
import ShortUrl from "../../components/shortUrl/shortUrl";
import Counter from "../../components/counter/counter";
import avatarIcon from "../../images/avatar.png";
import queryString from "query-string";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";

export const history = createBrowserHistory({
  forceRefresh: false,
});

export default ({ item }) => {
  const queryParams = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const id = params.id;
  const username = params.username;
  const [showShare, setShowShare] = useState(false);
  const [notifyBtn, setNotifyBtn] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  // const [loading, setLoading] = useState(false);
  // const [postItem, setPostItem] = useState(item);
  const { publishedPost, facebookPublish, instaPublish } = useSelector((state) => state);
  const [addToStore, setAddToStore] = useState(false);
  const [kBSLYurl, setKBSLYurl] = useState("");
  const [channel, setChannel] = useState([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { upComingEvent, account, notifyedMe, publishedShop } = useSelector((state) => {
    return state;
  });

  const share = () => {
    let url = window.location.href;
    if (!userInfo) {
      dispatch(openLogin(true));
    } else {
      dispatch(addUrl(userInfo.token, shareAction(url))).then((res) => {
        setKBSLYurl(res?.payload?.message);
      });
      setShowShare(true);
      shareAction(url);
    }
  };

  // const shareAction = (url) => {
  // 	const newUrl = url;
  // 	const newRedirectUrl = new URL(newUrl);
  // 	if (userInfo.account_type === "customer" || userInfo.account_type === "influencer") {
  // 		newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
  // 		newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
  // 		newRedirectUrl.searchParams.set("event", "share");
  // 	}
  // 	return newRedirectUrl.toString();
  // };

  const publish = (token, post) => {
    if (channel.includes("socialStore")) {
      addToBioShop(token, post?.id);
    }

    if (channel.includes("instragram")) {
      instagramPublish(post?.caption, post?.url);
    }

    if (channel.includes("facebook")) {
      dispatch(addUrl(token, shareAction(post?.media_url))).then((res) => {
        facebookPost(post?.url, post?.caption, res?.payload?.message);
      });
    }

    setTimeout(() => {
      setAddToStore(false);
    }, 3000);
  };

  const addToBioShop = (token, eventId) => {
    if (userInfo) {
      return dispatch(publishPost(token, eventId)).then((res) => {
        if (!res?.payload?.data?.success) {
          notification.error({
            message: res?.payload?.data?.message,
          });
          return false;
        } else {
          notification.success({
            message: "Successfully added to social store",
          });
        }
      });
    }
  };

  const facebookPost = (kBSLYurl, caption, link) => {
    return dispatch(publishFb(kBSLYurl, caption, link)).then((res) => {
      if (res?.payload?.data?.err) {
        notification.error({ message: "Something wrong with your facebook" });
      } else {
        notification.success({
          message: "Post to facebook successfully",
        });
      }
    });
  };

  const instagramPublish = (caption) => {
    return dispatch(publishedIgmedia(caption, kBSLYurl)).then((res) => {
      if (res?.payload?.data?.err) {
        notification.error({ message: "Something wrong with your instagram" });
      } else {
        notification.success({
          message: "Post to Instagram Successfully",
        });
      }
      return true;
    });
  };

  const notify = (token, event_id) => {
    if (!userInfo) {
      dispatch(openLogin(true));
    } else {
      dispatch(notifyMe(token, event_id)).then((res) => {
        if (res?.payload?.success) {
          setNotifyBtn(false);
          messageApi.open({
            type: "success",
            content: "You will receive an email notification when Live Show starts.",
            duration: 5,
            style: {
              marginTop: "12vh",
            },
          });
        } else {
          messageApi.open({
            type: "error",
            content: res?.payload?.message,
            duration: 5,
          });
        }
      });
    }
  };

  const shareAction = (url) => {
    const newUrl = url;
    const newRedirectUrl = new URL(newUrl);
    newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
    newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
    newRedirectUrl.searchParams.set("event_id", id);
    newRedirectUrl.searchParams.set("roiEvent", true);
    newRedirectUrl.searchParams.set("brand", 1);
    newRedirectUrl.searchParams.set("event", "eventshare");
    return newRedirectUrl.toString();
  };

  const channelChange = (e) => {
    if (e.target.checked) setChannel([...channel, e.target.value]);
    else setChannel(channel.filter((value) => value !== e.target.value));
  };

  let userInfo = localStorage.getItem("userInfo");

  if (userInfo !== "undefined") {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } else {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }

  useEffect(() => {
    dispatch(getUpComingEvent(id)).then((res) => {
      if (res?.payload?.message?.length === 0) {
        navigate(`/live-event/${id}/${username}`);
        window.location.reload();
      }
    });
  }, [id]);

  if (upComingEvent?.payload?.message) {
    return (
      <>
        {contextHolder}
        <div className="live-event-main">
          {upComingEvent?.payload && (
            <div className="live-video-container">
              <NavLink to={`${queryParams.origin == "home" ? "/" : "/brand-events/" + username}`} className="close-video">
                <img src={closeicon} className="filter-gray" />
              </NavLink>
              <div className="video-boxes-main upcoming-box-main">
                <div className={`upcoming-poster ${upComingEvent?.payload?.message[0]?.default_banner ? "default-image" : ""}`}>
                  <img src={upComingEvent?.payload?.message[0]?.banner} />
                </div>
                <div className="upcoming-timer">
                  <div className="countdown">
                    <span className="timer-title">This live event will start in </span>
                    <Counter id={id} username={username} endDate={upComingEvent?.payload?.message[0]?.start_date} />
                    <div className="rsvp-btns">
                      {notifyedMe?.loading ? (
                        <NavLink>
                          <Spin indicator={antIcon} />
                        </NavLink>
                      ) : (
                        <a
                          href="#"
                          onClick={() => {
                            notifyBtn && notify(userInfo?.token, id);
                          }}
                        >
                          {notifyBtn ? "Notify me" : "You Got It!"}
                        </a>
                      )}
                      {publishedShop?.loading ? (
                        <NavLink>
                          <Spin indicator={antIcon} />
                        </NavLink>
                      ) : (
                        <>
                          {account?.payload?.Data?.account_type === "influencer" && (
                            <NavLink
                              onClick={() => {
                                setAddToStore(true);
                              }}
                            >
                              Add to my Store
                            </NavLink>
                          )}
                        </>
                      )}
                      <a
                        href="#"
                        onClick={() => {
                          share();
                        }}
                      >
                        Share
                      </a>
                      <NavLink to="/">Exit</NavLink>
                    </div>
                    <div className="fee-row">
                      {upComingEvent?.payload?.message[0]?.discount !== "0%" && upComingEvent?.payload?.message[0]?.discount ? <span className="off-percentage">{upComingEvent?.payload?.message[0]?.discount} Off</span> : ""}
                      {userInfo?.account_type !== "influencer" ? upComingEvent?.payload?.message[0]?.referral_percent !== "0" && upComingEvent?.payload?.message[0]?.referral_percent !== "undefined" && upComingEvent?.payload?.message[0]?.referral_percent ? <span className="ref-fee">{upComingEvent?.payload?.message[0]?.referral_percent}% Referral Fee</span> : "" : ""}
                      {userInfo && account?.payload?.Data?.account_type !== "customer" ? upComingEvent?.payload?.message[0].influencer_percent !== "0" && upComingEvent?.payload?.message[0]?.influencer_percent !== "undefined" && upComingEvent?.payload?.message[0].influencer_percent ? <span className="inf-fee">{upComingEvent?.payload?.message[0].influencer_percent}% Influencer Fee</span> : "" : ""}
                      {upComingEvent?.payload?.message[0].promo && upComingEvent?.payload?.message[0].promo != "KB0" ? <span className="inf-fee">Promo {upComingEvent?.payload?.message[0].promo}</span> : ""}
                    </div>
                    <span className="broadcaster-name">{upComingEvent?.payload?.message[0]?.title}</span>
                    <div className="tim-author-name">
                      <span className="tim-auth-image">{upComingEvent?.payload?.message[0]?.brand_profile ? <img src={upComingEvent?.payload?.message[0]?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                      <span className="tim-auth-title">{upComingEvent?.payload?.message[0]?.brand_name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="midd-width-ifti">
            <div className="live-shows">
              <BrandLiveEvent />
            </div>
          </div>
        </div>
        <ShortUrl url={window.location.href} showShare={showShare} setShowShare={setShowShare} />

        <Modal
          backdropClassName="custom-backdrop"
          className="social-modal"
          show={addToStore}
          onHide={() => {
            setAddToStore(false);
            setChannel([]);
          }}
          centered
        >
          <Modal.Header closeButton>
            <h2>Add to Social Store</h2>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col span={12} className="mb-3">
                <Checkbox
                  onChange={(e) => {
                    channelChange(e);
                  }}
                  value="socialStore"
                >
                  Add to Social Store
                </Checkbox>
              </Col>
              {userInfo?.page_token && userInfo?.fb_token ? (
                <>
                  <Col span={12} className="mb-3">
                    <Checkbox
                      onChange={(e) => {
                        channelChange(e);
                      }}
                      value="instragram"
                    >
                      Post on Instagram
                    </Checkbox>
                  </Col>
                  <Col
                    onChange={(e) => {
                      channelChange(e);
                    }}
                    span={12}
                    className="mb-3"
                  >
                    <Checkbox value="facebook">Post on Facebook</Checkbox>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={12} className="mb-3">
                    <Checkbox disabled value="instragram">
                      Post on Instagram
                    </Checkbox>
                  </Col>
                  <Col span={12} className="mb-3">
                    <Checkbox disabled value="facebook">
                      Post on Facebook
                    </Checkbox>
                  </Col>
                </>
              )}
              <Col span={12} className="mb-3">
                <Checkbox disabled value="pinterest">
                  Post on Pinterest
                </Checkbox>
              </Col>
              <Col span={12} className="mb-3">
                <Checkbox disabled value="linkedin">
                  Post on Linkedin
                </Checkbox>
              </Col>
              <Col span={24} className="d-flex post-btns mt-3">
                {publishedPost?.loading || facebookPublish?.loading || instaPublish?.loading ? (
                  <Button type="primary" size="large">
                    <Spin indicator={antIcon} />
                  </Button>
                ) : (
                  <Button
                    disabled={channel.length === 0 && true}
                    type="primary"
                    size="large"
                    onClick={() =>
                      publish(userInfo?.token, {
                        id: id,
                        caption: upComingEvent?.payload?.message[0]?.title,
                        url: kBSLYurl,
                        // link: window.location.href,
                      })
                    }
                  >
                    Add / Post
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setChannel([]);
                    setAddToStore(false);
                  }}
                  type="primary"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  } else {
    return (
      <div className="live-event-main">
        <div className="live-video-container">
          <NavLink to="/live-shopping" className="close-video">
            <img src={closeicon} className="filter-gray" />
          </NavLink>
          <div className="video-boxes-main upcoming-box-main">
            <div className="upcoming-poster"></div>
            <div className="upcoming-timer">
              <div className="countdown">
                <span className="timer-title">This live event will start in</span>
                <span className="notify-button">{/* <Button variant="secondary">Notify me</Button> */}</span>
                <span className="broadcaster-name">Candice Swanepoel Fronts DL1961</span>
                <div className="tim-author-name">
                  <span className="tim-auth-image">
                    <img src="https://static.konnect.bio/banner/9a87d50f-2032-44b7-a982-942704564b84.jpg" />
                  </span>
                  <span className="tim-auth-title">DL1961 Premium Denim</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
