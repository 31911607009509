import React, { useEffect, useState } from "react";
import { openLogin } from "../../../redux/actions/login.action";
import { addUrl } from "../../../redux/actions/bioShop.action";
import { Select, InputNumber, Space, Spin, Form, Empty } from "antd";
import ShortUrl from "../../../components/shortUrl/shortUrl";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ShippingAddressModal from "../../account/component/shippingAddress";
// import { getProduct } from "../../../redux/actions/shopify.getProduct.action";
import PaymentMethodModal from "../../account/component/payment";
const { Option } = Select;

export default function Shop({ quantity, price, addToBag, addBag, setLineItem, setQty, clear, brandId, shopifyProduct, stp1, stp2, stp3, setStp1, setStp2, setStp3, setClear, liveEventId, userInfo, eventId, lineItem, product, buy }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showShare, setShowShare] = useState(false);
  const findVariant = () => product.variants.find((variant) => variant.selectedOptions.find((item) => item.name === "Size" && item.value === lineItem.filter((item) => item.key === "Size")[0].value));
  const [productUrl, setProductUrl] = useState("");
  const onFinish = async (values) => {
    addToBag(product?.images[0]?.src, product?.title, product?.tags, clear, findVariant()).then(() => {});
  };
  const onFinishFailed = (errorInfo) => console.log("Failed:", errorInfo);
  const { shopifyaddToCart, account } = useSelector((state) => {
    return state;
  });

  const share = (url) => {
    // alert(url)
    if (!userInfo) {
      dispatch(openLogin(true));
    } else {
      setProductUrl(url);
      dispatch(addUrl(userInfo.token, url));
      setShowShare(true);
    }
  };
  //   useEffect(() => {
  //     dispatch(getProduct())
  //   }, []);
  return (
    <>
      <div className="prod-for-purchase">
        <h4>Products</h4>
        <span className="available-for-purchase">Products available for purchase</span>
        {!stp1 && (
          <span
            onClick={() => {
              if (stp2) {
                setStp1(true);
                setStp2(false);
                setStp3(false);
                setClear(false);
              } else if (stp3) {
                setStp1(false);
                setStp2(true);
                setStp3(false);
                setClear(false);
              }
            }}
            className="go-back"
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
          </span>
        )}
      </div>
      {/* Dynamic */}
      {stp1 && (
        <div className="pro-list-main">
          <div className="pro-list-inner">
            {!liveEventId.loading ? (
              <>
                {eventId &&
                  eventId[0]?.products?.map((item, i) => {
                    return (
                      <>
                        <div className="list-col">
                          <div className="prod-box">
                            <div className="prod-image">
                              <img src={`${item?.mediaUrl}`} />
                            </div>
                            <div className="prod-name">
                              <div className="prod-title">
                                <span className="prod-name-inr">{item?.ProductName}</span>
                              </div>
                              <div className="prod-price">
                                <span className="prod-inr-price">
                                  <span>${item?.price}</span>
                                  {/* <span className="original-price">{item.productAmount}</span> */}
                                </span>
                              </div>
                              {account?.payload?.Data?.account_type === "customer" && (
                                <>
                                  {item.referral_percent && item.referral_percent !== "0" && (
                                    <div className="prod-inf-ref-fee">
                                      <span>{item.referral_percent}% Referral Fee</span>
                                    </div>
                                  )}
                                </>
                              )}
                              {!userInfo && (
                                <>
                                  {item.referral_percent && item.referral_percent !== "0" && (
                                    <div className="prod-inf-ref-fee">
                                      <span>{item.referral_percent}% Referral Fee</span>
                                    </div>
                                  )}
                                </>
                              )}
                              {account?.payload?.Data?.account_type === "influencer" && (
                                <>
                                  {eventId[0].influencer_percent && eventId[0].influencer_percent !== "0" && (
                                    <div className="prod-inf-ref-fee">
                                      <span>{eventId[0].influencer_percent}% Influencer Fee</span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="prod-cart-btn">
                              <a
                                target="_blank"
                                onClick={(e) => {
                                  if (!userInfo) {
                                    dispatch(openLogin(true));
                                  } else {
                                    buy(item?.id, brandId, item?.price);
                                  }
                                }}
                              >
                                Buy Now
                              </a>
                              <a
                                href="#"
                                className="share-product-oncart"
                                onClick={() => {
                                  share(item?.ProductUrl);
                                  // props.onKeyChange("3");
                                  // props.setCollapsed(!props.collapsed);
                                }}
                              >
                                Share
                              </a>
                            </div>
                            {/* <ShortUrl url={item?.ProductUrl} showShare={showShare} setShowShare={setShowShare} /> */}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="load-container">
                  <Spin size="medium"></Spin>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* Static */}

      {stp2 && (
        <div className="checkout-container">
          <div className="checkout-list-container">
            <div className="checkout-list-inner">
              {shopifyProduct.loading || shopifyaddToCart.loading ? (
                <>
                  <div className="load-container">
                    <Spin size="medium"></Spin>
                  </div>
                </>
              ) : (
                <>
                  {shopifyProduct?.payload?.data?.code === "PAYMENT_METHOD_ERROR" || shopifyProduct?.payload?.data?.code === "SHIPPING_ERROR" ? (
                    <>
                      {shopifyProduct?.payload?.data?.code === "PAYMENT_METHOD_ERROR" && (
                        <div className="payment-from-front">
                          <PaymentMethodModal cart={true} />
                        </div>
                      )}
                      {shopifyProduct?.payload?.data?.code === "SHIPPING_ERROR" && (
                        <div className="shipping-from-front">
                          <ShippingAddressModal cart={true} />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {shopifyProduct?.payload?.message === null ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      ) : (
                        <Form name="add-to-bag" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
                          <div className="product-check-detail">
                            <div className="prod-image">
                              <div className="prod-image-left">
                                <img src={`${product?.images[0]?.src}`} />
                              </div>
                              <div className="prod-title-price">
                                <h6>{product?.title}</h6>
                                <span className="shp-price">${price}</span>
                              </div>
                            </div>
                            <div className="prod-veriants">
                              {product?.options?.map((item, key) => {
                                return (
                                  <div key={key} className="variant-column">
                                    <Form.Item
                                      name={item?.name}
                                      label={item.name}
                                      rules={[
                                        {
                                          required: true,
                                          message: `Please insert ${item.name}`,
                                        },
                                      ]}
                                    >
                                      <Select
                                        optionFilterProp="children"
                                        onChange={(value) => {
                                          let lnItem = lineItem.filter((itm) => {
                                            if (lineItem?.length > 0) return itm?.key !== item?.name;
                                          });
                                          //console.log('test',lnItem)
                                          setLineItem([...lnItem, { key: item?.name, value: value }]);
                                        }}
                                        placeholder={`Select ${item?.name}`}
                                        size="large"
                                        style={{ width: "100%" }}
                                      >
                                        {item?.values?.map((item) => {
                                          return <Option value={item?.value}>{item?.value}</Option>;
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                );
                              })}
                              <div className="variant-column">
                                <Form.Item name={"qty"} label="Quantity">
                                  <Space>
                                    <InputNumber size="large" min={1} max={100000} defaultValue={1} onChange={(value) => setQty(value)} />
                                  </Space>
                                </Form.Item>
                              </div>
                              <div className="variant-column variant-gap">
                                <div dangerouslySetInnerHTML={{ __html: product?.body_html }} />
                              </div>
                              <div className="checkout-buttons">
                                <Button type="primary" htmlType="submit">
                                  ADD TO CART
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Static */}
      {stp3 && (
        <div className="pro-list-main">
          <h2>Cart</h2>
          <div className="pro-list-inner">
            {addBag?.map((item) => {
              return (
                <div className="list-col">
                  <div className="prod-box">
                    <div className="prod-image">
                      <img src={`${item?.source}`} />
                    </div>
                    <div className="prod-name">
                      <div className="prod-title">
                        <span className="prod-name-inr">{item?.title}</span>
                        <span className="prod-veriants-inr"></span>
                      </div>
                      <div className="prod-price">
                        <span className="prod-inr-price">
                          <span>${item?.price}</span>
                          {/* <span className="original-price">{item.productAmount}</span> */}
                        </span>
                      </div>
                      <div className="qty-rmv-btn">
                        <Space>
                          <InputNumber size="medium" min={1} max={100000} defaultValue={1} />
                        </Space>
                        <a href="javascript:void" className="remove-btn">
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="checkout-buttons">
            <Button>CHECKOUT</Button>
          </div>
        </div>
      )}
      <ShortUrl url={productUrl} showShare={showShare} setShowShare={setShowShare} />
    </>
  );
}
