import React,{useState} from "react";
import Lottie from "react-lottie";
import animationData from "../hearts/single-heart.json";

export default function Heart ({isStopped}) {
  // set initial state property

    // add button style
    const buttonStyle = {
      display: "block",
      margin: "10px auto",
    };

    const defaultOptions = {
      loop: true,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="streaming-heart">
        {/* add Lottie component */}
        <Lottie
          options={defaultOptions}
          //  height={1200}
            // width={65}
          isStopped={isStopped}
        />
      </div>
    );
  
}
