import { POST_ALL_INFLUENCER_REQUEST, POST_ALL_INFLUENCER_SUCCESS, POST_ALL_INFLUENCER_ERROR } from "../../types/types";

export default function influencerReviews(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case POST_ALL_INFLUENCER_REQUEST:
      return {
        loading: true,
      };
    case POST_ALL_INFLUENCER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case POST_ALL_INFLUENCER_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload ? payload : [],
      };

    default:
      return state;
  }
}